import {feature} from '@admin-tribe/acsc';

/**
 * @description Helper method to add together org consumables and org
 *   delegatable products
 * @param {ProductList} productList - list of products to grab delegatables from
 * @param {FiProductGroupProductList[]} fiProductGroupProductLists - list of org
 *   consumable products
 * @returns {<Product|FiProductGroupProductList>[]} list of org consumable and
 *   delegatable products
 */
function getOrgConsumableAndDelegatableProducts(productList, fiProductGroupProductLists) {
  const orgDelegatableProducts = productList.items.filter((product) =>
    feature.isEnabled('temp_delegation_type_dcomm')
      ? product.fulfillableItemList.hasOrgDelegatableWithTarget(
          product.processingInstructions?.license_data?.delegation_targets
        )
      : product.fulfillableItemList.hasOrgDelegatable()
  );

  return [...orgDelegatableProducts, ...fiProductGroupProductLists];
}

// eslint-disable-next-line import/prefer-default-export -- utils
export {getOrgConsumableAndDelegatableProducts};
