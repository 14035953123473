import {configStore} from '@admin-tribe/acsc';
import axios from 'axios';

import {getAuthorizationHeader} from 'common/api/httpUtils';
import rootStore from 'core/RootStore';

// API documentation: https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=ims&title=IP+Access+List+-+CRUD+API+Spec

let baseUrl, clientId;

(async function loadConfig() {
  ({url: baseUrl, clientId} = await configStore.getServiceConfiguration('ims'));
})();

/**
 * Get the list of allowed IPs.
 */
const getIpAccessList = () =>
  axios.get(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/policies/ip_access_list`,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
      params: {
        client_id: clientId,
      },
    }
  );

/**
 * Save the list of allowed IPs.
 *
 * @param ipList {Array<string>} List of IPs to save.
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const saveIpAccessList = (ipList) => {
  const body = {
    ipAccessList: ipList,
  };

  return axios.post(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/policies/ip_access_list`,
    body,
    {
      headers: {...getAuthorizationHeader()},
      params: {
        client_id: clientId,
      },
    }
  );
};

const ipAccessList = {
  getIpAccessList,
  saveIpAccessList,
};

export default ipAccessList;
