// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import PropTypes from 'prop-types';
import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';

import {SCHEDULE_OPTIONS} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';
import useEduRosterSyncV3 from 'features/settings/hooks/api/useEduRosterSyncV3';

/**
 * Private Context for the Roster Sync Settings section and its sub-components.
 * Used to pass the roster sync context between sub-components.
 */
const RosterSyncContext = createContext({});
const useRosterSyncContext = () => useContext(RosterSyncContext);

// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- it's only a lot of lines because of Prettier formatting the deps array
const RosterSyncContextProvider = ({children, value}) => {
  const [selectedGroupOption, setSelectedGroupOption] = useState();
  const [selectedApps, setSelectedApps] = useState([]);
  const [frequency, setFrequency] = useState(SCHEDULE_OPTIONS.MANUAL);
  const [userGroups, setUserGroups] = useState([]);
  const [products, setProducts] = useState([]);
  const {getOrgRosterSyncDetails} = useEduRosterSyncV3();

  // Add refreshSyncConfig function to refresh the sync configuration
  const refreshSyncConfig = useCallback(async () => {
    try {
      const response = await getOrgRosterSyncDetails();
      // Only update the syncConfig part of the value, preserving other properties
      if (response && value.setSyncConfig && value.syncConfig) {
        // Determine the structure of the response
        const updatedConfig = response.data?.syncConfig || response;

        // Create a new object that preserves all existing properties
        const newSyncConfig = {
          ...value.syncConfig,
          ...updatedConfig,
        };

        // Ensure rosterSource is lowercase to match the expected format in PROVIDER_CONFIGS
        if (newSyncConfig.rosterSource) {
          newSyncConfig.rosterSource = newSyncConfig.rosterSource.toLowerCase();
        }

        value.setSyncConfig(newSyncConfig);
      }
    } catch (error) {
      // Handle error if needed
    }
  }, [getOrgRosterSyncDetails, value]);

  const contextValue = useMemo(
    () => ({
      authData: value.authData,
      frequency,
      integrations: value.integrations,
      products,
      provider: value.provider,
      refreshSyncConfig,
      selectedApps,
      selectedGroupOption,
      setFrequency,
      setProducts,
      setProvider: value.setProvider,
      setSelectedApps,
      setSelectedGroupOption,
      setSyncConfig: value.setSyncConfig,
      setUserGroups,
      syncConfig: value.syncConfig,
      userGroups,
    }),
    [
      selectedGroupOption,
      selectedApps,
      frequency,
      userGroups,
      value.syncConfig,
      value.authData,
      value.integrations,
      products,
      value.provider,
      setProducts,
      value.setProvider,
      value.setSyncConfig,
      refreshSyncConfig,
    ]
  );

  return <RosterSyncContext.Provider value={contextValue}>{children}</RosterSyncContext.Provider>;
};

RosterSyncContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.shape({
    authData: PropTypes.shape({
      accessToken: PropTypes.string,
      districtId: PropTypes.string,
    }),
    integrations: PropTypes.arrayOf(
      PropTypes.shape({deployed: PropTypes.bool, name: PropTypes.string})
    ),
    provider: PropTypes.string,
    setProvider: PropTypes.func,
    setSyncConfig: PropTypes.func,
    syncConfig: PropTypes.shape({}),
  }),
};

export {RosterSyncContextProvider, useRosterSyncContext};
