import {feature} from '@admin-tribe/acsc';
import {Flex, Link} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getDevConsoleEndpointUrl} from 'common/utils/devConsoleUtils';
import ApiIntegrationUser from 'core/api-integration/ApiIntegrationUser';
import IsvBinding from 'core/api-integration/IsvBinding';

// An external link to the Adobe Developer's Console (formerly known as the Adobe I/O Console).
const AdobeioConsoleLink = ({member}) => {
  const intl = useIntl();
  const namespace = 'common.userDetailsHeader.integrations';

  let linkToAdobeio = member.deepLink;
  const outLinkLabelId = member instanceof IsvBinding ? `manageOnAdobeExchange` : `viewOnAdobeio`;

  if (feature.isDisabled('temp_devconsole_deeplink')) {
    linkToAdobeio = getDevConsoleEndpointUrl();
    if (linkToAdobeio && member.integrationOrgId && member.integrationId) {
      linkToAdobeio = `${linkToAdobeio}/integrations/${member.integrationOrgId}/${member.integrationId}/overview`;
    }
  }

  // Try to render the link to the specific integration on the developer console but fallback to the top-level.

  return (
    linkToAdobeio && (
      <Flex
        alignItems="center"
        columnGap="size-100"
        data-testid="adobeio-console-link"
        direction="row"
      >
        <Link>
          <a
            aria-label={intl.formatMessage({
              id: `${namespace}.${outLinkLabelId}.ariaLabel`,
            })}
            href={linkToAdobeio}
            rel="noreferrer"
            target="_blank"
          >
            {intl.formatMessage({
              id: `${namespace}.${outLinkLabelId}`,
            })}
          </a>
        </Link>
        <LinkOutIcon size="S" />
      </Flex>
    )
  );
};

AdobeioConsoleLink.propTypes = {
  /**
   * The API integration user which should have properties integrationOrgId and integrationId.
   */
  member: PropTypes.oneOfType([
    PropTypes.instanceOf(IsvBinding),
    PropTypes.instanceOf(ApiIntegrationUser),
  ]),
};

export default AdobeioConsoleLink;
