// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {useCallback} from 'react';

import {SYNC_STATUSES} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';

import useAdaptivePolling from './useAdaptivePolling';

/**
 * Custom hook for polling roster sync status with adaptive intervals
 *
 * @param {Object} options - Configuration options
 * @param {Object} options.syncConfig - The sync configuration object
 * @param {Function} options.refreshSyncConfig - Function to refresh the sync configuration
 * @param {number} options.initialDelay - Initial polling interval in milliseconds
 * @param {number} options.maxDuration - Maximum polling duration in milliseconds
 * @returns {Object} Object containing the current delay
 */
const useRosterSyncPolling = ({
  syncConfig,
  refreshSyncConfig,
  initialDelay = 5000,
  maxDuration = 10 * 60 * 1000, // 10 minutes
}) => {
  // Only poll when status is QUEUED or RUNNING
  const shouldPoll =
    syncConfig?.status === SYNC_STATUSES.QUEUED || syncConfig?.status === SYNC_STATUSES.RUNNING;

  // Wrap refreshSyncConfig to return the current status for comparison
  const refreshAndReturnStatus = useCallback(async () => {
    await refreshSyncConfig();
    return syncConfig?.status;
  }, [refreshSyncConfig, syncConfig?.status]);

  // Check if sync config is valid
  const isSyncConfigValid = useCallback(() => syncConfig && syncConfig.syncId, [syncConfig]);

  // Use the generic adaptive polling hook
  return useAdaptivePolling({
    active: shouldPoll,
    callback: refreshAndReturnStatus,
    increaseAfter: 5, // Increase interval after 5 unchanged polls
    increaseBy: 1.5, // Increase by 50% each time
    initialDelay, // Initial time between polls
    isValidCheck: isSyncConfigValid,
    maxDelay: 30000, // Maximum 30 seconds between polls
    maxDuration,
  });
};

export default useRosterSyncPolling;
