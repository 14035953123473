import {feature} from '@admin-tribe/acsc';
import {ActionButton, Flex, Text} from '@adobe/react-spectrum';
import {AdobeIcon, AdobeIconColors} from '@pandora/react-icon';
import {VisuallyHidden} from '@react-aria/visually-hidden';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * @description Left side of site header (Admin Console name, logo and skip to main content button)
 */
const SiteHeaderLeft = () => {
  const intl = useIntl();

  const showDistributorOrgLabel =
    feature.isEnabled('temp_support_distributor_org') &&
    rootStore.organizationStore.isActiveOrgDistributor;

  return (
    <>
      <VisuallyHidden isFocusable>
        <ActionButton
          data-testid="skip-to-main-content"
          minWidth="size-2400"
          onPress={clickSkipToMainContent}
          UNSAFE_style={{position: 'absolute', top: 'var(--spectrum-global-dimension-size-150)'}}
        >
          {intl.formatMessage({
            id: 'site.header.left.button',
          })}
        </ActionButton>
      </VisuallyHidden>
      <Flex alignItems="center" direction="row" gap="size-200">
        <AdobeIcon
          aria-hidden="false"
          aria-label="Adobe Icon"
          data-testid="adobe-icon"
          iconColor={AdobeIconColors.WHITE}
          role="img"
          size="L"
        />
        <Text
          data-testid="adobe-icon-text"
          UNSAFE_style={{
            color: 'var(--color-white)',
            fontSize: 'var(--spectrum-global-dimension-size-200)',
          }}
        >
          {showDistributorOrgLabel ? 'Admin Console : Partners' : 'Admin Console'}
        </Text>
      </Flex>
    </>
  );
};

// scroll to the main content element on the page
function clickSkipToMainContent() {
  const mainContent = document.querySelector('#main-content');
  if (!mainContent) {
    return;
  }

  // Setting tabindex to -1 is required for Chrome and IE browsers
  // (According to https://accessibility.oit.ncsu.edu/it-accessibility-at-nc-state/developers/accessibility-handbook/mouse-and-keyboard-events/skip-to-main-content/)
  mainContent.setAttribute('tabindex', '-1');
  mainContent.focus();
  mainContent.scrollIntoView();
  // Remove the tabindex on the main view to fix the issue with modal and drawer content navigation
  // when a click occurs on a non-focusable element (Reasoning is in https://axesslab.com/skip-links/ under "Update 3 – A comment from gov.uk")
  setTimeout(() => {
    mainContent.removeAttribute('tabindex');
  });
}

export default SiteHeaderLeft;
