import {Flex, View} from '@adobe/react-spectrum';
import {HelpTopicCard, HelpTopicCardContentModel} from '@pandora/react-help-topic-cards';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import SupportCaseQuickActions from '../support-summary-quick-actions/SupportCaseQuickActions';

import styles from './DirectPartnerSupportQuickActions.pcss';

const DirectPartnerSupportQuickActions = ({
  'data-testid': dataTestId,
  cloudType,
  onSupportCaseCreated,
}) => {
  const intl = useIntl();
  const content = HelpTopicCardContentModel.createEntry({
    richTextJson: {
      json: [
        {
          content: [
            {
              nodeType: 'text',
              value: intl.formatMessage({
                id: 'teamHelpTopicListContentModel.helpTopicCards.0.richTextJson.heading',
              }),
            },
          ],
          nodeType: 'header',
          style: 'h3',
        },
        {
          content: [
            {
              nodeType: 'text',
              value: intl.formatMessage({
                id: 'teamHelpTopicListContentModel.helpTopicCards.0.richTextJson.description',
              }),
            },
            {
              content: [],
              nodeType: 'line-break',
            },
            {
              content: [],
              nodeType: 'line-break',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          content: [
            {
              data: {
                href: `https://www.adobe.com/go/ac_cct_manage`,
                target: '_blank',
                title: 'tooltip',
              },
              nodeType: 'link',
              value: intl.formatMessage({
                id: 'teamHelpTopicListContentModel.helpTopicCards.0.richTextJson.link.text',
              }),
            },
          ],
          nodeType: 'paragraph',
        },
        {
          content: [
            {
              content: [],
              nodeType: 'line-break',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
    },
  });

  return (
    <Flex
      data-testid={dataTestId || 'support-summary-quick-actions'}
      direction="column"
      marginBottom="size-200"
    >
      <Flex>
        <SupportCaseQuickActions
          canDisplayChat
          cloudType={cloudType}
          data-testid="contact-support-quick-actions"
          isDistributorOrg
          onGoToOpenCases={() => {}}
          onSupportCaseCreated={onSupportCaseCreated}
          supportCaseCount={0}
        />
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes --  need a top level class to namespace the styles below */}
        <View data-testid={dataTestId} flex="1" UNSAFE_className={styles['help-topic']}>
          <HelpTopicCard content={content} dataTestId="help-topic-card-manage-your-team" />
        </View>
      </Flex>
    </Flex>
  );
};

DirectPartnerSupportQuickActions.propTypes = {
  /**
   * Used to set cloud type
   */
  cloudType: PropTypes.string,
  /**
   * The specified data-testid for the component.
   */
  'data-testid': PropTypes.string,
  /**
   * Callback function for after support case is opened
   */
  onSupportCaseCreated: PropTypes.func.isRequired,
};

export default DirectPartnerSupportQuickActions;
