// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {Heading, View} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {OptionSelector} from '@pandora/react-option-selector';
import Provider2 from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';

import {SYNC_PROVIDERS} from 'features/settings/api/eduRosterSync';
import ClasslinkLogo from 'features/settings/assets/classlink-new-logo.svg';
import CleverLogo from 'features/settings/assets/clever-new-logo.svg';
import OptionSelectorWrapper from 'features/settings/common/components/option-selector-wrapper/OptionSelectorWrapper';
import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import RosterSyncOptionCard from 'features/settings/components/roster-sync-settings/RosterSyncOptionCard';

import styles from './RosterSyncProviderCard.pcss';

const PROVIDERS = [
  {
    description: 'settings.rosterSync.providers.clever.description',
    id: SYNC_PROVIDERS.CLEVER,
    logo: CleverLogo,
    name: 'settings.rosterSync.providers.clever.title',
  },
  {
    description: 'settings.rosterSync.providers.classlink.description',
    id: SYNC_PROVIDERS.CLASSLINK,
    logo: ClasslinkLogo,
    name: 'settings.rosterSync.providers.classlink.title',
  },
];

const RosterSyncProviderCard = ({id, description, name, logo, selected}) => (
  <RosterSyncOptionCard selected={selected} testId={`roster-sync-card-${id}`}>
    <div styleName="provider-card-body">
      <ImageIcon alt={`${name} logo`} size="XXXL" src={logo} styleName="provider-card-logo" />
      <Heading
        level="2"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need to add styles
        UNSAFE_className={styles['provider-card-title']}
      >
        <FormattedMessage id={name} />
      </Heading>
      <p>
        <FormattedMessage id={description} />
      </p>
    </div>
  </RosterSyncOptionCard>
);

RosterSyncProviderCard.propTypes = {
  description: PropTypes.string,
  id: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.bool,
};

const RosterSyncProviderSelector = () => {
  const {provider, setProvider} = useRosterSyncContext();

  const providerCards = useMemo(
    () =>
      PROVIDERS.map((item) => ({
        ...item,
        selected: item.id === provider,
      })),
    [provider]
  );

  return (
    <Provider2>
      <OptionSelectorWrapper>
        <OptionSelector
          data={providerCards}
          options={{
            on_selection_change: (event) => event.selected && setProvider(event.data.id),
            preventDeselection: true,
          }}
          react_function={(props) => (
            <View>
              <RosterSyncProviderCard {...props} />
            </View>
          )}
        />
      </OptionSelectorWrapper>
    </Provider2>
  );
};

export default RosterSyncProviderSelector;
