// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {Flex, Text, View} from '@adobe/react-spectrum';
import {SelectableCard} from '@pandora/react-actionable-card';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {isAppIntegrationDeployed} from 'features/settings/components/roster-sync-settings/rosterSyncUtils';

import {useRosterSyncContext} from './RosterSyncContext';
import {APP_OPTIONS} from './rosterSyncConstants';

const AppOptionCard = ({selected, logo, title, onChange, id}) => {
  const intl = useIntl();
  return (
    <SelectableCard
      aria-label={intl.formatMessage(
        {id: 'settings.rosterSync.appOptionSelector.label'},
        {provider: title}
      )}
      id={id}
      isSelected={selected}
      onChange={onChange}
    >
      <ImageIcon alt={`${title} logo`} size="XXL" src={logo} />
      <Text>{title}</Text>
    </SelectableCard>
  );
};
AppOptionCard.propTypes = {
  id: PropTypes.string,
  logo: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.string,
};

const AppOptionSelector = () => {
  const {selectedApps, setSelectedApps, integrations} = useRosterSyncContext();

  // Preserve the selected option when navigating back to the settings step
  const appOptionsWithSelection = APP_OPTIONS.filter((option) =>
    isAppIntegrationDeployed(option.id, integrations)
  ).map((option) => ({
    ...option,
    selected: selectedApps.includes(option.id),
  }));

  const onSelectionChange = (id, isSelected) => {
    setSelectedApps(
      isSelected ? [...selectedApps, id] : selectedApps.filter((appId) => appId !== id)
    );
  };

  return (
    <Flex direction="row" gap="size-200">
      {appOptionsWithSelection.map((option) => (
        <View key={option.id} width="size-1600">
          <AppOptionCard onChange={onSelectionChange} {...option} />
        </View>
      ))}
    </Flex>
  );
};

export default AppOptionSelector;
