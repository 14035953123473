// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import React from 'react';
import {useLoaderData} from 'react-router-dom';

import LegacyRosterSyncError from 'features/settings/components/roster-sync-settings/LegacyRosterSyncError';
import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import SetupRosterSync from 'features/settings/components/roster-sync-settings/SetupRosterSync';
import RosterSyncDetails from 'features/settings/components/roster-sync-settings/roster-sync-details/RosterSyncDetails';

const RosterSyncPageContent = () => {
  const {syncConfig} = useRosterSyncContext();
  const {eduRosterSyncs} = useLoaderData();

  const hasLegacyRosterSync = eduRosterSyncs?.length > 0;

  if (hasLegacyRosterSync) {
    return <LegacyRosterSyncError />;
  }
  if (!syncConfig?.syncId) {
    return <SetupRosterSync />;
  }

  return <RosterSyncDetails />;
};

export default RosterSyncPageContent;
