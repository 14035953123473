// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {
  DATE_FORMATS,
  FormattedDateAndTime,
  showError as showErrorToast,
} from '@admin-tribe/acsc-ui';
import {Button, Flex, View} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import EduSyncStatus from 'features/settings/components/edu-sync-card/EduSyncStatus';
import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import ProviderLogoAndName from 'features/settings/components/roster-sync-settings/roster-sync-details/ProviderLogoAndName';
import RosterSyncActionMenu from 'features/settings/components/roster-sync-settings/roster-sync-details/RosterSyncActionMenu';
import {
  SYNC_ACTIONS,
  SYNC_STATUSES,
} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';
import useEduRosterSyncV3 from 'features/settings/hooks/api/useEduRosterSyncV3';
import useRosterSyncPolling from 'features/settings/hooks/useRosterSyncPolling';

const RosterSyncDetailsHeader = () => {
  const intl = useIntl();
  const {syncConfig, refreshSyncConfig} = useRosterSyncContext();
  const {putRosterSyncAction} = useEduRosterSyncV3();

  const isSyncStatusStopped = syncConfig.status === SYNC_STATUSES.STOPPED;
  const isQueueButtonEnabled =
    syncConfig.status === SYNC_STATUSES.IDLE || syncConfig.status === SYNC_STATUSES.SCHEDULED;

  // Use our specialized roster sync polling hook
  useRosterSyncPolling({
    refreshSyncConfig,
    syncConfig,
  });

  const handleQueueNow = async () => {
    try {
      await putRosterSyncAction({
        syncAction: SYNC_ACTIONS.QUEUE,
        syncId: syncConfig.syncId,
      });
      await refreshSyncConfig();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.rosterSync.error.queueSync',
        })
      );
    }
  };

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <View>
        <ProviderLogoAndName />

        <Flex alignItems="center" gap="size-350">
          <span>
            <FormattedMessage id="settings.rosterSync.details.lastSync" />{' '}
            {syncConfig.lastSyncedAt ? (
              <FormattedDateAndTime
                format={DATE_FORMATS.defaultDateTime}
                value={syncConfig.lastSyncedAt}
              />
            ) : (
              <FormattedMessage id="settings.rosterSync.details.notSyncedYet" />
            )}
          </span>
          {syncConfig.nextSyncAt && (
            <>
              <FormattedMessage id="settings.rosterSync.details.nextSync" />{' '}
              {isSyncStatusStopped ? (
                <FormattedMessage id="settings.rosterSync.details.syncStopped" />
              ) : (
                <FormattedDateAndTime value={syncConfig.nextSyncAt} />
              )}
            </>
          )}
          <EduSyncStatus status={syncConfig.status} />
        </Flex>
      </View>
      <Flex gap="size-100">
        <Button isDisabled={!isQueueButtonEnabled} onPress={handleQueueNow} variant="primary">
          <FormattedMessage id="settings.rosterSync.details.queueNow" />
        </Button>
        <RosterSyncActionMenu />
      </Flex>
    </Flex>
  );
};

export default RosterSyncDetailsHeader;
