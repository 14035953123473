// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {Card} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';

import './RosterSyncProviderCard.pcss';

const RosterSyncOptionCard = ({children, selected, testId}) => (
  <Card
    allowsSelection={false}
    data-testid={testId}
    selected={selected}
    styleName="roster-sync-option-card"
  >
    {children}
  </Card>
);

RosterSyncOptionCard.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  testId: PropTypes.string,
};
export default RosterSyncOptionCard;
