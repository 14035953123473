import ClasslinkLogo from 'features/settings/assets/classlink-new-logo.svg';
import CleverLogo from 'features/settings/assets/clever-new-logo.svg';

const ROSTER_SYNC_STEPS = {
  PROVIDER: {id: 'provider'},
  SETTINGS: {id: 'settings'},
  // eslint-disable-next-line sort-keys -- order is important
  PROFILES: {id: 'profiles'},
};

const STEPS = [
  {
    description: 'settings.rosterSync.provider.description',
    id: ROSTER_SYNC_STEPS.PROVIDER.id,
    number: 1,
    title: 'settings.rosterSync.provider.title',
  },
  {
    description: 'settings.rosterSync.settings.description',
    id: ROSTER_SYNC_STEPS.SETTINGS.id,
    number: 2,
    title: 'settings.rosterSync.settings.title',
  },
  {
    description: 'settings.rosterSync.profiles.description',
    id: ROSTER_SYNC_STEPS.PROFILES.id,
    number: 3,
    title: 'settings.rosterSync.profiles.title',
  },
];

const GROUP_OPTION_TYPES = {
  ALL_IN_ONE: 'GROUP_ALL',
  GROUP_BY_ROLE: 'GROUP_BY_ROLE',
};

const SETUP_OPTIONS = [
  {
    description: 'settings.rosterSync.setup.options.allInOne.description',
    id: GROUP_OPTION_TYPES.ALL_IN_ONE,
    title: 'settings.rosterSync.setup.options.allInOne.title',
  },
  {
    description: 'settings.rosterSync.setup.options.groupByRole.description',
    id: GROUP_OPTION_TYPES.GROUP_BY_ROLE,
    title: 'settings.rosterSync.setup.options.groupByRole.title',
  },
];

const APP_TYPES = {
  CREATIVE_CLOUD: 'CC_ALL_APPS',
  EXPRESS: 'CCX',
};

const APP_TYPES_TO_INTEGRATION_NAMES = {
  [APP_TYPES.EXPRESS]: 'Adobe Express',
  [APP_TYPES.CREATIVE_CLOUD]: 'Adobe Creative Cloud - All Apps',
};

const APP_TYPES_TO_PRODUCT_FAMILIES = {
  [APP_TYPES.CREATIVE_CLOUD]: ['CC_ALL_APPS'],
  [APP_TYPES.EXPRESS]: ['SPARK', 'CC_EXPRESS'],
};

const APP_OPTIONS = [
  {
    id: APP_TYPES.EXPRESS,
    logo: 'https://wwwimages.stage.adobe.com/content/dam/acom/one-console/icons_rebrand/adobeexpress.svg',
    title: 'Adobe Express',
  },
  {
    id: APP_TYPES.CREATIVE_CLOUD,
    logo: 'https://wwwimages.stage.adobe.com/content/dam/acom/one-console/icons_rebrand/cc_appicon.svg',
    title: 'Creative Cloud',
  },
];

const SYNC_STATUSES = {
  COMPLETED: 'Completed',
  IDLE: 'Idle',
  INVALID: 'Invalid',
  QUEUED: 'Queued',
  RUNNING: 'Running',
  SCHEDULED: 'Scheduled',
  STOPPED: 'Stopped',
  UNKNOWN: 'Unknown',
};

const SYNC_ACTIONS = {
  QUEUE: 'queue',
  RESUME: 'resume',
  STOP: 'stop',
};

const SCHEDULE_OPTIONS = {
  DAILY: 'daily',
  MANUAL: 'manual',
  WEEKLY: 'weekly',
};

const SYNC_PROVIDERS = {
  CLASSLINK: 'classlink',
  CLEVER: 'clever',
};

const PROVIDER_CONFIGS = {
  [SYNC_PROVIDERS.CLASSLINK]: {
    logo: ClasslinkLogo,
    name: 'settings.rosterSync.providers.Classlink',
    url: 'https://myapps.classlink.com/home',
  },
  [SYNC_PROVIDERS.CLEVER]: {
    logo: CleverLogo,
    name: 'settings.rosterSync.providers.Clever',
    url: 'https://schools.clever.com/applications',
  },
};

export {
  APP_OPTIONS,
  APP_TYPES,
  APP_TYPES_TO_INTEGRATION_NAMES,
  APP_TYPES_TO_PRODUCT_FAMILIES,
  GROUP_OPTION_TYPES,
  PROVIDER_CONFIGS,
  ROSTER_SYNC_STEPS,
  SCHEDULE_OPTIONS,
  SETUP_OPTIONS,
  STEPS,
  SYNC_ACTIONS,
  SYNC_PROVIDERS,
  SYNC_STATUSES,
};
