import {OverlayWait, showError} from '@admin-tribe/acsc-ui';
import {Content, Divider, Heading, Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalButtonGroup, ModalDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useIpListAccessContext} from 'features/settings/components/auth-settings-page/ip-restriction-section/IpListAccessContext';
import IpTextArea, {
  IP_LIST_SEPARATOR,
} from 'features/settings/components/auth-settings-page/ip-restriction-section/IpTextArea';

const INPUT_MODE = {
  ADD: 'add',
  EDIT: 'edit',
};

const IpRestrictionModal = ({defaultIpValue = '', onSave}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();
  const [ipValue, setIpValue] = useState(defaultIpValue);
  const [isValid, setIsValid] = useState(true);
  const {addIp, replaceIp, save} = useIpListAccessContext();
  const [isLoading, setIsLoading] = useState(false);
  const mode = defaultIpValue ? INPUT_MODE.EDIT : INPUT_MODE.ADD;
  const heading =
    mode === INPUT_MODE.EDIT
      ? intl.formatMessage({
          id: 'settings.authSettings.ipRestriction.ipRestrictionModal.heading.edit',
        })
      : intl.formatMessage({
          id: 'settings.authSettings.ipRestriction.ipRestrictionModal.heading.add',
        });

  const onSavePress = async () => {
    setIsLoading(true);

    const ipList = ipValue.split(IP_LIST_SEPARATOR).map((ip) => ip.trim());
    const newIpList =
      mode === INPUT_MODE.EDIT ? replaceIp(defaultIpValue, ipList[0]) : addIp(ipList);

    try {
      await save(newIpList);
      onSave();

      dialog.dismiss();
    } catch {
      showError(
        intl.formatMessage({
          id: 'settings.authSettings.ipRestriction.ipRestrictionModal.errorToastMessage',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalDialog size="M">
      <Heading>{heading}</Heading>

      <Divider marginBottom={0} />

      <Content>
        <OverlayWait delay={0} isLoading={isLoading} showContent>
          <Text marginBottom="size-200">
            {mode === INPUT_MODE.ADD ? (
              <FormattedMessage id="settings.authSettings.ipRestriction.ipRestrictionModal.description.add" />
            ) : (
              <FormattedMessage id="settings.authSettings.ipRestriction.ipRestrictionModal.description.edit" />
            )}
          </Text>

          <IpTextArea
            allowMultiple={mode !== INPUT_MODE.EDIT}
            defaultValue={defaultIpValue}
            onChange={setIpValue}
            onValidationStateChanged={setIsValid}
          />
        </OverlayWait>
      </Content>

      <ModalButtonGroup
        cancelLabel={intl.formatMessage({
          id: 'common.modal.buttons.cancel',
        })}
        ctaLabel={intl.formatMessage({
          id: 'common.modal.buttons.save',
        })}
        isCtaDisabled={!ipValue || !isValid || isLoading}
        onCancel={() => dialog.dismiss()}
        onCta={() => onSavePress()}
      />
    </ModalDialog>
  );
};

IpRestrictionModal.propTypes = {
  defaultIpValue: PropTypes.string,
  onSave: PropTypes.func,
};

export default IpRestrictionModal;
