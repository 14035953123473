// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {useDialogContainer} from '@adobe/react-spectrum';
import {Product} from '@pandora/data-model-product';
import {useOrganizationsProducts} from '@pandora/react-data-source-jil';
import {useCallback, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {
  getSelectedProductFamilies,
  prepareUserGroupPayload,
} from 'features/settings/components/roster-sync-settings/rosterSyncUtils';
import useEduRosterSyncV3 from 'features/settings/hooks/api/useEduRosterSyncV3';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';

import {useRosterSyncContext} from '../RosterSyncContext';
import {ROSTER_SYNC_STEPS, STEPS, SYNC_PROVIDERS} from '../rosterSyncConstants';

/**
 * Hook to manage roster sync wizard navigation and state
 * @param {Object} params - Hook parameters
 * @param {Function} params.onClose - Modal close handler
 * @param {string} params.currentStep - Current wizard step ID
 * @returns {Object} Navigation handlers and loading state
 */
const useRosterSyncWizard = ({onClose, currentStep}) => {
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);

  // Track previous selections to avoid unnecessary refetching
  const previousSelectionsRef = useRef({
    apps: [],
    groupOption: null,
  });

  const {getOrganizationsProducts} = useOrganizationsProducts();
  const intl = useIntl();
  const {
    selectedGroupOption,
    selectedApps,
    frequency,
    setProducts,
    setUserGroups,
    userGroups,
    authData,
    provider,
    setSyncConfig,
  } = useRosterSyncContext();
  const wizardRef = useRef(null);
  const initWizard = useRef({
    stepIndex: STEPS.findIndex(
      (step) => step.id === (currentStep || ROSTER_SYNC_STEPS.PROVIDER.id)
    ),
    steps: STEPS.map((step) => ({name: step.id})),
  });
  const {getUserGroups, createOrgRosterSync} = useEduRosterSyncV3();

  const onInitWizard = useCallback(
    (inputWizard) => {
      wizardRef.current = inputWizard;
    },
    [wizardRef]
  );

  const canGoNext = useCallback(() => {
    switch (currentStep) {
      case ROSTER_SYNC_STEPS.PROVIDER.id:
        return !!provider;
      case ROSTER_SYNC_STEPS.SETTINGS.id:
        // The admin must select the frequency, group, and at least one app
        return !!selectedGroupOption && selectedApps.length > 0 && !!frequency;
      case ROSTER_SYNC_STEPS.PROFILES.id:
        // The profiles step is optional,
        // so we can use the confirm button
        return true;
      default:
        return false;
    }
  }, [currentStep, frequency, selectedApps, selectedGroupOption, provider]);

  const handleProviderLogin = useCallback(() => {
    if (provider === SYNC_PROVIDERS.CLEVER) {
      ExternalAuthService.startCleverAuthentication();
    } else if (provider === SYNC_PROVIDERS.CLASSLINK) {
      ExternalAuthService.startClassLinkAuthentication();
    }
  }, [provider]);

  const handleBack = useCallback(() => {
    if (currentStep === ROSTER_SYNC_STEPS.PROVIDER.id) {
      onClose();
      dialog.dismiss();
    } else {
      wizardRef.current.goToPreviousStep();
    }
  }, [currentStep, wizardRef, dialog, onClose]);

  const getProducts = useCallback(async () => {
    const response = await getOrganizationsProducts({
      orgId: rootStore.organizationStore.activeOrgId,
    });
    const productObjects = await response.json();
    const selectedProductFamilies = getSelectedProductFamilies(selectedApps);
    return productObjects
      .filter((product) => selectedProductFamilies.includes(product.family))
      .map((product) => new Product(product));
  }, [selectedApps, getOrganizationsProducts]);

  // Helper function to check if selections have changed
  // This is used to determine if we need to refetch user groups
  // We don't want unnecessary refetches because it will result in having to select product profiles again
  const haveSelectionsChanged = useCallback(() => {
    // Check if group option has changed
    // Example: From "All in one" to "Group by role"
    if (previousSelectionsRef.current.groupOption !== selectedGroupOption) {
      return true;
    }

    // Check if the number of apps has changed
    // Example: From only Express to both Express and CC All Apps
    const prevApps = previousSelectionsRef.current.apps;
    if (prevApps.length !== selectedApps.length) {
      return true;
    }

    // Check if any app is different
    // Example: From only Express to CC All Apps
    return selectedApps.some((app) => !prevApps.includes(app));
  }, [selectedGroupOption, selectedApps]);

  const handleSettingsStep = useCallback(async () => {
    try {
      setIsLoading(true);

      // Check if we need to refetch user groups
      const needToRefetch = haveSelectionsChanged() || !userGroups;

      if (needToRefetch) {
        // Update previous selections reference
        previousSelectionsRef.current = {
          apps: [...selectedApps],
          groupOption: selectedGroupOption,
        };

        const [userGroupsResult, productsResult] = await Promise.allSettled([
          getUserGroups({
            grouping: selectedGroupOption,
            products: selectedApps,
          }),
          getProducts(),
        ]);

        setProducts(productsResult.status === 'rejected' ? null : productsResult.value);

        if (userGroupsResult.status === 'rejected') {
          throw userGroupsResult.reason;
        }

        setUserGroups(userGroupsResult.value.data.userGroups);
      }
      // If selections haven't changed and we already have user groups,
      // we don't need to refetch
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.rosterSync.errors.getUserGroups',
        })
      );
      setUserGroups(null);
    } finally {
      setIsLoading(false);
      wizardRef.current.goToNextStep();
    }
  }, [
    getProducts,
    getUserGroups,
    haveSelectionsChanged,
    intl,
    selectedApps,
    selectedGroupOption,
    setProducts,
    setUserGroups,
    userGroups,
  ]);

  const handleCreateSync = useCallback(async () => {
    try {
      setIsLoading(true);
      const {data: syncConfig} = await createOrgRosterSync({
        accessToken: authData.accessToken,
        districtId: authData.districtId,
        grouping: selectedGroupOption,
        products: selectedApps,
        rosterProvider: provider,
        syncSchedule: frequency,
        userGroups: userGroups.map(prepareUserGroupPayload),
      });
      setSyncConfig({...syncConfig, rosterSource: syncConfig.rosterSource.toLowerCase()});
      showSuccessToast(
        intl.formatMessage({
          id: 'settings.rosterSync.success.createSync',
        })
      );
      onClose();
      dialog.dismiss();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.rosterSync.errors.createSync',
        })
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    authData,
    createOrgRosterSync,
    dialog,
    frequency,
    intl,
    onClose,
    selectedApps,
    selectedGroupOption,
    provider,
    userGroups,
    setSyncConfig,
  ]);

  const getNextButtonMessageId = useCallback(() => {
    switch (currentStep) {
      case ROSTER_SYNC_STEPS.PROVIDER.id:
        switch (provider) {
          case SYNC_PROVIDERS.CLEVER:
            return 'settings.rosterSync.setup.modal.button.loginWithClever';
          case SYNC_PROVIDERS.CLASSLINK:
            return 'settings.rosterSync.setup.modal.button.loginWithClasslink';
          default:
            return 'common.modal.buttons.next';
        }
      case ROSTER_SYNC_STEPS.PROFILES.id:
        return userGroups
          ? 'common.modal.buttons.confirm'
          : 'settings.rosterSync.profiles.error.retry';
      default:
        return 'common.modal.buttons.next';
    }
  }, [currentStep, provider, userGroups]);

  const handleNext = useCallback(async () => {
    switch (currentStep) {
      case ROSTER_SYNC_STEPS.PROVIDER.id:
        handleProviderLogin();
        break;
      case ROSTER_SYNC_STEPS.SETTINGS.id:
        await handleSettingsStep();
        break;
      case ROSTER_SYNC_STEPS.PROFILES.id:
        if (userGroups) {
          await handleCreateSync();
        } else {
          await handleSettingsStep();
        }
        break;
      default:
        break;
    }
  }, [currentStep, handleProviderLogin, handleSettingsStep, handleCreateSync, userGroups]);

  return {
    canGoNext,
    getNextButtonMessageId,
    handleBack,
    handleNext,
    initWizard: initWizard.current,
    isLoading,
    onInitWizard,
  };
};

export default useRosterSyncWizard;
