import {Contract, Locale, dispatchUiEventAnalytics, eventBus} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {DialogContainer} from '@adobe/react-spectrum';
import {withAEMContent} from '@pandora/react-aem-content-provider';
import {EditContractName} from '@pandora/react-edit-contract-name';
import {ContractMiniAppContentModel} from '@pandora/react-mini-app-contract';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getContractNameList} from 'features/account/utils/accountUtils';
import {CART_EVENT} from 'features/offers/freeOfferCartConstants';

/**
 * @description Render Edit Contract Name modal
 */
const EditContractNameWrapper = ({contract, isOpen, onClose}) => {
  const intl = useIntl();
  const contractNames = getContractNameList();

  const onEditContractNameSuccess = () => {
    showSuccess(
      intl.formatMessage({
        id: 'account.editContractName.success.toast',
      })
    );
    // Refresh contract name
    eventBus.emit(CART_EVENT.SUBMIT, this);

    dispatchUiEventAnalytics({
      eventAction: 'save',
      eventName: 'editContractDisplayName',
      webInteraction: {
        name: 'editContractDisplayName:save',
      },
    });
    onClose();
  };

  const onEditContractNameCancel = () => {
    dispatchUiEventAnalytics({
      eventAction: 'cancel',
      eventName: 'editContractDisplayName',
      webInteraction: {
        name: 'editContractDisplayName:cancel',
      },
    });
    onClose();
  };

  const onEditContractNameError = () => {
    showError(
      intl.formatMessage({
        id: 'account.editContractName.error.toast',
      })
    );
  };

  const EditContractNameWithContent = withAEMContent(
    'businesstrials/account-contract-mini-app',
    'reactMiniAppAccountContractV1Main',
    ContractMiniAppContentModel
  )(EditContractName);

  return (
    <DialogContainer onDismiss={onClose}>
      {isOpen && (
        <EditContractNameWithContent
          aem={{locale: Locale.get().activeLanguageBCP47Code, usePlaceholderContent: true}}
          contractDisplayName={contract.name}
          contractId={contract.id}
          contractNameList={contractNames}
          contractNamePrefix={contract?.displayNamePrefix}
          onClose={onEditContractNameCancel}
          onError={onEditContractNameError}
          onSuccess={onEditContractNameSuccess}
          orgId={contract.orgId}
        />
      )}
    </DialogContainer>
  );
};

EditContractNameWrapper.propTypes = {
  /**
   * Contract data to be used for editing contract name
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * Whether to open Edit Contract Name modal or not
   */
  isOpen: PropTypes.bool,
  /**
   * A function to handle closing of Edit Contract Name modal
   */
  onClose: PropTypes.func,
};

export default EditContractNameWrapper;
