// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {TableSectionTable} from '@admin-tribe/acsc-ui';
import {Flex, Text} from '@adobe/react-spectrum';
import {TableSection} from '@pandora/react-table-section';
import UserGroupIcon from '@spectrum-icons/workflow/UserGroup';
import React from 'react';
import {useIntl} from 'react-intl';

import {useRosterSyncContext} from '../RosterSyncContext';

import ProductProfilesCell from './ProductProfilesCell';

const GroupsProfilesTableSection = () => {
  const intl = useIntl();
  const {userGroups} = useRosterSyncContext();

  const columnDescriptor = [{key: 'userGroupName'}, {key: 'productProfiles'}];
  const renderers = {
    productProfiles: ({item}) => <ProductProfilesCell userGroup={item} />,
    userGroupName: ({item}) => (
      <Flex alignItems="center" gap="size-100">
        <UserGroupIcon size="S" />
        <Text>{item.userGroupName}</Text>
      </Flex>
    ),
  };

  return (
    <TableSection
      getItemKey={(item) => item.userGroupId}
      items={userGroups?.map((item) => ({...item, key: item.userGroupId})) ?? []}
      pageNumber={0}
    >
      <TableSectionTable
        aria-label={intl.formatMessage({id: 'settings.rosterSync.profiles.table.ariaLabel'})}
        columnDescriptor={columnDescriptor}
        columnNamespace="settings.rosterSync.profiles.table.columns"
        renderers={renderers}
      />
    </TableSection>
  );
};

export default GroupsProfilesTableSection;
