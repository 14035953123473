import {eventBus, log, navBus} from '@admin-tribe/acsc';
import {
  OverlayWait,
  Page,
  PageActions,
  PageBanner,
  PageBanners,
  PageContent,
  PageHeader,
  showSuccess,
} from '@admin-tribe/acsc-ui';
import {Item, TabList, TabPanels, Tabs, View} from '@adobe/react-spectrum';
import {
  SUPPORT_TICKET_RECORD_TYPE,
  canViewSupportContactInfo,
} from '@pandora/data-model-support-ticket';
import {useContentEntry} from '@pandora/react-content-provider';
import {SupportPlanSummary, SupportPlanSummaryContentModel} from '@pandora/react-support-ticket';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation} from 'react-router-dom';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import rootStore from 'core/RootStore';
import chatProvider from 'core/services/chat/chatProvider';
import {useChatState} from 'core/services/chat/ready/ChatStateProvider';

import useDocumentTitle from '../../../../common/hooks/useDocumentTitle';
import AdobeStatusSummary from '../../components/adobe-status-summary/AdobeStatusSummary';
import SupportSummaryQuickActions from '../../components/support-summary-quick-actions/SupportSummaryQuickActions';
import SupportContactInfo from '../../components/support-ticket-contact-info/SupportContactInfo';
import useProductEntitlementList from '../../hooks/useProductEntitlementList';
import useSupportSummary from '../../hooks/useSupportSummary';
import {
  PATH_EXPERT_SESSIONS_OPEN,
  PATH_EXPERT_SESSION_DETAILS,
  PATH_SUPPORT_CASES_OPEN,
  PATH_SUPPORT_CASE_DETAILS,
} from '../../routing/supportPaths';
import {MESSAGE} from '../../support.constants';

/**
 * Shows Support Summary page which has Quick action buttons and Help topics
 */
const SupportSummaryPage = () => {
  const {pathname} = useLocation();
  const {chatStateReady} = useChatState();
  const {fetchProductEntitlementList} = useProductEntitlementList();
  const intl = useIntl();
  const pageTitle = intl.formatMessage({
    id: 'support.summary.title',
  });
  useDocumentTitle({defaultTitle: pageTitle});

  const {
    accessEnterpriseSupport,
    cloudType,
    contractList,
    hasError,
    isDistributorOrg,
    isLoading,
    isOrgOrSupportAdminOrPSA,
    productEntitlementList,
    productList,
    setCloudType,
    setHasError,
    setIsLoading,
    setProductEntitlementList,
    setShowBanner,
    showBanner,
    tabs,
    tabsToShow,
  } = useSupportSummary();

  const content = useContentEntry(SupportPlanSummaryContentModel);

  // Registering event for banner
  useEffect(() => {
    eventBus.registerEventHandler((eventId) => {
      if (eventId === MESSAGE.OPEN.TEAM_EXPERT_SESSION_BANNER) {
        setShowBanner(true);
      }
    });
  }, [setShowBanner]);

  const fetchProductEntitlementListAsync = useCallback(async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      setProductEntitlementList(await fetchProductEntitlementList());
    } catch (error) {
      setHasError(true);
      log.error('Unable to fetch product entitlement list', error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchProductEntitlementList, setIsLoading, setHasError, setProductEntitlementList]);

  // Fetching product entitlement list
  useEffect(() => {
    if (accessEnterpriseSupport) {
      fetchProductEntitlementListAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run once
  }, []);

  const onSupportTicketCreated = (ticketId, recordType) => {
    showSuccess(
      intl.formatMessage(
        {
          id:
            recordType === SUPPORT_TICKET_RECORD_TYPE.CASE
              ? 'pandora.reactMiniAppSupportTicketContent.createCaseModalContent.supportCaseCreatingViewContentModel.caseCreated'
              : 'pandora.reactMiniAppSupportTicketContent.requestExpertSessionModalContentModel.expertSessionCreated',
        },
        {caseId: ticketId, expertSessionId: ticketId}
      )
    );

    navBus.navigate(
      generatePath(
        recordType === SUPPORT_TICKET_RECORD_TYPE.CASE
          ? PATH_SUPPORT_CASE_DETAILS
          : PATH_EXPERT_SESSION_DETAILS,
        {
          orgId: rootStore.organizationStore.activeOrgId,
          ticketId,
        }
      )
    );
  };

  // Find the key for the current url.
  // If not found it is a bug but default to the Tabs default.
  const selectedKey = useMemo(
    () => tabs.find((tab) => tab.pathname === pathname)?.key ?? null,
    [pathname, tabs]
  );

  const onChange = (itemKey) => {
    if (itemKey !== selectedKey) {
      setCloudType(itemKey);
      const toPath = tabsToShow.find((tab) => tab.key === itemKey)?.pathname;
      navBus.navigate(toPath);
    }
  };

  return (
    <Page data-testid="support-summary-page">
      <PageBanners>
        <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_SUPPORT} />
        {showBanner && (
          <PageBanner
            header={intl.formatMessage({
              id: 'support.summaryRequestExpertSessionBanner.alertHeader',
            })}
          >
            {intl.formatMessage({id: 'support.summaryRequestExpertSessionBanner.alertMessage'})}
          </PageBanner>
        )}
      </PageBanners>

      <PageHeader data-testid="support-summary-page-title" title={pageTitle} />
      <PageActions>
        {!isDistributorOrg && canViewSupportContactInfo(contractList) && <SupportContactInfo />}
      </PageActions>
      <PageContent>
        {isOrgOrSupportAdminOrPSA ? (
          <SupportSummaryQuickActions
            canAccessEnterpriseSupport={accessEnterpriseSupport}
            canDisplayChat={chatStateReady && chatProvider.canDisplayChat()}
            cloudType={cloudType}
            onExpertSessionCreated={(ticketId) =>
              onSupportTicketCreated(ticketId, SUPPORT_TICKET_RECORD_TYPE.EXPERT)
            }
            onGoToOpenCases={() =>
              navBus.navigate(
                generatePath(PATH_SUPPORT_CASES_OPEN, {
                  orgId: rootStore.organizationStore.activeOrgId,
                })
              )
            }
            onGoToOpenSessions={() =>
              navBus.navigate(
                generatePath(PATH_EXPERT_SESSIONS_OPEN, {
                  orgId: rootStore.organizationStore.activeOrgId,
                })
              )
            }
            onSupportCaseCreated={(ticketId) =>
              onSupportTicketCreated(ticketId, SUPPORT_TICKET_RECORD_TYPE.CASE)
            }
          />
        ) : null}
        <OverlayWait isLoading={isLoading}>
          {accessEnterpriseSupport && !isDistributorOrg && (
            <View backgroundColor="static-white" flex="1" margin="size-50" padding="size-160">
              <SupportPlanSummary
                content={content}
                hasError={hasError}
                onTryAgain={fetchProductEntitlementListAsync}
                orgId={rootStore.organizationStore.activeOrgId}
                productEntitlementList={productEntitlementList}
                productList={productList}
              />
            </View>
          )}
        </OverlayWait>
        {tabsToShow.length > 1 && (
          <Tabs
            aria-label={intl.formatMessage({id: 'support.helpTopicTabs.ariaLabel'})}
            items={tabsToShow}
            onSelectionChange={onChange}
            selectedKey={selectedKey}
          >
            <TabList>{(item) => <Item key={item.key}>{item.name}</Item>}</TabList>
            <TabPanels>{(item) => <Item key={item.key}>{item.content}</Item>}</TabPanels>
          </Tabs>
        )}
        {tabsToShow.length === 1 && tabsToShow[0].content}
        <AdobeStatusSummary />
      </PageContent>
    </Page>
  );
};

export default SupportSummaryPage;
