// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {Flex, Image} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import {PROVIDER_CONFIGS} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';

const ProviderLogoAndName = () => {
  const {syncConfig} = useRosterSyncContext();

  return (
    <Flex alignItems="center" gap="size-200">
      <Image
        alt=""
        height="size-500"
        src={PROVIDER_CONFIGS[syncConfig.rosterSource].logo}
        width="size-500"
      />
      <strong>
        <FormattedMessage id={PROVIDER_CONFIGS[syncConfig.rosterSource].name} />
      </strong>
    </Flex>
  );
};

export default ProviderLogoAndName;
