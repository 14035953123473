// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {DialogTrigger, Link} from '@adobe/react-spectrum';
import {
  AssignmentModalContentModel,
  AssignmentModalContextProvider,
  ITEM_TYPE,
  ProductAssignmentModal,
  TARGET_TYPE,
  useAssignmentModal,
} from '@pandora/react-assignment-modal';
import {AssignmentModalSectionContentModel} from '@pandora/react-assignment-section';
import {useContentEntry} from '@pandora/react-content-provider';
import {ProductIconList} from '@pandora/react-product-icon-list';
import {EnDashDefaultContent} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import {APP_TYPES} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';
import {getSelectedProductFamilies} from 'features/settings/components/roster-sync-settings/rosterSyncUtils';

const ProductProfilesCell = ({userGroup}) => {
  const {products, setUserGroups} = useRosterSyncContext();
  const intl = useIntl();

  const productsForUserGroup = useMemo(() => {
    if (products === null || products.length === 0) {
      return [];
    }

    const {userGroupName} = userGroup;

    // there might be localization for user group names in the future, for now this is the best we can do
    const groupProductFamilies = getSelectedProductFamilies([
      ...(userGroupName.includes('Express') ? [APP_TYPES.EXPRESS] : []),
      ...(userGroupName.includes('CC All Apps') ? [APP_TYPES.CREATIVE_CLOUD] : []),
    ]);

    return products.filter((product) => groupProductFamilies.includes(product.family));
  }, [userGroup, products]);

  const modalContext = useAssignmentModal({
    selectableItemsMap: {
      [ITEM_TYPE.PRODUCTS]: productsForUserGroup,
      [ITEM_TYPE.PRODUCT_PROFILES]: [],
      [ITEM_TYPE.USER_GROUPS]: [],
    },
  });

  const content = useContentEntry(AssignmentModalSectionContentModel);
  const assignmentModalContent = content.search(AssignmentModalContentModel)[0];

  const handleModalSubmit = useCallback(
    () =>
      setUserGroups((currentUserGroups) =>
        currentUserGroups.map((group) =>
          group.userGroupId === userGroup.userGroupId
            ? {
                ...group,
                productProfiles: modalContext.calculateSelectedItemsAfterToggle(
                  ITEM_TYPE.PRODUCT_PROFILES
                ),
              }
            : group
        )
      ),
    [setUserGroups, modalContext, userGroup.userGroupId]
  );

  if (productsForUserGroup.length === 0) {
    return <EnDashDefaultContent />;
  }
  return (
    <AssignmentModalContextProvider value={modalContext}>
      <DialogTrigger>
        {userGroup.productProfiles?.length > 0 ? (
          <ProductIconList
            products={userGroup.productProfiles.map((productProfile) => productProfile.product)}
          />
        ) : (
          <Link>
            {intl.formatMessage({id: 'settings.rosterSync.profiles.table.assignProductProfiles'})}
          </Link>
        )}
        {(close) => (
          <ProductAssignmentModal
            content={assignmentModalContent}
            onDismiss={close}
            onSubmit={handleModalSubmit}
            orgId={rootStore.organizationStore.activeOrgId}
            targets={[TARGET_TYPE.PRODUCTS, TARGET_TYPE.PRODUCT_PROFILES]}
          />
        )}
      </DialogTrigger>
    </AssignmentModalContextProvider>
  );
};

ProductProfilesCell.propTypes = {
  userGroup: PropTypes.shape({
    productProfiles: PropTypes.arrayOf(
      PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types -- exact interface doesn't matter to this component and is not exported by Pandora package
        product: PropTypes.any,
      })
    ),
    userGroupId: PropTypes.string,
    userGroupName: PropTypes.string,
  }),
};
export default ProductProfilesCell;
