import {authentication, configStore} from '@admin-tribe/acsc';
import axios from 'axios';

let clientId, url;

(async function loadConfig() {
  ({url, clientId} = await configStore.getServiceConfiguration('adobeioConsole'));
  url = `${url}/console/organizations`;
})();

const getHeaders = () => {
  const headers = {
    'X-Api-Key': clientId,
  };
  const token = authentication.getAccessToken()?.token;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

/**
 * @description Fetches a specific integration.
 *
 * @param {Object} params - GET params for API
 * @param {String} params.intId - integration id
 * @param {String} params.orgId - the AdobeIO organization id, not the usual Renga orgId
 * @returns {Promise} promise which will resolve with the response
 */
const getIntegration = ({intId, orgId}) =>
  axios.get(`${url}/${orgId}/integrations/${intId}`, {
    headers: getHeaders(),
  });

/**
 * @description Fetches the list of AdobeIO organizations. This is used to map
 *   the Adobeid orgId to the Renga orgId.
 *
 * @returns {Promise} promise which will resolve with the list of integrations
 */
const getOrganizations = async () => {
  const response = await axios.get(`${url}`, {
    headers: getHeaders(),
  });

  return response.data;
};

/**
 * @description Fetches integrations for a single organization.
 *
 * @param {Object} params - GET params for API
 * @param {String} params.orgId - the AdobeIO organization id, not the usual Renga orgId
 * @param {String[]} params.techAccountIdsToSearch - the list of tech account ids to search for
 * @param {Number} params.page - the page index to fetch
 * @param {Number} params.size - the page size to fetch
 * @returns {Promise} promise which will resolve with data for the
 *     integrations. Note - this response does not contain a nested data
 *     property since a property is extracted from the payload and provided to
 *     the caller directly.
 */
const getOrganizationIntegrations = async ({
  orgId,
  techAccountIdsToSearch,
  page = 0,
  size = 50,
}) => {
  const response = await axios.get(`${url}/${orgId}/integrations`, {
    headers: getHeaders(),
    params: {expanded: true, page, search: techAccountIdsToSearch?.join(','), size},
  });

  return response.data.content;
};

/**
 * @description Fetches the credentials-isv-bindings for a single organization.
 *
 * @param {Object} params - GET params for API
 * @param {String} params.orgId - the AdobeIO organization id, not the usual Renga orgId
 * @param {Number} params.page - the page index to fetch
 * @param {Number} params.size - the page size to fetch
 * @returns {Promise} promise which will resolve with data for the
 */
const getOrganizationCredentialsIsvBindings = async ({orgId, page = 0, size = 50}) => {
  const response = await axios.get(`${url}/${orgId}/credentials-isv-bindings`, {
    headers: getHeaders(),
    params: {page, size},
  });

  return response.data;
};

const adobeioConsoleIntegrations = {
  getIntegration,
  getOrganizationCredentialsIsvBindings,
  getOrganizationIntegrations,
  getOrganizations,
};

export default adobeioConsoleIntegrations;
