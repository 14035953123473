const ADD_PRODUCTS_MINI_APP_NAME = 'AddProductMiniApp';
const QUICK_ASSIGN_MINI_APP_NAME = 'quick-assign-app';
const CONFIRMATION_MINI_APP_NAME = 'confirmation-app';
const FAILED = 'failed';
const SUBMITTED = 'submitted';

const STATUS_CODE = {
  CANCEL_WITHOUT_PROGRESS: 0,
  FULLY_COMPLETE: 10,
  GO_BACK: -1,
};

const ORCHESTRATION_URL = 'services.commerceOrchestration';
const ORCHESTRATION_MINI_APP_NAME = 'pandora-workflow';

export {
  ADD_PRODUCTS_MINI_APP_NAME,
  CONFIRMATION_MINI_APP_NAME,
  FAILED,
  ORCHESTRATION_MINI_APP_NAME,
  ORCHESTRATION_URL,
  SUBMITTED,
  STATUS_CODE,
  QUICK_ASSIGN_MINI_APP_NAME,
};
