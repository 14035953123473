import {Flex, Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

const AddAppIntegrationPublisherDetails = ({selectedAppFromList}) => {
  const contactDetails = selectedAppFromList?.publisher?.contactDetails;
  if (!contactDetails) {
    return null;
  }

  return (
    <View marginTop="size-200">
      <Heading level={4}>
        <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.heading.publisherDetails" />
      </Heading>
      <View>
        <ul>
          {contactDetails.email && <li>{contactDetails.email}</li>}
          {contactDetails.phone && (
            <li>
              {contactDetails.phone.countryCode} {contactDetails.phone.number}
            </li>
          )}
          {contactDetails.address && (
            <li>
              <Flex direction="column" gap="size-50">
                <View>{contactDetails.address.line1}</View>
                {contactDetails.address.line2 && <View>{contactDetails.address.line2}</View>}
                <View>
                  {contactDetails.address.city}, {contactDetails.address.state}{' '}
                  {contactDetails.address.postalCode}
                </View>
                <View>{contactDetails.address.country}</View>
              </Flex>
            </li>
          )}
        </ul>
      </View>
    </View>
  );
};

AddAppIntegrationPublisherDetails.propTypes = {
  selectedAppFromList: PropTypes.shape({
    publisher: PropTypes.shape({
      contactDetails: PropTypes.shape({
        address: PropTypes.shape({
          city: PropTypes.string,
          country: PropTypes.string,
          line1: PropTypes.string,
          line2: PropTypes.string,
          postalCode: PropTypes.string,
          state: PropTypes.string,
        }),
        email: PropTypes.string,
        phone: PropTypes.shape({
          countryCode: PropTypes.string,
          number: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default AddAppIntegrationPublisherDetails;
