// Advanced Settings constants
const minute = 60;
const hourInSeconds = 60 * minute;
const dayInSeconds = 24 * hourInSeconds;

const TIMESTAMPS = {
  DAYS_3: {key: 'days_3', value: 3 * dayInSeconds},
  DAYS_7: {key: 'days_7', value: 7 * dayInSeconds},
  DAYS_14: {key: 'days_14', value: 14 * dayInSeconds},
  HOURS_1: {key: 'hours_1', value: hourInSeconds},
  HOURS_3: {key: 'hours_3', value: 3 * hourInSeconds},
  HOURS_8: {key: 'hours_8', value: 8 * hourInSeconds},
  HOURS_12: {key: 'hours_12', value: 12 * hourInSeconds},
  HOURS_24: {key: 'hours_24', value: dayInSeconds},
  MINUTES_15: {key: 'minutes_15', value: 15 * minute},
};

const UNSET = {key: 'unset'};

const NOT_SET = {
  id: UNSET.key,
  label: `settings.authSettings.advancedSettings.timestamps.notSetDefault`,
};

const TIME_VALUES_OPTIONS = [
  {id: UNSET.key},
  {
    id: TIMESTAMPS.HOURS_1.key,
    label: 'settings.authSettings.advancedSettings.timestamps.hours_1',
    value: TIMESTAMPS.HOURS_1.value,
  },
  {
    id: TIMESTAMPS.HOURS_3.key,
    label: 'settings.authSettings.advancedSettings.timestamps.hours_3',
    value: TIMESTAMPS.HOURS_3.value,
  },
  {
    id: TIMESTAMPS.HOURS_8.key,
    label: 'settings.authSettings.advancedSettings.timestamps.hours_8',
    value: TIMESTAMPS.HOURS_8.value,
  },
  {
    id: TIMESTAMPS.HOURS_12.key,
    label: 'settings.authSettings.advancedSettings.timestamps.hours_12',
    value: TIMESTAMPS.HOURS_12.value,
  },
  {
    id: TIMESTAMPS.HOURS_24.key,
    label: 'settings.authSettings.advancedSettings.timestamps.hours_24',
    value: TIMESTAMPS.HOURS_24.value,
  },
  {
    id: TIMESTAMPS.DAYS_3.key,
    label: 'settings.authSettings.advancedSettings.timestamps.days_3',
    value: TIMESTAMPS.DAYS_3.value,
  },
  {
    id: TIMESTAMPS.DAYS_7.key,
    label: 'settings.authSettings.advancedSettings.timestamps.days_7',
    value: TIMESTAMPS.DAYS_7.value,
  },
  {
    id: TIMESTAMPS.DAYS_14.key,
    label: 'settings.authSettings.advancedSettings.timestamps.days_14',
    value: TIMESTAMPS.DAYS_14.value,
  },
];

const MAX_SESSION_LIFE_OPTIONS = TIME_VALUES_OPTIONS.map((timestamp) => {
  if (timestamp.id === UNSET.key) {
    return NOT_SET;
  }
  return timestamp;
});

const MAX_SESSION_IDLE_TIME_OPTIONS = TIME_VALUES_OPTIONS.map((timestamp) => {
  if (timestamp.id === UNSET.key) {
    return NOT_SET;
  }
  return timestamp;
});

const MAX_SESSION_IDLE_15MIN_OPTION = {
  id: TIMESTAMPS.MINUTES_15.key,
  label: 'settings.authSettings.advancedSettings.timestamps.minutes_15',
  value: TIMESTAMPS.MINUTES_15.value,
};

// TODO: integrate MAX_SESSION_IDLE_15MIN_OPTION in TIME_VALUES_OPTIONS
// when temp_advancedsettings_idle15 is merged
const FINAL_MAX_SESSION_IDLE_TIME_OPTIONS = [...MAX_SESSION_IDLE_TIME_OPTIONS];
FINAL_MAX_SESSION_IDLE_TIME_OPTIONS.splice(1, 0, MAX_SESSION_IDLE_15MIN_OPTION);

const SOCIAL_PROVIDERS = {
  APPLE: 'APPLE',
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  KAKAO: 'KAKAO',
  LINE: 'LINE',
  MICROSOFT: 'MICROSOFT',
};

const SOCIAL_PROVIDER_NAMES = {
  APPLE: 'settings.authSettings.socialLogin.providers.apple',
  FACEBOOK: 'settings.authSettings.socialLogin.providers.facebook',
  GOOGLE: 'settings.authSettings.socialLogin.providers.google',
  KAKAO: 'settings.authSettings.socialLogin.providers.kakao',
  LINE: 'settings.authSettings.socialLogin.providers.line',
  MICROSOFT: 'settings.authSettings.socialLogin.providers.microsoft',
};

export {
  TIME_VALUES_OPTIONS,
  MAX_SESSION_LIFE_OPTIONS,
  MAX_SESSION_IDLE_TIME_OPTIONS,
  FINAL_MAX_SESSION_IDLE_TIME_OPTIONS,
  UNSET,
  NOT_SET,
  SOCIAL_PROVIDERS,
  SOCIAL_PROVIDER_NAMES,
};
