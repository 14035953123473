import {FULFILLABLE_ITEM_DELEGATION_TYPE, feature} from '@admin-tribe/acsc';

/**
 * @description Method to return products with QUOTA fulfilled items that are assignable to users
 *
 * @param {LicenseGroup} product - the product
 * @returns {Array} List of Products that are assignable to users and with QUOTA fulfilled items
 *
 */
function getQuotaFulfilledItemsAndWithTarget(licenseGroup) {
  return feature.isEnabled('temp_delegation_type_dcomm')
    ? licenseGroup.getQuotaFulfilledItemsWithTarget({
        delegationConfigurable: true,
        delegationTargets:
          licenseGroup.product.processingInstructions?.license_data?.delegation_targets,
      })
    : licenseGroup.getQuotaFulfilledItems({
        delegationConfigurable: true,
        delegationType: FULFILLABLE_ITEM_DELEGATION_TYPE.PERSON,
      });
}

/**
 * @description Method to return products with SERVICE fulfilled items that are assignable to users
 *
 * @param {Product} product - the product
 * @returns {boolean} true when the product contains an organization type quota on-demand consumable FulfillableItem
 *
 */
function getServiceFulfilledItemsAndWithTarget(licenseGroup) {
  return feature.isEnabled('temp_delegation_type_dcomm')
    ? licenseGroup.getServiceFulfilledItemsWithTarget({delegationConfigurable: true})
    : licenseGroup.getServiceFulfilledItems({delegationConfigurable: true});
}

/**
 * @description Method to determine if the product contains a Delegation target or delegation type FulfillableItem
 *
 * @param {Product} product - the product
 * @returns {boolean} true when the product contains a Delegation target or delegation type FulfillableItem
 *
 */
function hasDelegation(product) {
  return feature.isEnabled('temp_delegation_type_dcomm')
    ? product.hasDelegationTarget()
    : product.fulfillableItemList.hasDelegationType();
}

/**
 * @description Method to determine if the product contains an organization type quota on-demand consumable FulfillableItem
 *
 * @param {Product} product - the product
 * @returns {boolean} true when the product contains an organization type quota on-demand consumable FulfillableItem
 *
 */
function hasOrgOnDemandAndConsumable(product) {
  return feature.isEnabled('temp_delegation_type_dcomm')
    ? product.fulfillableItemList.hasOrgOnDemandConsumableWithTarget(
        product.processingInstructions?.license_data?.delegation_targets
      )
    : product.fulfillableItemList.hasOrgOnDemandConsumable();
}

/**
 * @description Method to determine if the product contains an organization delegatable target or delegation type FulfillableItem
 * And the product does not contain an organization DELEGATABLE target or delegation type FulfillableItem
 *
 * @param {Product} product - the product
 * @returns {boolean} true when the product contains an organization delegatable target or type FulfillableItem
 *
 */
function hasOrgDelegatableAndTarget(product) {
  return feature.isEnabled('temp_delegation_type_dcomm')
    ? product.hasDelegationTarget() &&
        product.fulfillableItemList.hasOrgDelegatableWithTarget(
          product.processingInstructions?.license_data?.delegation_targets
        )
    : product.fulfillableItemList.hasDelegationType() &&
        product.fulfillableItemList.hasOrgDelegatable();
}

export {
  getQuotaFulfilledItemsAndWithTarget,
  getServiceFulfilledItemsAndWithTarget,
  hasDelegation,
  hasOrgOnDemandAndConsumable,
  hasOrgDelegatableAndTarget,
};
