// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import groupBy from 'lodash/groupBy';

import {
  APP_TYPES_TO_INTEGRATION_NAMES,
  APP_TYPES_TO_PRODUCT_FAMILIES,
  PROVIDER_CONFIGS,
} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';

/* Replaces product profiles field with product profile information to send to Edu, or deletes field if profile selection ended up empty. */
const prepareUserGroupPayload = (userGroup) => {
  const userGroupCopy = {userGroupId: userGroup.userGroupId};

  if (!Object.hasOwn(userGroup, 'productProfiles')) {
    return userGroupCopy;
  }

  const profilesGroupedByProductId = groupBy(userGroup.productProfiles, 'productId');

  const productProfileInfo = Object.entries(profilesGroupedByProductId).map(
    ([productId, productProfiles]) => ({
      productId,
      productProfileIds: productProfiles.map(({id}) => id),
    })
  );

  return productProfileInfo.length > 0 ? {...userGroupCopy, productProfileInfo} : userGroupCopy;
};

/**
 * @param selectedApps - array of APP_TYPE values (see rosterSyncConstants.js)
 * @returns Array - single array containing all the APP_TYPES_TO_FAMILIES values for the input array
 */
const getSelectedProductFamilies = (selectedApps) =>
  selectedApps
    .map((app) => APP_TYPES_TO_PRODUCT_FAMILIES[app])
    .reduce((acc, curr) => [...acc, ...curr], []);

/**
 * Identifies which APP_TYPEs are missing from the available products.
 * An APP_TYPE is considered missing if none of its required product families are present in the available products.
 *
 * @param {Array} selectedApps - Array of APP_TYPE values (e.g., ['EXPRESS', 'CREATIVE_CLOUD'])
 * @param {Array} products - Array of available products with family property
 * @returns {Array} - Array of missing APP_TYPE values
 *
 * @example
 * // If no products with family 'SPARK' or 'CC_EXPRESS' are available:
 * getMissingProductTypes([APP_TYPES.EXPRESS], [{family: 'CC_ALL_APPS'}]) // Returns [APP_TYPES.EXPRESS]
 *
 * @example
 * // If products with both 'SPARK' and 'CC_ALL_APPS' families are available:
 * getMissingProductTypes([APP_TYPES.EXPRESS, APP_TYPES.CREATIVE_CLOUD], [{family: 'SPARK'}, {family: 'CC_ALL_APPS'}]) // Returns []
 *
 * @example
 * // If no products with family 'CC_ALL_APPS' are available:
 * getMissingProductTypes([APP_TYPES.EXPRESS, APP_TYPES.CREATIVE_CLOUD], [{family: 'SPARK'}]) // Returns [APP_TYPES.CREATIVE_CLOUD]
 *
 * @example
 * // If null value is passed for products (i.e. GET /products call failed)
 * getMissingProductTypes([APP_TYPES.EXPRESS, APP_TYPES.CREATIVE_CLOUD], null) // Returns []
 */
const getMissingProductTypes = (selectedApps, products = []) =>
  products === null
    ? []
    : selectedApps.filter((appType) => {
        const requiredFamilies = APP_TYPES_TO_PRODUCT_FAMILIES[appType] || [];
        // An app type is missing if none of its required families are present in products
        return !requiredFamilies.some((family) =>
          products.some((product) => product.family === family)
        );
      });

/**
 * @param app - APP_TYPE value
 * @param integrations - array of integrations returned by the Edu /district call
 * @returns boolean - whether the given app integration is deployed
 */
const isAppIntegrationDeployed = (app, integrations) =>
  integrations.some(
    (integration) =>
      integration.name === APP_TYPES_TO_INTEGRATION_NAMES[app] && integration.deployed
  );

/** Opens the given provider's portal in a new browser tab. */
const goToProviderPortal = (provider) => {
  const url = PROVIDER_CONFIGS[provider].url;

  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- not on server side
  window.open(url, '_blank');
};

export {
  getMissingProductTypes,
  isAppIntegrationDeployed,
  getSelectedProductFamilies,
  goToProviderPortal,
  prepareUserGroupPayload,
};
