// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {Button, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import accountAndBillingSVG from 'features/overview/components/quick-link-banner-section/svgs/AccountAndBilling.svg';
import addUsersSVG from 'features/overview/components/quick-link-banner-section/svgs/AddUsers.svg';
import assignLicensesSVG from 'features/overview/components/quick-link-banner-section/svgs/AssignLicenses.svg';
import buyLicensesSVG from 'features/overview/components/quick-link-banner-section/svgs/BuyLicenses.svg';
import buyLicensesAsPrimarySVG from 'features/overview/components/quick-link-banner-section/svgs/BuyLicensesAsPrimary.svg';
import consolidateTeamSVG from 'features/overview/components/quick-link-banner-section/svgs/ConsolidateTeam.svg';
import managePlanSVG from 'features/overview/components/quick-link-banner-section/svgs/ManagePlan.svg';
import reactivePlanSVG from 'features/overview/components/quick-link-banner-section/svgs/ReactivePlan.svg';
import updateBillingInfoSVG from 'features/overview/components/quick-link-banner-section/svgs/UpdateBillingInfo.svg';
import {dispatchAnalytics} from 'features/overview/components/quick-links-section/quickLinkUtils';

const ICON_MAP = {
  accountAndBilling: accountAndBillingSVG,
  addUsers: addUsersSVG,
  assignLicenses: assignLicensesSVG,
  buyLicenses: buyLicensesSVG,
  buyLicensesAsPrimary: buyLicensesAsPrimarySVG,
  consolidateTeam: consolidateTeamSVG,
  managePlan: managePlanSVG,
  reactivePlan: reactivePlanSVG,
  updateBillingInfo: updateBillingInfoSVG,
};

const QuickLinkIcon = ({alt, name}) =>
  ICON_MAP[name] ? <img alt={alt} height="20px" src={ICON_MAP[name]} width="20px" /> : null;

QuickLinkIcon.propTypes = {
  alt: PropTypes.string,
  name: PropTypes.string,
};

/* eslint-disable complexity -- jitra m3 feature flags raise complexity from 10 to 11 temporarily */
const QuickLinkButton = ({
  analyticsOptions,
  displayCount,
  handleCTA,
  iconAlt,
  iconName,
  label,
  count,
  countLimit,
  testId,
  variant,
  children = [],
}) => {
  const intl = useIntl();
  const displayLabel = label.includes('.') ? intl.formatMessage({id: label}) : label;
  const primary = variant === 'accent';
  const backgroundColor = primary ? undefined : 'white';
  const countBackgroundColour = primary ? 'gray-100' : 'blue-400';
  const countForegroundColour = primary ? 'black' : 'white';
  const iconNameActual = iconName === 'buyLicenses' && primary ? 'buyLicensesAsPrimary' : iconName;

  const onPress = () => {
    // dispatch m3 specific analytics
    if (analyticsOptions?.analyticsAction === 'priority-user-introductions') {
      dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: 'userintro:notification:quicklink',
      });
    }

    // dispatch quicklink-style analytics
    dispatchAnalytics({
      ...analyticsOptions,
      interaction: {
        ...analyticsOptions?.interaction,
        impression: 'QuicklinkBannerSection',
      },
    });
    handleCTA?.();
  };

  return (
    <>
      <Button
        data-testid={testId}
        onPress={onPress}
        UNSAFE_style={{
          alignItems: 'center',
          backgroundColor,
          border: 'none',
          boxShadow: '2px 3px 5px 1px rgb(0 0 0 / 10%)',
          display: 'flex',
        }}
        variant={variant}
      >
        <View
          backgroundColor={displayCount ? countBackgroundColour : ''}
          UNSAFE_style={{
            alignItems: 'center',
            borderRadius: '50%', // Make the view circular
            display: 'flex',
            height: '20px',
            justifyContent: 'center',
            width: '28px',
          }}
        >
          {displayCount ? (
            <Text id="quick-link-button-count" UNSAFE_style={{color: countForegroundColour}}>
              {countLimit && count >= countLimit ? `${countLimit}+` : count}
            </Text>
          ) : (
            <QuickLinkIcon alt={iconAlt} name={iconNameActual} />
          )}
        </View>
        <Text id="quick-link-button-label" marginStart="5px">
          {displayLabel}
        </Text>
      </Button>
      {children.map((ChildComponent) => (
        // we don't know what the children are, so we need to add a key that is not an index
        <React.Fragment key={ChildComponent.key || Math.random().toString(36).slice(2, 11)}>
          {ChildComponent}
        </React.Fragment>
      ))}
    </>
  );
};
/* eslint-enable complexity -- re-enablement */

QuickLinkButton.propTypes = {
  /**
   * analytics options as expected by quickLinkUtils.dispatchAnalytics
   */
  // eslint-disable-next-line react/forbid-prop-types -- method to pass to has too general an interface
  analyticsOptions: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.node),
  count: PropTypes.number,
  countLimit: PropTypes.number,
  displayCount: PropTypes.bool,
  handleCTA: PropTypes.func,
  iconAlt: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string,
  variant: PropTypes.string,
};

export default QuickLinkButton;
