// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {Flex, Heading, Text, View} from '@adobe/react-spectrum';
import {OptionSelector} from '@pandora/react-option-selector';
import Provider2 from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import OptionSelectorWrapper from 'features/settings/common/components/option-selector-wrapper/OptionSelectorWrapper';
import RosterSyncOptionCard from 'features/settings/components/roster-sync-settings/RosterSyncOptionCard';

import {useRosterSyncContext} from './RosterSyncContext';
import {SETUP_OPTIONS} from './rosterSyncConstants';

import './RosterSyncProviderCard.pcss';

const GroupOptionCard = ({title, description, selected}) => (
  <RosterSyncOptionCard selected={selected}>
    <Flex direction="column" gap="size-100">
      <Heading level={3}>
        <FormattedMessage id={title} />
      </Heading>
      <Text>
        <FormattedMessage id={description} />
      </Text>
    </Flex>
  </RosterSyncOptionCard>
);
GroupOptionCard.propTypes = {
  description: PropTypes.string,
  selected: PropTypes.string,
  title: PropTypes.string,
};

const GroupOptionSelector = () => {
  const {selectedGroupOption, setSelectedGroupOption} = useRosterSyncContext();

  // Preserve the selected option when navigating back to the settings step
  const setupOptionsWithSelection = SETUP_OPTIONS.map((option) => ({
    ...option,
    selected: option.id === selectedGroupOption,
  }));

  const onSelectionChange = (event) => event.selected && setSelectedGroupOption(event.data.id);

  return (
    <Provider2>
      <OptionSelectorWrapper>
        <OptionSelector
          data={setupOptionsWithSelection}
          options={{
            on_selection_change: onSelectionChange,
            preventDeselection: true,
          }}
          react_function={(props) => (
            <View>
              <GroupOptionCard {...props} />
            </View>
          )}
        />
      </OptionSelectorWrapper>
    </Provider2>
  );
};

export default GroupOptionSelector;
