const PATH_SETTINGS_ASSET = '/:orgId/settings/asset';
const PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS = '/:orgId/settings/asset/authorized-domains';
const PATH_SETTINGS_ASSET_CREDENTIALS = '/:orgId/settings/asset/credentials';
const PATH_SETTINGS_ASSET_MIGRATION = '/:orgId/settings/asset/migration';
const PATH_SETTINGS_ASSET_SHARING_OPTIONS = '/:orgId/settings/asset/sharing-options';
const PATH_SETTINGS_AUTHENTICATION = '/:orgId/settings/password-policy';
const PATH_SETTINGS_CONSOLE = '/:orgId/settings/console';
const PATH_SETTINGS_CONTENT_LOGS = '/:orgId/settings/content-logs';
const PATH_SETTINGS_ENCRYPTION = '/:orgId/settings/encryption';
const PATH_SETTINGS_IDENTITY = '/:orgId/settings/identity';
const PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS = '/:orgId/settings/identity/access-requests';
const PATH_SETTINGS_IDENTITY_DIRECTORIES = '/:orgId/settings/identity/directories';
const PATH_SETTINGS_IDENTITY_DOMAINS = '/:orgId/settings/identity/domains';
const PATH_SETTINGS_IDENTITY_GUIDED_SETUP = '/:orgId/settings/identity/guided-setup';
const PATH_SETTINGS_IDENTITY_TRUSTEES = '/:orgId/settings/identity/trustees';
const PATH_SETTINGS_ROOT = '/:orgId/settings';
const PATH_SETTINGS_SECURITY_CONTACTS = '/:orgId/settings/security-contacts';
const PATH_SETTINGS_SHARED_PROJECT_POLICIES = '/:orgId/settings/project-policies';
const PATH_SETTINGS_IDENTITY_ROSTER_SYNC = `${PATH_SETTINGS_IDENTITY}/roster-sync`;
const PATH_SETTINGS_NON_ADOBE_GENAI_MODELS_POLICY =
  '/:orgId/settings/asset/non-adobe-gen-ai-models';

/**
 * Directory details
 */
const PATH_DIRECTORY_DETAILS = '/:orgId/settings/identity/directories/:directoryId';

const DIRECTORY_DETAILS_TAB_NAV = {
  AUTHENTICATION: 'authentication',
  DOMAIN_ENFORCEMENT: 'domain-enforcement',
  DOMAINS: 'domains',
  OVERVIEW: 'overview',
  SYNC: 'sync',
  TRUSTEES: 'trustees',
};

const PATH_DIRECTORY_DETAILS_OVERVIEW = `${PATH_DIRECTORY_DETAILS}/${DIRECTORY_DETAILS_TAB_NAV.OVERVIEW}`;
const PATH_DIRECTORY_DETAILS_AUTHENTICATION = `${PATH_DIRECTORY_DETAILS}/${DIRECTORY_DETAILS_TAB_NAV.AUTHENTICATION}`;
const PATH_DIRECTORY_DETAILS_DOMAINS = `${PATH_DIRECTORY_DETAILS}/${DIRECTORY_DETAILS_TAB_NAV.DOMAINS}`;
const PATH_DIRECTORY_DETAILS_DOMAIN_ENFORCEMENT = `${PATH_DIRECTORY_DETAILS}/${DIRECTORY_DETAILS_TAB_NAV.DOMAIN_ENFORCEMENT}`;
const PATH_DIRECTORY_DETAILS_SYNC = `${PATH_DIRECTORY_DETAILS}/${DIRECTORY_DETAILS_TAB_NAV.SYNC}`;
const PATH_DIRECTORY_DETAILS_TRUSTEES = `${PATH_DIRECTORY_DETAILS}/${DIRECTORY_DETAILS_TAB_NAV.TRUSTEES}`;

export {
  PATH_SETTINGS_ASSET,
  PATH_SETTINGS_ASSET_SHARING_OPTIONS,
  PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS,
  PATH_SETTINGS_ASSET_CREDENTIALS,
  PATH_SETTINGS_ASSET_MIGRATION,
  PATH_SETTINGS_AUTHENTICATION,
  PATH_SETTINGS_CONSOLE,
  PATH_SETTINGS_CONTENT_LOGS,
  PATH_SETTINGS_ENCRYPTION,
  PATH_SETTINGS_IDENTITY,
  PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS,
  PATH_SETTINGS_IDENTITY_DIRECTORIES,
  PATH_SETTINGS_IDENTITY_DOMAINS,
  PATH_SETTINGS_IDENTITY_GUIDED_SETUP,
  PATH_SETTINGS_IDENTITY_TRUSTEES,
  PATH_SETTINGS_IDENTITY_ROSTER_SYNC,
  PATH_SETTINGS_NON_ADOBE_GENAI_MODELS_POLICY,
  PATH_SETTINGS_ROOT,
  PATH_SETTINGS_SECURITY_CONTACTS,
  PATH_SETTINGS_SHARED_PROJECT_POLICIES,

  // Directory details
  PATH_DIRECTORY_DETAILS,
  PATH_DIRECTORY_DETAILS_OVERVIEW,
  PATH_DIRECTORY_DETAILS_AUTHENTICATION,
  PATH_DIRECTORY_DETAILS_DOMAINS,
  PATH_DIRECTORY_DETAILS_DOMAIN_ENFORCEMENT,
  PATH_DIRECTORY_DETAILS_SYNC,
  PATH_DIRECTORY_DETAILS_TRUSTEES,
  DIRECTORY_DETAILS_TAB_NAV,
};
