// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {configStore} from '@admin-tribe/acsc';
import axios from 'axios';

import {getAuthorizationHeader} from 'common/api/httpUtils';
import rootStore from 'core/RootStore';

const ERROR_CODES = {
  CCALL_INTEGRATION_MISSING: 'CCALL_INTEGRATION_MISSING',
  CCX_INTEGRATION_MISSING: 'CCX_INTEGRATION_MISSING',
  LICENSE_NOT_AVAILABLE: 'LICENSE_NOT_AVAILABLE',
  LICENSE_VARIANTS_CONFLICT: 'LICENSE_VARIANTS_CONFLICT',
  USER_NOT_DISTRICT_ADMIN: 'USER_NOT_DISTRICT_ADMIN',
};

let baseUrl, clientId;

(async function loadConfig() {
  ({clientId, url: baseUrl} = await configStore.getServiceConfiguration('eduRosterSyncV3'));
  baseUrl = `${baseUrl}/api/v1`;
})();

const getHeaders = () => ({
  ...getAuthorizationHeader(),
  'X-Api-Key': clientId,
});

const getAccessToken = ({syncProvider, authCode, redirectUri}) =>
  axios.post(
    `${baseUrl}/tokens`,
    {
      code: authCode,
      redirectUri,
      rosterProvider: syncProvider,
    },
    {
      headers: getHeaders(),
    }
  );

const getRosterTenants = ({syncProvider, accessToken}) =>
  axios.get(`${baseUrl}/rosters/${syncProvider}/district`, {
    headers: {
      ...getHeaders(),
      'X-Roster-Authorization': accessToken,
    },
  });

const getUserGroups = ({grouping, products}) =>
  axios.get(`${baseUrl}/syncs/groups/${grouping}`, {
    headers: getHeaders(),
    params: {
      products: products.join(','),
    },
  });

const createOrgRosterSync = ({
  accessToken,
  districtId,
  grouping,
  products,
  rosterProvider,
  syncSchedule,
  userGroups,
}) =>
  axios.post(
    `${baseUrl}/syncs/organizations/${rootStore.organizationStore.activeOrgId}`,
    {
      districtId,
      grouping: [grouping],
      products,
      rosterProvider,
      syncSchedule,
      userGroups,
    },
    {
      headers: {
        ...getHeaders(),
        'X-Roster-Authorization': accessToken,
      },
    }
  );

const getOrgRosterSyncDetails = () =>
  axios.get(`${baseUrl}/syncs/organizations/${rootStore.organizationStore.activeOrgId}`, {
    headers: getHeaders(),
  });

const putRosterSyncAction = ({syncId, syncAction}) =>
  axios.put(`${baseUrl}/syncs/${syncId}/actions`, {syncAction}, {headers: getHeaders()});

const patchRosterSync = ({syncId, data}) =>
  axios.patch(`${baseUrl}/syncs/${syncId}`, data, {headers: getHeaders()});

const removeRosterSync = ({syncId}) =>
  axios.delete(`${baseUrl}/syncs/${syncId}`, {headers: getHeaders()});

const eduRosterSyncV3 = {
  createOrgRosterSync,
  getAccessToken,
  getOrgRosterSyncDetails,
  getRosterTenants,
  getUserGroups,
  patchRosterSync,
  putRosterSyncAction,
  removeRosterSync,
};

export default eduRosterSyncV3;
export {ERROR_CODES};
