import {dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {View} from '@adobe/react-spectrum';
import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import usePageAnalytics from 'common/hooks/usePageAnalytics';

import OverviewPage from '../overview/OverviewPage';

import AccountPageWrapper from './AccountPageWrapper';

/**
 * The Account overview page
 */
const AccountOverviewPage = () => {
  const overviewTitleKey = 'account.overview.page.title';

  useDocumentTitle({key: overviewTitleKey});
  usePageAnalytics({name: 'account:overview'});

  const location = useLocation();
  const navigate = useNavigate();

  // Detect if we have been redirected from a Gainsight Modal, and fire an analytics event if so.
  useEffect(() => {
    const handleAnalyticsEvent = () => {
      dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: 'contractETLA:goToAccount',
        web: {
          webInteraction: {
            name: 'contractETLA:goToAccount:click',
          },
        },
      });
    };

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('fgm') === 'true') {
      // the parameter is detected (fgm='from-gainsight-modal', see ONESIE-44551), so send the analytics event.
      handleAnalyticsEvent();

      // remove the parameter, its a one-time-use.
      searchParams.delete('fgm');
      const newSearch = searchParams.toString() ? `?${searchParams.toString()}` : '';

      // Update the URL without reloading the page using replace
      navigate(
        {
          pathname: location.pathname,
          search: newSearch,
        },
        {replace: true}
      );
    }
  }, [location, navigate]);

  return (
    <AccountPageWrapper pageTestId="account-overview-page-wrapper">
      <View
        // Add negative margin-top to this View as AccountPageWrapper adds additional margin to this PageContent
        // And because of lack of PageHeader on this page, the content needs to be moved up a bit.
        UNSAFE_style={{
          marginTop:
            'calc(var(--spectrum-global-dimension-size-400, var(--spectrum-alias-size-400)) * -1)',
        }}
      >
        <OverviewPage />
      </View>
    </AccountPageWrapper>
  );
};

export default AccountOverviewPage;
