import {OverlayWait, TableSectionTable} from '@admin-tribe/acsc-ui';
import {
  ActionButton,
  Button,
  DialogContainer,
  Flex,
  Heading,
  Text,
  View,
} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {TableSection} from '@pandora/react-table-section';
import Delete from '@spectrum-icons/workflow/Delete';
import Edit from '@spectrum-icons/workflow/Edit';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import ConfirmRemoveIpModal from 'features/settings/components/auth-settings-page/ip-restriction-section/ConfirmRemoveIpModal';
import {useIpListAccessContext} from 'features/settings/components/auth-settings-page/ip-restriction-section/IpListAccessContext';
import IpRestrictionModal from 'features/settings/components/auth-settings-page/ip-restriction-section/IpRestrictionModal';

const DIALOGS = {
  INPUT_DIALOG: 'inputDialog',
  REMOVE_DIALOG: 'removeDialog',
};

const IpRestrictionSection = () => {
  const [openedDialog, setOpenedDialog] = useState(null);
  const {error, ipList, loadIpAccessList, isLoading} = useIpListAccessContext();
  const editIpValue = useRef('');
  const intl = useIntl();

  const tableData = useMemo(() => ipList.map((ip) => ({id: ip, value: ip})), [ipList]);

  // load ip access list
  useEffect(() => {
    loadIpAccessList();
  }, [loadIpAccessList]);

  const onChangesSaved = () => {
    editIpValue.current = '';
    loadIpAccessList();
  };

  const onDeletePressed = (ipValue) => {
    editIpValue.current = ipValue;
    setOpenedDialog(DIALOGS.REMOVE_DIALOG);
  };

  const onEditIp = (ipValue) => {
    editIpValue.current = ipValue;
    setOpenedDialog(DIALOGS.INPUT_DIALOG);
  };

  const onCancel = () => {
    editIpValue.current = '';
    setOpenedDialog(null);
  };

  const columnDescriptor = [
    {key: 'ipAddress', props: {width: '80%'}},
    {key: 'actions', props: {hideHeader: true, width: '20%'}},
  ];

  const renderers = {
    actions: ({item}) => (
      <Flex direction="row" justifyContent="flex-end" marginEnd="size-125">
        <ActionButton data-testid="edit-button" isQuiet onPress={() => onEditIp(item.value)}>
          <Edit />
        </ActionButton>
        <ActionButton
          data-testid="delete-button"
          isQuiet
          onPress={() => onDeletePressed(item.value)}
        >
          <Delete />
        </ActionButton>
      </Flex>
    ),
    ipAddress: ({item}) => <Text>{item.value}</Text>,
  };

  return (
    <View>
      <Heading level={2}>
        <FormattedMessage id="settings.authSettings.ipRestriction.title" />
      </Heading>
      <Text>
        <FormattedMessage
          id="settings.authSettings.ipRestriction.description"
          values={{
            gourl: (linkText) => (
              <GoUrl hasLanguageSuffix={false} name="acc_loc_based_access">
                {linkText}
              </GoUrl>
            ),
          }}
        />
      </Text>

      <Flex alignItems="center" justifyContent="space-between">
        <Heading level={3}>
          <FormattedMessage id="settings.authSettings.ipRestriction.allowedIpAddresses" />
        </Heading>
        <Button
          isDisabled={isLoading || !!error}
          onPress={() => setOpenedDialog(DIALOGS.INPUT_DIALOG)}
          variant="cta"
        >
          <FormattedMessage id="settings.authSettings.ipRestriction.addIp" />
        </Button>
      </Flex>

      <OverlayWait isLoading={isLoading}>
        <TableSection items={tableData}>
          <TableSectionTable
            aria-label={intl.formatMessage({
              id: 'settings.authSettings.ipRestriction.ipAddressesTable.ariaLabel',
            })}
            columnDescriptor={columnDescriptor}
            columnNamespace="settings.authSettings.ipRestriction.ipAddressesTable"
            noItemsFoundContentMessage={intl.formatMessage({
              id: 'settings.authSettings.ipRestriction.noIpAddresses',
            })}
            renderers={renderers}
          />
        </TableSection>
      </OverlayWait>

      <DialogContainer onDismiss={onCancel}>
        {openedDialog === DIALOGS.INPUT_DIALOG && (
          <IpRestrictionModal
            defaultIpValue={editIpValue.current}
            onSave={() => onChangesSaved()}
          />
        )}

        {openedDialog === DIALOGS.REMOVE_DIALOG && (
          <ConfirmRemoveIpModal
            ipToRemove={editIpValue.current}
            onRemoveConfirmed={() => onChangesSaved()}
          />
        )}
      </DialogContainer>
    </View>
  );
};

export default IpRestrictionSection;
