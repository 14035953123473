/* eslint-disable eslint-comments/disable-enable-pair -- Needed for line below */
/* eslint-disable max-lines -- Will be reduced with FF cleanup and legacy flow deprecation */
import binky, {eventBus, feature, setLocalStorageItem} from '@admin-tribe/acsc';
import {Button, Item, Menu, MenuTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import sophiaHelper from 'common/services/sophia/sophiaHelper';
import trialHelper from 'core/products/trial-helper/trialHelper';
import CancelPlanModalWrapper from 'features/mini-apps/cancel-plan-modal-wrapper/CancelPlanModalWrapper';
import ChangePlanModalWrapper from 'features/mini-apps/change-plan-modal-wrapper/ChangePlanModalWrapper';
import SelfCancelModalWrapper from 'features/mini-apps/self-cancel-modal-wrapper/SelfCancelModalWrapper';
import {CART_EVENT} from 'features/offers/freeOfferCartConstants';

import {isAllowedToAddProducts} from '../../../core/organizations/access/organizationAccess';
import chatProvider from '../../../core/services/chat/chatProvider';
import {CHAT_APP_ID} from '../../../core/services/chat/chatProviderConstants';

import {MANAGE_PLAN_CONSTANTS} from './ManagePlanConstants';
import {
  MANAGE_PLAN_DROPDOWN_OPEN,
  dispatchManagePlanButtonAnalytics,
} from './SelfCancelAnalyticsUtils';
import {MANAGE_PLAN_ACTIONS, OFFER_TYPE} from './SelfCancelConstants';
import SelfCancelModal from './SelfCancelModal';
import {
  areAllProductsNonSwitchable,
  isEligibleForSwitchPlan,
  isEligibleToLaunchJarvisOnChangePlan,
  isLegacyPaidSelfCancelEligible,
  isLegacyPaidSelfCancelEligibleWithBumper,
  isPaidSelfCancelEligible,
  isProductCancellable,
  isTrialSelfCancelEligible,
} from './SelfCancelUtils';

const {ADD_LICENSES, CANCEL_LICENSES, CANCEL_PLAN, CANCEL_TRIAL, CHANGE_PLAN} = MANAGE_PLAN_ACTIONS;

const useShouldShowPaidCancelButton = ({
  contract,
  isHideCancelPlanFeatureEnabled,
  isTrialProduct,
  productList,
  singleProductModeData,
}) => {
  const currentProduct = productList?.items?.find(
    ({productArrangementCode}) =>
      productArrangementCode === singleProductModeData?.item?.offer?.product_arrangement_code
  );

  const shouldShowLegacyPaidCancelButton = useMemo(() => {
    const eligibilityCheck = feature.isEnabled('bumper_self_cancel')
      ? isLegacyPaidSelfCancelEligibleWithBumper
      : isLegacyPaidSelfCancelEligible;

    if (feature.isEnabled('temp_self_cancel_us_paid_expand_14_days')) {
      return (
        feature.isEnabled('temp_force_enable_self_cancel') ||
        (eligibilityCheck(contract, productList) && isProductCancellable(currentProduct))
      );
    }

    return (
      feature.isEnabled('temp_force_enable_self_cancel') || eligibilityCheck(contract, productList)
    );
  }, [contract, currentProduct, productList]);

  const shouldShowPaidCancelButton = useMemo(
    () =>
      feature.isEnabled('temp_force_enable_self_cancel') ||
      (isPaidSelfCancelEligible(contract, productList) && isProductCancellable(currentProduct)),
    [contract, currentProduct, productList]
  );

  return (
    !isHideCancelPlanFeatureEnabled &&
    feature.isEnabled('temp_self_cancel') &&
    !isTrialProduct &&
    (feature.isEnabled('temp_self_cancel_cme_11879') ||
    feature.isEnabled('temp_self_cancel_cme_11879_dec_ab_test')
      ? shouldShowPaidCancelButton
      : shouldShowLegacyPaidCancelButton)
  );
};

/**
 * @description Returns an object with booleans indicating which manage plan items should be shown
 *
 * @param contract The contract to use for eligibility checks
 * @param productList The product list to use for eligibility checks
 * @param singleProductModeData The single product mode data to use for eligibility checks
 */

const useShouldShowManagePlanItems = ({
  contract,
  productList,
  singleProductModeData,
  isHideCancelPlanFeatureEnabled,
}) => {
  const currentProduct = productList?.items?.find(
    ({productArrangementCode}) =>
      productArrangementCode === singleProductModeData?.item?.offer?.product_arrangement_code
  );

  // Get the trial status for the product
  const {activeTrial} = trialHelper.getTrialWithPaymentInfo(singleProductModeData?.item);

  const isTrialProduct = singleProductModeData?.offerType === OFFER_TYPE.TRIAL && activeTrial;
  const isTrialWithPaymentEnabled = useMemo(() => feature.isEnabled('trial_with_payment'), []);

  const shouldShowPaidCancelButton = useShouldShowPaidCancelButton({
    contract,
    isHideCancelPlanFeatureEnabled,
    isTrialProduct,
    productList,
    singleProductModeData,
  });

  const shouldShowTrialCancelButton = useMemo(
    () =>
      feature.isEnabled('temp_force_enable_self_cancel') ||
      isTrialSelfCancelEligible(contract, productList),
    [contract, productList]
  );

  // Removing daysLeft condition as we just rely only on can_message_upcoming_trial_expiration for active trial.
  // This is to avoid the confusion of active trial status when trial-to-paid conversion job is delayed -- E2E-27630.
  const showCancelTrial =
    shouldShowTrialCancelButton && isTrialWithPaymentEnabled && isTrialProduct;

  const shouldShowChangePlan = useMemo(() => {
    if (feature.isEnabled('temp_show_change_plan') && contract.canSwitch()) {
      // check for non switchable products
      if (feature.isEnabled('temp_check_non_switchable_products')) {
        return !areAllProductsNonSwitchable(productList) && isEligibleForSwitchPlan(currentProduct);
      }
      return isEligibleForSwitchPlan(currentProduct);
    }
    return false;
  }, [contract, currentProduct, productList]);

  return {
    showAddLicenses: !feature.isEnabled('temp_show_manage_plan') || isAllowedToAddProducts(),
    showCancelLicense: shouldShowPaidCancelButton,
    showCancelPlan:
      isTrialWithPaymentEnabled && feature.isEnabled('trial_with_payment_cancel_plan'),
    showCancelTrial,
    showChangePlan: shouldShowChangePlan,
  };
};

const useHandlePaidCancelModal = ({
  setIsCancelPlanMiniAppOpen,
  setIsModalOpen,
  singleProductModeData,
}) => {
  const intl = useIntl();

  const openChat = () => {
    chatProvider.openMessagingWindow({
      appId: CHAT_APP_ID.ONESIE_SELF_CANCEL_DEEPLINK,
      sourceText: intl.formatMessage({id: 'account.managePlan.cancelLicenses'}),
      sourceType: 'button',
    });
  };

  return () => {
    if (feature.isEnabled('temp_redirect_self_cancel_to_jarvis_cme_11879')) {
      openChat();
    } else if (
      feature.isEnabled('temp_self_cancel_cme_11879') ||
      feature.isEnabled('temp_self_cancel_cme_11879_dec_ab_test')
    ) {
      setIsCancelPlanMiniAppOpen(true);
    } else if (feature.isEnabled('temp_redirect_self_cancel_to_jarvis')) {
      if (chatProvider.isConversationOpen()) {
        setIsModalOpen(true);
      } else {
        // Set the product id in session storage so that we can use it in the self cancel modal
        if (singleProductModeData) {
          setLocalStorageItem(
            MANAGE_PLAN_CONSTANTS.PAID_CANCEL_PRODUCT_DETAILS,
            JSON.stringify(singleProductModeData)
          );
        }
        openChat();
      }
    } else {
      setIsModalOpen(true);
    }
  };
};

/**
 * Component responsible to show a Manage licenses popover menu, with options to Add Products and
 * Cancel licenses. If Cancel licenses is triggered, it will open SelfCancelModal.
 * It will also manage focus when closing modal or closing menu via keyboard.
 */
// eslint-disable-next-line complexity -- will be reduced once we clean up feature flags
const ManagePlan = ({
  contract,
  onCancel,
  onConfirm,
  onOpenAddProducts,
  onOpenManageUsers,
  onStartChat,
  productList,
  singleProductModeData,
}) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelPlanMiniAppOpen, setIsCancelPlanMiniAppOpen] = useState(false);
  const [isChangePlanMiniAppOpen, setIsChangePlanMiniAppOpen] = useState(false);
  const [isSelfCancelMiniAppOpen, setIsSelfCancelMiniAppOpen] = useState(false);

  const [isHideCancelPlanFeatureEnabled, setIsHideCancelPlanFeatureEnabled] = useState(false);

  const buttonRef = useRef(null);
  const handlePaidCancelModal = useHandlePaidCancelModal({
    setIsCancelPlanMiniAppOpen,
    setIsModalOpen,
    singleProductModeData,
  });

  const isTrialWithPaymentEnabled = useMemo(() => feature.isEnabled('trial_with_payment'), []);
  const isFrameIoManagePlanHidden = useMemo(
    () => feature.isEnabled('temp_hide_manage_plan_for_frameio'),
    []
  );

  // When the the modal closes, unmount SelfCancelModal and focus back on button
  const onModalClosed = () => {
    setIsModalOpen(false);
    buttonRef.current.focus();
  };

  // When any of the mini app to manage plan closes, unmount mini app and focus back on button
  const onMiniAppClosed = (closeMiniApp) => {
    // Firing event to trigger AcquisitionSummaryList refresh when temp_refresh_acquisition_summary flag is enabled
    if (feature.isEnabled('temp_refresh_acquisition_summary')) {
      eventBus.emit(CART_EVENT.SUBMIT, this);
    }
    closeMiniApp();
    buttonRef.current.focus();
  };

  const onChangeOrCancelPlanMiniAppClosed = (closeMiniApp) => {
    closeMiniApp();
    buttonRef.current.focus();
  };

  // Handler that is called when a Manage Plan menu item is selected
  const onMenuAction = (key) => {
    switch (key) {
      case ADD_LICENSES:
        dispatchManagePlanButtonAnalytics({action: key});
        onOpenAddProducts();
        break;
      case CANCEL_LICENSES:
        dispatchManagePlanButtonAnalytics({
          action: key,
          product: singleProductModeData,
        });
        handlePaidCancelModal();
        break;
      case CANCEL_TRIAL:
        dispatchManagePlanButtonAnalytics({action: key, product: singleProductModeData});
        setIsSelfCancelMiniAppOpen(true);
        break;
      case CHANGE_PLAN:
        dispatchManagePlanButtonAnalytics({action: key, product: singleProductModeData});
        if (
          feature.isEnabled('temp_redirect_change_plan_to_jarvis') &&
          isEligibleToLaunchJarvisOnChangePlan(productList)
        ) {
          chatProvider.openMessagingWindow({
            appId: CHAT_APP_ID.ONESIE1_CHANGE_PLAN,
            sourceText: intl.formatMessage({id: 'account.managePlan.changePlan'}),
            sourceType: 'button',
          });
        } else setIsChangePlanMiniAppOpen(true);
        break;
      default:
        break;
    }
  };

  /**
   * Checks for Sophia campaign and enables the hide cancel plan feature if applicable.
   */
  const checkForSophiaCampaign = async () => {
    const sophiaContentList = await sophiaHelper.getSophiaContent({
      surfaceId: 'pandora_add_product_mini_app',
    });
    // Get the content for the feature flags container
    const sophiaContent = sophiaContentList.getContentByContainerLabel('feature_flags');

    if (sophiaContent) {
      const data = sophiaContent.getContent();
      const sophiaFeatureFlags = data?.split(',')?.map((_item) => _item?.trim());
      if (sophiaFeatureFlags?.includes('temp_hide_cancel_plan_sophia')) {
        setIsHideCancelPlanFeatureEnabled(true);
      }
    }
  };

  // Handler that is called when the drop down state changes so analytics can be sent
  const onMenuChange = async (isOpenState) => {
    if (isOpenState) {
      dispatchManagePlanButtonAnalytics({
        action: MANAGE_PLAN_DROPDOWN_OPEN,
      });
    }

    if (feature.isEnabled('temp_hide_cancel_plan_split')) {
      await checkForSophiaCampaign();
    }
    setIsOpen(isOpenState);
  };

  const {showAddLicenses, showCancelLicense, showCancelPlan, showCancelTrial, showChangePlan} =
    useShouldShowManagePlanItems({
      contract,
      isHideCancelPlanFeatureEnabled,
      productList,
      singleProductModeData,
    });

  // Disable manage plan button if no options are available
  const shouldDisableManagePlan =
    !showAddLicenses &&
    !showCancelLicense &&
    !showCancelPlan &&
    !showCancelTrial &&
    !showChangePlan;

  // If other products also require hiding manage plan, we should move this to a specialized function
  const isFrameIoOffer = singleProductModeData?.item?.offer?.product_arrangement_code === 'PA-1478';
  const hideManagePlan = isFrameIoOffer && isFrameIoManagePlanHidden;

  return (
    <>
      {!hideManagePlan && (
        <MenuTrigger isOpen={isOpen} onOpenChange={onMenuChange}>
          <Button
            ref={buttonRef}
            data-testid="manage-plan-trigger"
            isDisabled={shouldDisableManagePlan}
            marginBottom="size-100"
            variant="secondary"
          >
            {feature.isEnabled('temp_show_account_page_product_change_plan')
              ? intl.formatMessage({id: 'account.managePlan.buttonLabelManage'})
              : intl.formatMessage({id: 'account.managePlan.buttonLabel'})}
          </Button>
          <Menu data-testid="manage-plan-menu" onAction={onMenuAction}>
            {showAddLicenses && (
              <Item key={ADD_LICENSES}>
                {isTrialWithPaymentEnabled
                  ? intl.formatMessage({id: 'account.managePlan.addLicenses'})
                  : intl.formatMessage({id: 'account.managePlan.addProducts'})}
              </Item>
            )}
            {showChangePlan && (
              <Item key={CHANGE_PLAN}>
                {intl.formatMessage({id: 'account.managePlan.changePlan'})}
              </Item>
            )}
            {showCancelPlan && (
              <Item key={CANCEL_PLAN}>
                {intl.formatMessage({id: 'account.managePlan.cancelPlan'})}
              </Item>
            )}
            {showCancelLicense && (
              <Item key={CANCEL_LICENSES}>
                {intl.formatMessage({id: 'account.managePlan.cancelLicenses'})}
              </Item>
            )}
            {showCancelTrial && (
              <Item key={CANCEL_TRIAL}>
                {intl.formatMessage({id: 'account.managePlan.cancelLicenses'})}
              </Item>
            )}
          </Menu>
        </MenuTrigger>
      )}

      {/* Direct handler for deep link self cancel modal */}
      {isModalOpen && (
        <SelfCancelModal
          contract={contract}
          isOpen
          onCancel={onCancel}
          onClosed={onModalClosed}
          onConfirm={onConfirm}
          onOpenAddProducts={onOpenAddProducts}
          onOpenManageUsers={onOpenManageUsers}
          onStartChat={onStartChat}
          productList={productList}
          singleProductModeData={singleProductModeData}
        />
      )}

      {/* Cancel Plan Mini App */}
      {isCancelPlanMiniAppOpen && (
        <CancelPlanModalWrapper
          contractId={contract.id}
          onClose={() => onChangeOrCancelPlanMiniAppClosed(() => setIsCancelPlanMiniAppOpen(false))}
          productId={singleProductModeData?.productId}
        />
      )}

      {/* Change Plan Mini App */}
      {isChangePlanMiniAppOpen && (
        <ChangePlanModalWrapper
          contractId={contract.id}
          onClose={() => onChangeOrCancelPlanMiniAppClosed(() => setIsChangePlanMiniAppOpen(false))}
          productId={singleProductModeData?.productId}
        />
      )}

      {/* Self Cancel Mini App */}
      {isSelfCancelMiniAppOpen && (
        <SelfCancelModalWrapper
          contractId={contract.id}
          onClose={() => onMiniAppClosed(() => setIsSelfCancelMiniAppOpen(false))}
          productId={singleProductModeData?.productId}
        />
      )}
    </>
  );
};

ManagePlan.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(binky.models.contract.Contract).isRequired,
  /**
   * Handler that is called when the user cancels out from the modal.
   */
  onCancel: PropTypes.func,
  /**
   * Handler that is called when the user confirmed the cancellation of licenses.
   */
  onConfirm: PropTypes.func,
  /**
   * Handler that is called when the user wants to open Add Products modal.
   */
  onOpenAddProducts: PropTypes.func.isRequired,
  /**
   * Handler that is called when the user confirmed a cancellation or a save offer and clicks on
   * Manage Users, from the confirmation step.
   */
  onOpenManageUsers: PropTypes.func,
  /**
   * Handler that is called when the user wants to start a chat session.
   * Will also send up an object specifying which step a user is initiating a chat session from.
   * i.e. {step: 'BumperStep'}
   */
  onStartChat: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(binky.services.product.ProductList).isRequired,
  /**
   * Optional: When ManagePlan is shown for individual products.
   * Usually, the actions performed via ManagePlan menu in this case will only be done
   * from a specific product perspective.
   */
  singleProductModeData: PropTypes.shape({
    item: PropTypes.shape({
      offer: PropTypes.shape({
        product_arrangement_code: PropTypes.string,
      }),
    }),
    offerId: PropTypes.string,
    offerType: PropTypes.string,
    productCode: PropTypes.string,
    productId: PropTypes.string,
  }),
};

export default ManagePlan;
