import {useCallback, useState} from 'react';

import useIpAccessList from 'features/settings/hooks/api/useIpAccessList';

/**
 * This hook is used to manage the IP access list state.
 *
 * @param {boolean} loadOnStart This flag is used to determine if the loading state should be set to true on first render.
 *
 * @returns {Object} An object containing the IP access list state and actions.
 */
const useIpAccessListState = (loadOnStart = true) => {
  const [ipList, setIpList] = useState([]);
  const {getIpAccessList, saveIpAccessList} = useIpAccessList();
  const [isLoading, setIsLoading] = useState(loadOnStart);
  const [error, setError] = useState(null);

  const loadIpAccessList = useCallback(async () => {
    try {
      const data = await getIpAccessList();
      setIpList(data.ipAccessList);
    } catch (error_) {
      setError(error_);
    } finally {
      setIsLoading(false);
    }
  }, [getIpAccessList]);

  const save = useCallback((ipAccessList) => saveIpAccessList(ipAccessList), [saveIpAccessList]);

  const replaceIp = useCallback(
    (oldIp, newIp) => ipList.map((ip) => (ip === oldIp ? newIp : ip)),
    [ipList]
  );
  const removeIp = useCallback((ipToRemove) => ipList.filter((ip) => ip !== ipToRemove), [ipList]);
  const addIp = useCallback((ipToAdd) => [...ipList, ...ipToAdd], [ipList]);

  return {
    addIp,
    error,
    ipList,
    isLoading,
    loadIpAccessList,
    removeIp,
    replaceIp,
    save,
    setIpList,
    setIsLoading,
  };
};

export default useIpAccessListState;
