import {
  MEMBER_TECH_ACCOUNT_DOMAIN,
  OrganizationUserList,
  feature,
  modelCache,
} from '@admin-tribe/acsc';

import ApiIntegrationList from 'core/api-integration/ApiIntegrationList';
import ApiIntegrationUser from 'core/api-integration/ApiIntegrationUser';
import IsvBindingList from 'core/api-integration/IsvBindingList';

/**
 * The list of Api integration/credentials for an organization.
 * It is composed of organization users with emails in the technical account domain
 * joined with the details of each integration.
 */
class OrganizationIntegrationList extends OrganizationUserList {
  /**
   * @description Constructor for OrganizationIntegrationList model .
   * @param {Object} options - options passed down to OrganizationUserList and MemberList.
   * @param {String} options.orgId - Org ID
   */
  constructor(options) {
    super({
      filterExcludeDomain: undefined,
      filterIncludeDomain: MEMBER_TECH_ACCOUNT_DOMAIN,
      itemClassRef: ApiIntegrationUser,
      modelCacheId: 'OrganizationIntegrationList',
      ...options,
    });
    this.pageNumber = options.pageNumber;
    this.pageSize = options.pageSize;
    this.isvBindingsPageNumber = options.isvBindingsPageNumber;
    // Because this list does a join after the refresh API call completes and the response is
    // cached, we have to manually update the cached entry.
    this.key = this.getModelKey(options);
  }

  async getIsvBindings(apiIntegrationUserItemsLength, orgIntegrations) {
    // Check if we need to fetch ISV bindings
    // 1. If we don't have enough API integration users to fill the page
    // 2. Or if we're explicitly requesting ISV bindings via isvBindingsPageNumber
    let isvBindingsList;
    if (this.pageSize > apiIntegrationUserItemsLength || this.isvBindingsPageNumber > 0) {
      // Calculate how many ISV bindings we need to fetch
      const remainingItemsNeeded = this.pageSize - apiIntegrationUserItemsLength;

      // Only fetch if we need to get ISV bindings (page >= 0)
      if (this.isvBindingsPageNumber >= 0) {
        isvBindingsList = await IsvBindingList.get({
          adobeioOrgId: orgIntegrations.adobeioOrgId,
          page: this.isvBindingsPageNumber,
          rengaOrgId: this.orgId,
          size: remainingItemsNeeded > 0 ? remainingItemsNeeded : this.pageSize,
        });

        // Add ISV bindings to the items list
        this.items = [...this.items, ...isvBindingsList.items];
        this.totalIsvBindings = isvBindingsList.totalIsvBindings || 0;
      }
    } else if (this.pageNumber === 1) {
      // Fetch ISV bindings for the first page so that we know that there are ISV bindings to show later
      isvBindingsList = await IsvBindingList.get({
        adobeioOrgId: orgIntegrations.adobeioOrgId,
        page: 0,
        rengaOrgId: this.orgId,
        size: this.pageSize,
      });
      if (isvBindingsList?.items?.length > 0) {
        this.totalIsvBindings = isvBindingsList.totalIsvBindings;
      }
    }
    return isvBindingsList;
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      filterExcludeDomain: undefined,
      filterIncludeDomain: MEMBER_TECH_ACCOUNT_DOMAIN,
    };
  }

  /**
   * @description Method to execute after a successful refresh.
   *   Adds the intergration details to each organinization integration.
   * @returns {Promise<undefined>} resolves with promise when integration details
   *   have been added
   */
  async onRefreshSuccess() {
    // The cache entry for this key currently only contains the refresh reponse so remove it
    // since it is incomplete.  If the join is successful the cache entry will be updated.
    modelCache.remove(this.modelCacheId, this.key);

    const techAccountIds = this.items.map((item) => item.id);
    const orgIntegrations = await ApiIntegrationList.get({
      rengaOrgId: this.orgId,
      techAccountIdsToSearch: techAccountIds,
    });
    if (feature.isEnabled('temp_pa8170_dev_acc')) {
      // Store the count of API integration users before we add ISV bindings
      const apiIntegrationUserItemsLength = this.items.length;

      // Fetch the ISV bindings if we need more items to fill the page
      this.totalIsvBindings = 0;
      await this.getIsvBindings(apiIntegrationUserItemsLength, orgIntegrations);
    }

    // To each user, add the org id needed in case of refresh,
    // and their integration if one is found.
    this.items.forEach((item) => {
      item.orgId = this.orgId;
      if (item instanceof ApiIntegrationUser) {
        item.addIntegration(orgIntegrations.items);
      }
    });

    // Now that the join is complete, use the saved key, to update value cached in
    // ModelList's `onQueryResponse`.
    modelCache.put(this.modelCacheId, this.key, Promise.resolve(this));
  }

  // Override since filter.query is always set to MEMBER_TECH_ACCOUNT_DOMAIN
  shouldUpdateTotalItemCount() {
    return this.pagination?.itemCount >= 0;
  }
}

export default OrganizationIntegrationList;
