// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {Button, DialogContainer, Flex, Heading, Image, Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import RosterSyncIcon from 'features/settings/assets/group_meet_roster_sync.svg';

import RosterSyncSteppedModal from './RosterSyncSteppedModal';

const SetupRosterSync = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex alignItems="center" justifyContent="center" marginTop="size-600">
      <Flex alignItems="center" direction="column">
        <Image
          alt={intl.formatMessage({id: 'settings.rosterSync.setup.icon.alt'})}
          src={RosterSyncIcon}
        />
        <Heading level={2} marginTop="size-0">
          {intl.formatMessage({id: 'settings.rosterSync.setup.title'})}
        </Heading>
        <Text>
          {intl.formatMessage({
            id: 'settings.rosterSync.setup.description',
          })}
        </Text>
        <Text>
          <GoUrl name="aac_roster_sync">
            {intl.formatMessage({
              id: 'settings.rosterSync.setup.learnMore',
            })}
          </GoUrl>
        </Text>
        <Button marginTop="size-200" onPress={() => setIsOpen(true)} variant="cta">
          {intl.formatMessage({id: 'settings.rosterSync.setup.button'})}
        </Button>
      </Flex>

      <DialogContainer onDismiss={() => setIsOpen(false)}>
        {isOpen && <RosterSyncSteppedModal onClose={() => setIsOpen(false)} />}
      </DialogContainer>
    </Flex>
  );
};

export default SetupRosterSync;
