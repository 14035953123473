/* eslint-disable eslint-comments/disable-enable-pair -- Needed for line below */
/* eslint-disable max-lines -- this file requires more lines */
import {
  Locale,
  authentication,
  dispatchPageAnalytics,
  dispatchUiEventAnalytics,
  eventBus,
  feature,
  getAdminConsoleDisplayableContracts,
} from '@admin-tribe/acsc';
import {PageBreadcrumbs} from '@admin-tribe/acsc-ui';
import {Flex, Grid, Item, View} from '@adobe/react-spectrum';
import {FetchProvider} from '@pandora/react-fetch-provider';
import qs from 'qs';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';

import BuyMoreButton from 'common/components/buy-more-button/BuyMoreButton';
import EditBillingModal from 'common/components/edit-billing-modal/EditBillingModal';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import usePageAnalytics from 'common/hooks/usePageAnalytics';
import rootStore from 'core/RootStore';
import {
  canAccessAccountPage,
  canManagePlan,
  canViewAccountOverviewPage,
  canViewChangePlanModalFromDeepLink,
  canViewEditBillingModalFromDeepLink,
  canViewSelfCancelModalFromDeepLink,
} from 'core/account/access/accountAccess';
import {isContractEligibleForContractAdmin} from 'core/contract/access/contractAccess';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import {canViewAddProductMiniAppDeepLink} from 'core/products/access/productAccess';
import chatProvider from 'core/services/chat/chatProvider';
import ContractTabs from 'features/account/contract-tabs/ContractTabs';
import {filterContractList} from 'features/account/overview/overviewUtils';
import {
  goToAccountOverview,
  goToAccountRoot,
} from 'features/account/routing/navigation-callbacks/navigationCallbacks';
import ChangePlanModalWrapper from 'features/mini-apps/change-plan-modal-wrapper/ChangePlanModalWrapper';
import {CART_EVENT} from 'features/offers/freeOfferCartConstants';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import ContractRenewalWrapper from 'features/products/components/contract-renewal-wrapper/ContractRenewalWrapper';
import {goToUsers} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import AccountBumper from '../account-bumper/AccountBumper';
import SummaryItemsTableSection from '../acquisitions/table/SummaryItemsTableSection';
import AdditionalProducts from '../additional-products/AdditionalProducts';
import ContractBanner from '../contract-banner/ContractBanner';
import ContractInfoMiniAppWrapper from '../contract-info/ContractInfoMiniAppWrapper';
import PaymentInfoMiniAppWrapper from '../payment-info/PaymentInfoMiniAppWrapper';
import SelfCancelModal from '../self-cancel/SelfCancelModal';
import StoragePlans from '../storage-plans/StoragePlans';
import {
  getAccountPageBumperDetails,
  getContractForAccountPage,
  getNonDefaultEnterpriseContractMessageKey,
  getProductForAccountPage,
  startChat,
} from '../utils/accountUtils';

import {
  ACCOUNT_OVERVIEW_ITEM,
  CONTRACT_DETAILS_ITEM,
  WAIT_UNTIL_ACCOUNT_PAGE_FINISHES_REFRESHING,
} from './AccountPageConstants';
import AccountPageWrapper from './AccountPageWrapper';
import ContractInfoBar from './components/ContractInfoBar';

/**
 * The Account page
 */
// eslint-disable-next-line max-statements -- Will be reduced when flags removed
const AccountPage = () => {
  const intl = useIntl();
  const [isLoading] = useState(false);
  useDocumentTitle({key: 'account.overview.contractDetails.title'});
  usePageAnalytics({name: 'account'});

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [showEditBillingModal, setShowEditBillingModal] = useState(false);
  const [showSelfCancelModal, setShowSelfCancelModal] = useState(false);

  const {pathname} = useLocation();
  const {contractId: contractIdFromUrl, productIdentifier: productIdentifierFromUrl} = useParams();
  const [searchParams] = useSearchParams();
  const queryParams = qs.parse(searchParams.toString());
  const addProductsParamsFromUrl = {
    step: queryParams.step,
    workflow: queryParams.wf,
  };

  /**
   * Support deep linking to Mini-Apps
   */
  useEffect(() => {
    if (isAllowedToAddProducts() && canViewAddProductMiniAppDeepLink(pathname)) {
      setShowAddProductModal(true);
    } else if (canViewChangePlanModalFromDeepLink(pathname)) {
      // Currently, the temp_account_page_force_refresh flag forces a refresh
      // every time a user navigates to the Account Page. This is a workaround
      // to resolve ECOMM-137720. However, this causes the Change Plan modal to
      // refresh multiple times before opening which results in some flickering.
      // To avoid this, we are delaying the opening of the Change Plan modal
      // until the Account Page finishes refreshing. ECOMM-145973 tracks the
      // resolution of this forced refresh issue.
      setTimeout(() => {
        setShowChangePlanModal(true);
      }, WAIT_UNTIL_ACCOUNT_PAGE_FINISHES_REFRESHING);
    } else if (canViewSelfCancelModalFromDeepLink(pathname)) {
      setShowSelfCancelModal(true);
    } else if (canViewEditBillingModalFromDeepLink(pathname)) {
      setShowEditBillingModal(true);
    }
  }, [pathname]);

  /**
   * Add Analytics
   *  - managePlanButton:display
   */
  useEffect(() => {
    if (canManagePlan()) {
      dispatchUiEventAnalytics({
        eventAction: 'display',
        eventName: 'managePlanButton',
      });
    }
  }, []);

  const contentGap = 'size-300'; // 24px
  const contentGapBigger = 'size-500'; // 40px

  const primaryContract = getContractForAccountPage(contractIdFromUrl);
  const contractId = primaryContract.id;
  const isprimaryContractVIPMP = primaryContract.isVIPMPContract();
  const isPrimaryContractETLA = primaryContract.isBuyingProgramETLA();

  const product = getProductForAccountPage(productIdentifierFromUrl);
  // Product ID can be productId or productArrangementCode from the URL
  const productId =
    productIdentifierFromUrl === null || productIdentifierFromUrl === undefined
      ? product?.id
      : productIdentifierFromUrl;

  const getAccountPageHeading = () =>
    canViewAccountOverviewPage()
      ? primaryContract.getDisplayName()
      : intl.formatMessage({id: 'account.overview.page.title'});

  // If the user doesn't go directly to Account page, the title should clearly
  // show which contract it is.
  const accountPageHeading = getAccountPageHeading();

  const isAllowedToAccessAccountPage = canAccessAccountPage();
  const secondContractMessageKey = feature.isEnabled('temp_multi_etla_pa_2881')
    ? undefined
    : getNonDefaultEnterpriseContractMessageKey();

  const showTeamContainer = () =>
    isAllowedToAccessAccountPage && feature.isEnabled('temp_multi_etla_pa_2881')
      ? !primaryContract.isEnterpriseContract() || isPrimaryContractETLA
      : !primaryContract.isEnterpriseContract();

  const showRenewalDetailSection = () =>
    primaryContract.isIndirectButNotVIPMPContract() || primaryContract.isRenewableDirectContract();

  const openAddProductsModal = () => {
    setShowAddProductModal(true);
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'addProducts',
    });
  };

  const onAddProductClose = () => {
    setShowAddProductModal(false);
  };

  const onChangePlanClose = () => {
    setShowChangePlanModal(false);
    goToAccountRoot();
  };

  const openEditPaymentInfoModal = () => {
    setShowEditBillingModal(true);
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'managePayment',
    });
  };

  const closeEditPaymentInfoModal = () => {
    setShowEditBillingModal(false);
  };

  const onCloseSelfCancelModal = () => {
    setShowSelfCancelModal(false);
  };

  const onCancelLicensesExit = () => {
    dispatchPageAnalytics({
      name: 'account',
    });
    onCloseSelfCancelModal();
    goToAccountRoot();
  };

  const onCancelLicensesConfirm = () => {
    // Firing event to trigger a refresh for Contracts and Products
    eventBus.emit(CART_EVENT.SUBMIT, this);
    onCloseSelfCancelModal();
    goToAccountRoot();
  };

  // Create managePlanProps obj only if canManagePlan() is true
  const managePlanProps = canManagePlan()
    ? {
        contract: primaryContract,
        onCancel: onCancelLicensesExit,
        onConfirm: onCancelLicensesConfirm,
        onOpenAddProducts: openAddProductsModal,
        onOpenManageUsers: goToUsers,
        onStartChat: startChat,
        productList: rootStore.organizationStore.productList,
      }
    : null;

  let headerActionElement;
  if (primaryContract.canAddProducts()) {
    headerActionElement = <BuyMoreButton eventName="AccountAddProducts" />;
  }

  // If the user is not allowed to access account page, show bumper
  if (!isAllowedToAccessAccountPage) {
    return (
      <AccountPageWrapper pageTestId="account-page">
        <View data-testid="account-page-bumper-wrapper">
          <AccountPageBumperWrapper />
        </View>
      </AccountPageWrapper>
    );
  }

  const onBreadcrumbPress = (key) => {
    switch (key) {
      case ACCOUNT_OVERVIEW_ITEM:
        goToAccountOverview();
        break;
      default:
      // Do nothing if they click the current breadcrumb
    }
  };

  const getAccountPageBreadcrumbs = () => {
    const contractListLength = feature.isEnabled('temp_multi_etla_pa_2881')
      ? filterContractList(rootStore.organizationStore.contractListIncludingInactive).length
      : getAdminConsoleDisplayableContracts(
          rootStore.organizationStore.contractListIncludingInactive
        ).length;

    if (contractListLength > 1) {
      return (
        <PageBreadcrumbs isDisabled={false} onAction={onBreadcrumbPress}>
          <Item key={ACCOUNT_OVERVIEW_ITEM}>
            {intl.formatMessage({id: 'account.overview.page.title'})}
          </Item>
          <Item key={CONTRACT_DETAILS_ITEM}>{accountPageHeading}</Item>
        </PageBreadcrumbs>
      );
    }
    return null;
  };

  const locale = Locale.get().activeLocaleCode;
  const JIL_API_REGEXP = /^https:\/\/bps[\w-]+\.adobe\.io\/jil-api\//;

  const customFetch = (path, options) => {
    const customHeaders = {};

    // This check against the JIL endpoint is needed since at least
    // one other endpoint (MILO) does not currently allow "Accept-Language" past its whitelist,
    // and JIL does not work with the latest browser "Accept-Language" locale-strings (like es-419)
    if (JIL_API_REGEXP.test(path)) {
      customHeaders['Accept-Language'] = locale;
    }

    if (feature.isDisabled('bug_fix_odin_x_api_key')) {
      customHeaders['X-Api-Key'] = authentication.getClientId();
    } else if (!path.includes('https://odin.adobe.com')) {
      // Intentionally exclude X-Api-Key header for Odin since AEM doesn't support it
      customHeaders['X-Api-Key'] = authentication.getClientId();
    }

    const mergedOptions = {
      ...options,
      headers: {
        ...customHeaders,
        ...options.headers,
      },
    };

    return fetch(path, mergedOptions);
  };

  // eslint-disable-next-line complexity -- needed for conditional rendering
  const renderContractDetails = () => (
    <>
      {showTeamContainer() &&
        // Display only one pod for VIPMP and ETLA contracts
        (isprimaryContractVIPMP ||
        (feature.isEnabled('temp_multi_etla_pa_2881') && isPrimaryContractETLA) ? (
          <ContractInfoMiniAppWrapper contractId={contractId} />
        ) : (
          <Grid
            columns={['minmax(0, 1fr)', 'minmax(0, 1fr)']}
            data-testid="account-contract-payment-info-container"
            gap={contentGapBigger}
          >
            <ContractInfoMiniAppWrapper contractId={contractId} />
            <PaymentInfoMiniAppWrapper
              contractId={contractId}
              openEditPaymentInfoModal={openEditPaymentInfoModal}
            />
          </Grid>
        ))}
      {showRenewalDetailSection() && <ContractRenewalWrapper />}
      {showTeamContainer() && (
        <>
          <SummaryItemsTableSection contract={primaryContract} managePlanProps={managePlanProps} />
          {/* Don't show additional products and storage plans for ETLA contracts */}
          {!(feature.isEnabled('temp_multi_etla_pa_2881') && isPrimaryContractETLA) && (
            <>
              <AdditionalProducts contractId={contractId} />
              <StoragePlans contractId={contractId} />
            </>
          )}
        </>
      )}
      {showAddProductModal && (
        <AddProductModalWrapper
          chat={chatProvider}
          onClose={onAddProductClose}
          {...addProductsParamsFromUrl}
        />
      )}
      {showChangePlanModal && productId && (
        <ChangePlanModalWrapper
          contractId={contractId}
          onClose={onChangePlanClose}
          productId={productId}
        />
      )}
      {showSelfCancelModal && (
        <SelfCancelModal {...managePlanProps} isOpen onClosed={onCloseSelfCancelModal} />
      )}
      <EditBillingModal
        contractId={contractId}
        isOpen={showEditBillingModal}
        onCancel={closeEditPaymentInfoModal}
        onSubmit={closeEditPaymentInfoModal}
      />
    </>
  );

  return (
    <AccountPageWrapper
      breadcrumbs={getAccountPageBreadcrumbs()}
      headerActionElement={headerActionElement}
      pageTestId="account-page"
      title={accountPageHeading}
    >
      <FetchProvider value={customFetch}>
        {!isLoading && (
          <Flex data-testid="account-content-main" direction="column" rowGap={contentGap}>
            {secondContractMessageKey && (
              <View
                class="second-contract-message"
                data-testid="second-contract-message"
                marginTop="size-200"
              >
                {intl.formatMessage({id: secondContractMessageKey})}
              </View>
            )}
            <ContractBanner contractId={contractId} />
            <ContractInfoBar contract={primaryContract} />
            {isContractEligibleForContractAdmin(primaryContract) ? (
              <ContractTabs renderContractDetails={renderContractDetails} />
            ) : (
              renderContractDetails()
            )}
          </Flex>
        )}
      </FetchProvider>
    </AccountPageWrapper>
  );
};

export const AccountPageBumperWrapper = () => {
  const intl = useIntl();
  const {bumperMessageHeader, bumperMessageBody, showBuyNowButton} = getAccountPageBumperDetails();

  return (
    <AccountBumper
      bumperMessageBody={
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition
        /* istanbul ignore next  */
        bumperMessageBody ? intl.formatMessage({id: bumperMessageBody}) : ''
      }
      bumperMessageHeader={intl.formatMessage({id: bumperMessageHeader})}
      showBuyNowButton={showBuyNowButton}
    />
  );
};

export default AccountPage;
