import {showError} from '@admin-tribe/acsc-ui';
import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useIpListAccessContext} from 'features/settings/components/auth-settings-page/ip-restriction-section/IpListAccessContext';

const ConfirmRemoveIpModal = ({ipToRemove, onRemoveConfirmed}) => {
  const dialog = useDialogContainer();
  const {loadIpAccessList, removeIp, save} = useIpListAccessContext();
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const onRemove = async () => {
    setIsLoading(true);

    const newIpList = removeIp(ipToRemove);

    try {
      await save(newIpList);
      loadIpAccessList();

      dialog.dismiss();
      onRemoveConfirmed();
    } catch (error) {
      showError(
        intl.formatMessage({
          id: 'settings.authSettings.ipRestriction.confirmRemoveIpModal.errorToastMessage',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'common.modal.buttons.cancel',
      })}
      isLoading={isLoading}
      onCancel={() => dialog.dismiss()}
      onPrimaryAction={() => onRemove()}
      primaryActionLabel={intl.formatMessage({
        id: 'common.modal.buttons.remove',
      })}
      title={intl.formatMessage({
        id: 'settings.authSettings.ipRestriction.confirmRemoveIpModal.heading',
      })}
    >
      <FormattedMessage
        id="settings.authSettings.ipRestriction.confirmRemoveIpModal.description"
        values={{
          b: (chunks) => <strong>{chunks}</strong>,
          ipToRemove,
        }}
      />
    </ModalAlertDialog>
  );
};

ConfirmRemoveIpModal.propTypes = {
  ipToRemove: PropTypes.string.isRequired,
  onRemoveConfirmed: PropTypes.func,
};

export default ConfirmRemoveIpModal;
