import PropTypes from 'prop-types';
import React, {createContext, useContext} from 'react';

import useIpAccessListState from 'features/settings/components/auth-settings-page/ip-restriction-section/useIpAccessListState';

const IpListAccessContext = createContext({});
const useIpListAccessContext = () => useContext(IpListAccessContext);

const IpListAccessProvider = ({children}) => {
  const ipListManagement = useIpAccessListState();

  return (
    <IpListAccessContext.Provider value={ipListManagement}>{children}</IpListAccessContext.Provider>
  );
};

IpListAccessProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {useIpListAccessContext, IpListAccessProvider};
