import {feature} from '@admin-tribe/acsc';
import {PageBanner} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Link, Text, View} from '@adobe/react-spectrum';
import {TitledSection} from '@pandora/react-titled-section';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAppIntegrationTableContext} from '../../hooks/AppIntegrationTableContext';
import AppIntegrationsPlatformIcon from '../AppIntegrationsPlatformIcon';

import AddAppIntegrationPublisherDetails from './AddAppIntegrationPublisherDetails';
import {useAddAppIntegrationModalContext} from './hooks/AddAppIntegrationModalContext';

// The 'Integration details' step in the AddAppIntegrationModal workflow
// eslint-disable-next-line complexity -- complex UI rendering logic
const AddAppIntegrationModalAppDetails = ({setIsIntegrationDetailsCtaDisabled}) => {
  const {appIntegration, error: appIntegrationError} = useAddAppIntegrationModalContext();
  const isAlreadyAuthorized = appIntegration?.policy;
  const isFeatureEnabled = feature.isEnabled('temp_eu_dsa_compliance_admin_9112');

  const {appIntegrationList, error: appIntegrationListError} = useAppIntegrationTableContext();

  const selectedAppFromList = appIntegrationList?.items?.find(
    (app) => app.clientIds[0] === appIntegration?.clientIds[0]
  );

  // Update the state of the "Next" button based on authorization and error status
  useEffect(() => {
    if (isAlreadyAuthorized || appIntegrationListError || appIntegrationError) {
      setIsIntegrationDetailsCtaDisabled(true);
    } else {
      setIsIntegrationDetailsCtaDisabled(false);
    }
  }, [
    isAlreadyAuthorized,
    appIntegrationListError,
    appIntegrationError,
    setIsIntegrationDetailsCtaDisabled,
  ]);

  return (
    <View>
      {isAlreadyAuthorized && !appIntegrationListError && (
        <PageBannerSection bannerType="warning" />
      )}
      {(appIntegrationListError || appIntegrationError) && <PageBannerSection bannerType="error" />}

      {!appIntegrationListError && !appIntegrationError && appIntegrationList && (
        <TitledSection
          headingLevel={3}
          title={isFeatureEnabled ? selectedAppFromList?.name : appIntegration?.name}
        >
          <View>
            <Heading level={4}>
              <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.heading.publisher" />
            </Heading>
            <PublisherLink
              publisher={
                isFeatureEnabled ? selectedAppFromList?.publisher : appIntegration?.publisher
              }
            />
          </View>

          {isFeatureEnabled && selectedAppFromList?.publisher?.contactDetails && (
            <AddAppIntegrationPublisherDetails selectedAppFromList={selectedAppFromList} />
          )}

          <View>
            <Heading level={4}>
              <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.heading.permissions" />
            </Heading>
            <ul>
              {appIntegration?.permissions?.map((permission) => (
                <li key={permission.displayName}> {permission.displayName} </li>
              ))}
            </ul>
          </View>
          <View>
            <Heading level={4}>
              <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.heading.platforms" />
            </Heading>
            <View elementType="p">
              <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.description.platforms" />
            </View>
            <View>
              {(isFeatureEnabled ? selectedAppFromList?.platforms : appIntegration?.platforms)?.map(
                (platform, index) => (
                  <Flex key={platform} alignItems="center">
                    <AppIntegrationsPlatformIcon platform={platform} />
                    <FormattedMessage
                      id="products.appIntegrations.addAppIntegrationModalAppDetails.description.platforms.clientId"
                      values={{
                        clientId: isFeatureEnabled
                          ? selectedAppFromList?.clientIds?.[index]
                          : appIntegration?.clientIds?.[index],
                      }}
                    />
                  </Flex>
                )
              )}
            </View>
          </View>
        </TitledSection>
      )}
    </View>
  );
};

AddAppIntegrationModalAppDetails.propTypes = {
  /** The setIsIntegrationDetailsCtaDisabled function required for to manage the state of next button */
  setIsIntegrationDetailsCtaDisabled: PropTypes.func.isRequired,
};

const PublisherLink = ({publisher}) => {
  if (publisher?.website) {
    return (
      <Link>
        <a href={publisher.website} rel="noopener noreferrer" target="_blank">
          {publisher.name}
        </a>
      </Link>
    );
  }
  return <Text>{publisher?.name}</Text>;
};

PublisherLink.propTypes = {
  publisher: PropTypes.shape({
    name: PropTypes.string,
    website: PropTypes.string,
  }),
};

const PageBannerSection = ({bannerType}) => {
  const intl = useIntl();
  return (
    // V2 Provider needed for PageBanner
    <SpectrumV2Provider>
      <View marginBottom="size-100">
        <PageBanner
          header={intl.formatMessage({
            id: `products.appIntegrations.addAppIntegrationModalAppDetails.pageBanner.${bannerType}.header`,
          })}
          showDefaultButton={false}
          variant={bannerType}
        >
          <FormattedMessage
            id={`products.appIntegrations.addAppIntegrationModalAppDetails.pageBanner.${bannerType}.children`}
          />
        </PageBanner>
      </View>
    </SpectrumV2Provider>
  );
};

PageBannerSection.displayName = 'PageBannerSection';

PageBannerSection.propTypes = {
  bannerType: PropTypes.oneOf(['warning', 'error']).isRequired,
};

export default AddAppIntegrationModalAppDetails;
