import {INVALID_APPLICATION_POLICY_TARGET_ERROR, POLICY_ACTIONS, feature} from '@admin-tribe/acsc';
import {showSuccess} from '@admin-tribe/acsc-ui';
import {Divider} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {WizardDriver, WizardView} from '@pandora/react-wizard';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import AddAppIntegrationModalContentModel from '../../content/AddAppIntegrationModalContentModel';
import {AppIntegrationTableContextProvider} from '../../hooks/AppIntegrationTableContext';

import AddAppIntegrationModalAppDetails from './AddAppIntegrationModalAppDetails';
import AddAppIntegrationModalSelectApp from './AddAppIntegrationModalSelectApp';
import AddAppIntegrationModalSetPolicy from './AddAppIntegrationModalSetPolicy';
import {ADD_APP_INTEGRATION_WIZARD_STEPS} from './addAppIntegrationModalConstants';
import {useAddAppIntegrationModalContext} from './hooks/AddAppIntegrationModalContext';

// Wizard API will be hoisted so we can control it.
let wizard;

// This component encompassses the whole add app integration modal workflow.
// ONESIE-44925: Post removal of feature flag temp_eu_dsa_compliance_admin_9112 cognitive complexity will come down to normal as ternary operator will be removed
const AddAppIntegrationModal = ({closeModal, onSuccess}) => {
  const intl = useIntl();
  const [isIntegrationDetailsCtaDisabled, setIsIntegrationDetailsCtaDisabled] = useState(false);
  const wizardConfig = useRef({
    stepIndex: 0,
    steps: [
      {
        name: intl.formatMessage({
          id: 'products.appIntegrations.addAppIntegrationModal.wizard.step.selectAppIntegration',
        }),
      },
      {
        name: intl.formatMessage({
          id: 'products.appIntegrations.addAppIntegrationModal.wizard.step.integrationDetails',
        }),
      },
      {
        name: intl.formatMessage({
          id: 'products.appIntegrations.addAppIntegrationModal.wizard.step.setAuthPolicy',
        }),
      },
    ],
  }).current;
  const [stepIndex, setStepIndex] = useState(
    ADD_APP_INTEGRATION_WIZARD_STEPS.SELECT_APP_INTEGRATION
  );
  const content = useContentEntry(AddAppIntegrationModalContentModel);

  const {
    appIntegration,
    isLoading,
    policyFormState,
    setSelectedClientId,
    setTableSelection,
    tableSelection,
  } = useAddAppIntegrationModalContext();
  const {isPolicyFormValid, newAuthorizedUsers, selectedPolicy} = policyFormState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();

  const onCta = useCallback(async () => {
    // eslint-disable-next-line default-case -- unnecessary to have default case
    switch (stepIndex) {
      case ADD_APP_INTEGRATION_WIZARD_STEPS.SELECT_APP_INTEGRATION: {
        setSelectedClientId(tableSelection);
        wizard.goToNextStep();
        break;
      }
      case ADD_APP_INTEGRATION_WIZARD_STEPS.INTEGRATION_DETAILS:
        wizard.goToNextStep();
        break;
      case ADD_APP_INTEGRATION_WIZARD_STEPS.SET_AUTHORIZATION_POLICY:
        setSubmitError(); // clear any existing error messages
        setIsSubmitting(true);
        try {
          await appIntegration.updateAppPolicy(
            POLICY_ACTIONS.ADD,
            selectedPolicy,
            newAuthorizedUsers
          );

          showSuccess(
            intl.formatMessage(
              {id: 'products.appIntegrations.addAppIntegrationModal.successMessage'},
              {
                integrationName: appIntegration.name,
              }
            )
          );
          onSuccess?.();
          closeModal();
        } catch (error) {
          if (error?.response?.data?.errorCode === INVALID_APPLICATION_POLICY_TARGET_ERROR) {
            setSubmitError(
              intl.formatMessage({
                id: 'products.appIntegrations.appIntegrationsPolicyModal.toast.invalidPolicyTarget',
              })
            );
          } else {
            setSubmitError(
              intl.formatMessage({
                id: 'products.appIntegrations.appIntegrationsPolicyModal.toast.genericError',
              })
            );
          }
        } finally {
          setIsSubmitting(false);
        }
        break;
    }
  }, [
    stepIndex,
    onSuccess,
    closeModal,
    setSelectedClientId,
    tableSelection,
    appIntegration,
    selectedPolicy,
    newAuthorizedUsers,
    intl,
  ]);

  const onSecondary = useCallback(() => {
    // eslint-disable-next-line default-case -- unnecessary to have default case
    switch (stepIndex) {
      case ADD_APP_INTEGRATION_WIZARD_STEPS.INTEGRATION_DETAILS:
        wizard.goToPreviousStep();
        setTableSelection();
        break;
      case ADD_APP_INTEGRATION_WIZARD_STEPS.SET_AUTHORIZATION_POLICY:
        wizard.goToPreviousStep();
        break;
    }
  }, [setTableSelection, stepIndex]);

  const isCtaDisabled = useMemo(() => {
    switch (stepIndex) {
      case ADD_APP_INTEGRATION_WIZARD_STEPS.SELECT_APP_INTEGRATION: {
        return !tableSelection;
      }
      case ADD_APP_INTEGRATION_WIZARD_STEPS.INTEGRATION_DETAILS: {
        return isIntegrationDetailsCtaDisabled;
      }
      case ADD_APP_INTEGRATION_WIZARD_STEPS.SET_AUTHORIZATION_POLICY:
        return isSubmitting || !isPolicyFormValid;
      default:
        throw new Error(`Unrecognized wizard step provided`);
    }
  }, [isIntegrationDetailsCtaDisabled, isPolicyFormValid, isSubmitting, stepIndex, tableSelection]);

  const modalContent = (
    <ModalDialog
      content={content.search(ModalDialogModel)[stepIndex]}
      errorMessage={submitError}
      heightVariant="static"
      id="add-app-integrations-modal"
      isCtaDisabled={isCtaDisabled}
      isLoading={isLoading}
      onCancel={closeModal}
      onCta={onCta}
      onSecondary={onSecondary}
    >
      <ModalHeading>
        <FormattedMessage id="products.appIntegrations.addAppIntegrationModal.modalHeading" />
        <Divider marginY="size-150" size="M" />
      </ModalHeading>
      <ModalContent>
        <WizardView
          initConfig={wizardConfig}
          onStepChange={(stepParams) => {
            setStepIndex(
              wizardConfig.steps.findIndex((step) => step.name === stepParams.currentStepName)
            );
          }}
          showStepList
        >
          {stepIndex === ADD_APP_INTEGRATION_WIZARD_STEPS.SELECT_APP_INTEGRATION && (
            <AddAppIntegrationModalSelectApp />
          )}
          {stepIndex === ADD_APP_INTEGRATION_WIZARD_STEPS.INTEGRATION_DETAILS && (
            <AddAppIntegrationModalAppDetails
              setIsIntegrationDetailsCtaDisabled={setIsIntegrationDetailsCtaDisabled}
            />
          )}
          {stepIndex === ADD_APP_INTEGRATION_WIZARD_STEPS.SET_AUTHORIZATION_POLICY && (
            <AddAppIntegrationModalSetPolicy />
          )}
          <WizardDriver
            onInit={(inputWizard) => {
              wizard = inputWizard;
            }}
          />
        </WizardView>
      </ModalContent>
    </ModalDialog>
  );

  return feature.isEnabled('temp_eu_dsa_compliance_admin_9112') ? (
    <AppIntegrationTableContextProvider orgId={rootStore.organizationStore.activeOrgId}>
      {modalContent}
    </AppIntegrationTableContextProvider>
  ) : (
    modalContent
  );
};

AddAppIntegrationModal.propTypes = {
  /** The function to call to close the modal */
  closeModal: PropTypes.func.isRequired,
  /** The success function to call when app integration is successfully added */
  onSuccess: PropTypes.func,
};

export default AddAppIntegrationModal;
