import {Content, Flex, Heading, IllustratedMessage, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';

const LegacyRosterSyncError = () => {
  const intl = useIntl();

  return (
    <Flex alignItems="center" justifyContent="center" marginTop="size-600">
      <IllustratedMessage>
        <NotFound />
        <Heading>
          {intl.formatMessage({
            id: 'settings.rosterSync.notAvailable.heading',
          })}
        </Heading>
        <Content>
          <Text>
            {intl.formatMessage({
              id: 'settings.rosterSync.notAvailable.description',
            })}
          </Text>
          <View>
            <GoUrl name="aac_roster_sync">
              {intl.formatMessage({
                id: 'settings.rosterSync.notAvailable.learnMore',
              })}
            </GoUrl>
          </View>
        </Content>
      </IllustratedMessage>
    </Flex>
  );
};

export default LegacyRosterSyncError;
