import {PRODUCT_DELEGATION_TARGET, feature} from '@admin-tribe/acsc';

/**
 * @description Method to get products which can be assigned to users.
 *  Products which are not administerable should be included.
 *  They will show as disabled in the list of assignable products without free offers..
 *
 * @param {ProductList} productList - the product list
 * @returns {Array<Product>} The products which can be assigned to users
 *
 */
function getProductsAssignableToUsers(productList) {
  return productList.items.filter((product) => {
    const hasOrgDelegatableAndTarget = feature.isEnabled('temp_delegation_type_dcomm')
      ? product.hasDelegationTarget() &&
        !product.fulfillableItemList.hasOrgDelegatableWithTarget(
          product.processingInstructions?.license_data?.delegation_targets
        )
      : product.fulfillableItemList.hasDelegationType() &&
        !product.fulfillableItemList.hasOrgDelegatable();

    const hasOrgOnDemandConsumableAndTarget = feature.isEnabled('temp_delegation_type_dcomm')
      ? !product.fulfillableItemList.hasOrgOnDemandConsumableWithTarget(
          product.processingInstructions?.license_data?.delegation_targets
        )
      : !product.fulfillableItemList.hasOrgOnDemandConsumable();

    return (
      hasOrgDelegatableAndTarget &&
      hasOrgOnDemandConsumableAndTarget &&
      [
        PRODUCT_DELEGATION_TARGET.TYPE1,
        PRODUCT_DELEGATION_TARGET.TYPE2,
        PRODUCT_DELEGATION_TARGET.TYPE2E,
        PRODUCT_DELEGATION_TARGET.TYPE3,
      ].some((delegationTarget) => product.isDelegatableToType(delegationTarget)) &&
      (feature.isEnabled('temp_b2b_free_provisioning')
        ? !product.isDisplayLocationSettingsOnly()
        : true)
    );
  });
}

// eslint-disable-next-line import/prefer-default-export -- utils, in future we need to add more functions as we start migrating binky
export {getProductsAssignableToUsers};
