import {OrganizationUser} from '@admin-tribe/acsc';

/**
 * Class which represents a credentials ISV binding.
 */
class IsvBinding extends OrganizationUser {
  static apiResponseTransformer(responseData) {
    return new IsvBinding(responseData);
  }

  constructor(options) {
    super(options);
    this.orgCode = options.orgCode;
    this.technicalAccountId = options.technicalAccountId;
    this.partnerAppName = options.partnerAppName;
    this.createdAt = options.createdAt;
    this.deepLink = options.deepLink;

    this.id = options.technicalAccountId;
    this.email = options.technicalAccountId;
    this.userName = options.technicalAccountId;
  }

  addRequiredAttributes(options) {
    this.type = options.type;
    this.products = options.products;
  }

  /**
   * @description Method to retrieve the display name of this user.
   *
   * @returns {String} Name of the integration user
   */
  getDisplayName() {
    return this.partnerAppName;
  }
}

export default IsvBinding;
