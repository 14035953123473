// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import eduRosterSyncV3 from 'features/settings/api/eduRosterSyncV3';
import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import ProviderLogoAndName from 'features/settings/components/roster-sync-settings/roster-sync-details/ProviderLogoAndName';

const RemoveRosterSyncConfirmationModal = () => {
  const dialog = useDialogContainer();
  const intl = useIntl();
  const {syncConfig, setSyncConfig, setSelectedApps, setProvider, setUserGroups} =
    useRosterSyncContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemove = async () => {
    setIsLoading(true);
    try {
      await eduRosterSyncV3.removeRosterSync({syncId: syncConfig.syncId});
      setSyncConfig({});
      // Reset the selected apps, provider, and user groups
      setSelectedApps([]);
      setProvider(null);
      setUserGroups([]);

      showSuccessToast(intl.formatMessage({id: 'settings.rosterSync.success.removeSync'}));
    } catch {
      showErrorToast(intl.formatMessage({id: 'settings.rosterSync.errors.removeSync'}));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleRemove}
      primaryActionLabel={intl.formatMessage({id: 'common.modal.buttons.remove'})}
      title={intl.formatMessage({id: 'settings.rosterSync.removeModal.title'})}
    >
      <ProviderLogoAndName />
      <p>
        <FormattedMessage id="settings.rosterSync.removeModal.description" />
      </p>
      <p>
        <strong>
          <FormattedMessage id="settings.rosterSync.removeModal.areYouSure" />
        </strong>
      </p>
    </ModalAlertDialog>
  );
};

export default RemoveRosterSyncConfirmationModal;
