// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {View} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import EduRosterSyncSchedule from 'features/settings/common/components/edu-roster-sync-schedule/EduRosterSyncSchedule';
import AppOptionSelector from 'features/settings/components/roster-sync-settings/AppOptionSelector';
import GroupOptionSelector from 'features/settings/components/roster-sync-settings/GroupOptionSelector';

import {useRosterSyncContext} from './RosterSyncContext';

const RosterSyncSettingsStep = () => {
  const {frequency, setFrequency} = useRosterSyncContext();

  return (
    <View data-testid="modal-content">
      <EduRosterSyncSchedule defaultSelectedKey={frequency} onChange={setFrequency} />
      <View marginBottom="size-100" marginTop="size-300">
        <FormattedMessage id="settings.rosterSync.setup.options.groups.label" />
      </View>

      <GroupOptionSelector />

      <View marginBottom="size-100" marginTop="size-300">
        <FormattedMessage id="settings.rosterSync.setup.options.apps.label" />
      </View>

      <AppOptionSelector />
    </View>
  );
};

export default RosterSyncSettingsStep;
