// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {log} from '@admin-tribe/acsc';
import {useCallback} from 'react';

import eduRosterSyncV3 from 'features/settings/api/eduRosterSyncV3';
import {CONSOLE_URL} from 'features/settings/services/ExternalAuthService';

/**
 * Hook to use the EDU roster sync V3 API inside React components
 */
const useEduRosterSyncV3 = () => {
  const getAccessToken = useCallback(async ({syncProvider, authCode}) => {
    try {
      return await eduRosterSyncV3.getAccessToken({
        authCode,
        redirectUri: CONSOLE_URL,
        syncProvider,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error getting token for sync provider', syncProvider);
      throw error;
    }
  }, []);

  const getRosterTenants = useCallback(async ({syncProvider, accessToken}) => {
    try {
      return await eduRosterSyncV3.getRosterTenants({
        accessToken,
        syncProvider,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error getting tenants for syncProvider', syncProvider);
      throw error;
    }
  }, []);

  const getUserGroups = useCallback(async ({grouping, products}) => {
    try {
      return await eduRosterSyncV3.getUserGroups({
        grouping,
        products,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error getting user groups for grouping:', grouping);
      throw error;
    }
  }, []);

  const createOrgRosterSync = useCallback(
    async ({
      accessToken,
      districtId,
      grouping,
      products,
      rosterProvider,
      syncSchedule,
      userGroups,
    }) => {
      try {
        return await eduRosterSyncV3.createOrgRosterSync({
          accessToken,
          districtId,
          grouping,
          products,
          rosterProvider,
          syncSchedule,
          userGroups,
        });
      } catch (error) {
        log.error('[ID][EDU_ROSTER] Error creating org roster sync for provider:', rosterProvider);
        throw error;
      }
    },
    []
  );

  const getOrgRosterSyncDetails = useCallback(async () => {
    try {
      return await eduRosterSyncV3.getOrgRosterSyncDetails();
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error getting org roster sync details');
      throw error;
    }
  }, []);

  const putRosterSyncAction = useCallback(async ({syncId, syncAction}) => {
    try {
      return await eduRosterSyncV3.putRosterSyncAction({syncAction, syncId});
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error updating sync action for id: ', syncId);
      throw error;
    }
  }, []);

  const patchRosterSync = useCallback(async ({syncId, data}) => {
    try {
      return await eduRosterSyncV3.patchRosterSync({data, syncId});
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error updating the sync with id: ', syncId);
      throw error;
    }
  }, []);

  const removeRosterSync = useCallback(async ({syncId}) => {
    try {
      return await eduRosterSyncV3.removeRosterSync({syncId});
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error deleting the sync with id: ', syncId);
      throw error;
    }
  }, []);

  return {
    createOrgRosterSync,
    getAccessToken,
    getOrgRosterSyncDetails,
    getRosterTenants,
    getUserGroups,
    patchRosterSync,
    putRosterSyncAction,
    removeRosterSync,
  };
};

export default useEduRosterSyncV3;
