import {Cache, log} from '@admin-tribe/acsc';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';
import ipAccessList from 'features/settings/api/ipAccessList';
import {getCacheKey} from 'features/settings/hooks/api/utils/hooksApiUtils';

const IP_ACCESS_LIST_CACHE_KEY = 'ipAccessList';

const ipAccessCache = new Cache();

const getIpAccessListCacheKey = () =>
  getCacheKey(IP_ACCESS_LIST_CACHE_KEY, rootStore.organizationStore.activeOrgId);

/**
 * Gets the list of allowed IPs.
 *
 * @returns {Promise<any>}
 */
const getIpAccessList = async () => {
  try {
    const cachedPromise = ipAccessCache.get(getIpAccessListCacheKey());

    let response;

    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      const requestPromise = ipAccessList.getIpAccessList();
      ipAccessCache.put(getIpAccessListCacheKey(), requestPromise);

      response = await requestPromise;
    }

    return response.data;
  } catch (error) {
    log.error(`[ID][IP_ACCESS_LIST] Error getting IP access list`, error);

    throw error;
  }
};

/**
 * Saves the list of allowed IPs.
 *
 * @param {Array} ipList - The list of allowed IPs.
 *
 * @returns {Promise<any>}
 */
const saveIpAccessList = async (ipList) => {
  try {
    const response = await ipAccessList.saveIpAccessList(ipList);
    ipAccessCache.clear(getIpAccessListCacheKey());

    return response.data;
  } catch (error) {
    log.error(`[ID][IP_ACCESS_LIST] Could not save ip access list`, error);

    throw error;
  }
};

/**
 * Hook to use the IP access list API inside React components.
 */
const useIpAccessList = () => {
  const getIpAccessListCallback = useCallback(getIpAccessList, []);
  const saveIpAccessListCallback = useCallback(saveIpAccessList, []);
  const clearIpAccessListCache = useCallback(
    () => ipAccessCache.clear(getIpAccessListCacheKey()),
    []
  );

  return {
    clearIpAccessListCache,
    getIpAccessList: getIpAccessListCallback,
    saveIpAccessList: saveIpAccessListCallback,
  };
};

export default useIpAccessList;

export {getIpAccessList, saveIpAccessList};
