import clamp from 'lodash/clamp';
import {makeObservable, observable, runInAction} from 'mobx';

import UserStore from './UserStore';

// Common domain data for Api credentials and ISV bindings
// Mostly used for handling pagination of ISV bindings
class UserIsvStore extends UserStore {
  isvBindingsPageNumber = -1; // Initialize to -1 to indicate no ISV bindings should be fetched
  totalApiIntegrations = 0;
  totalIntegrations = 0;

  /**
   * @param {Object} [options] - configuration for the store
   * @param {Object} options.listClassRef - the reference to the list class which should be a subclass of MemberList
   * @param {Object} [options.listOptions] - options passed to listClassRef's get method.
   */
  constructor({
    listClassRef,
    listOptions,
    immediateLoad = false,
    refreshUserCountRef = null,
    ...memberStoreProps
  }) {
    super({immediateLoad, listClassRef, listOptions, refreshUserCountRef, ...memberStoreProps});

    this.isvBindingsPageNumber = -1; // Initialize ISV bindings page number to -1
    this.totalApiIntegrations = 0;
    this.totalIntegrations = 0;

    makeObservable(this, {
      isvBindingsPageNumber: observable, // Make observable
      totalApiIntegrations: observable,
      totalIntegrations: observable,
    });
  }

  /**
   * This is the callback for Store.load() which wraps this in a try/catch.
   * If an error is caught this.hasLoadError will be set to true.
   * @param {Object}[options] - Options
   * @param {Function} [options.joinFn] - Function to call after the list is returned.
   *   This function is called with one parameter which is the list items.
   *   This function should make any other modifications needed to the list items.
   */
  async fetchData(options) {
    this.memberList = await this.listClassRef.get({
      filterQuery: this.filterQuery,
      isvBindingsPageNumber: this.isvBindingsPageNumber, // Pass to list class
      orgId: this.orgId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortExpression: this.sortExpression,
      sortOrder: this.sortOrder,
      ...this.listOptions,
    });

    // Join in any other data before currentItems is set.
    // If props are added/modified in items after currentItems is set, the table may not re-render.
    if (this.memberList.items.length > 0 && options?.joinFn) {
      this.memberList.items = await options.joinFn(this.memberList.items);
    }

    this.totalApiIntegrations =
      this.totalApiIntegrations === 0
        ? this.memberList.pagination.itemCount
        : this.totalApiIntegrations;
    this.totalIntegrations = Math.max(
      this.totalApiIntegrations + this.memberList.totalIsvBindings,
      this.totalIntegrations
    );

    this.memberList.pagination.itemCount = this.totalIntegrations;
    this.memberList.pagination.totalPages = Math.ceil(this.totalIntegrations / this.pageSize);

    runInAction(() => {
      this.currentItems = this.memberList.items;

      // Update the count of items for the paginator.
      this.totalPages = this.memberList.pagination.totalPages;
      // Only update the scorecard value shown if there isn't a search filter applied.
      if (this.memberList.shouldUpdateTotalItemCount()) {
        this.scorecardValue = this.memberList.getTotalItemCount();
      }

      // Setting this to false here instead of relying on it getting set in the finally() in ACSC's Store.js
      // This prevents an additional state update and re-render to all the components that use this store
      this.isLoading = false;
    });
  }

  handleGoToNextPage(totalApiPages) {
    this.pageNumber += 1;

    // Check if we need to increment ISV bindings page
    if (this.pageNumber > totalApiPages) {
      // If we're transitioning from API pages to ISV pages, start at 0
      if (this.isvBindingsPageNumber < 0) {
        this.isvBindingsPageNumber = 0;
      } else {
        this.isvBindingsPageNumber += 1;
      }
    }
  }

  handleGoToPrevPage(totalApiPages) {
    this.pageNumber -= 1;

    // Check if we need to decrement ISV bindings page
    if (this.pageNumber > totalApiPages) {
      this.isvBindingsPageNumber = Math.max(0, this.isvBindingsPageNumber - 1);
    } else {
      this.isvBindingsPageNumber = -1;
    }
  }

  handleLastPageIsvBindingsAndStateUpdates(totalApiPages, combinedTotalPages, currentPageNumber) {
    // Check if we're on the last page of API integrations and need to show ISV bindings
    // This handles the case where the last API page is full but there are ISV bindings to show
    if (this.pageNumber === totalApiPages && this.totalApiIntegrations % this.pageSize !== 0) {
      this.isvBindingsPageNumber = 0; // Start showing ISV bindings
    }

    this.totalPages = Math.max(combinedTotalPages, 1);

    // This should not be needed but be safe.
    this.pageNumber = clamp(this.pageNumber, 1, this.totalPages);

    if (this.pageNumber !== currentPageNumber) {
      this.load();
    }
  }

  handlePageNumberChange(pageNumber, totalApiPages) {
    if (this.pageNumber !== pageNumber) {
      this.pageNumber = pageNumber;

      // Reset ISV bindings page number when changing pages directly
      if (this.pageNumber === 1) {
        this.isvBindingsPageNumber = -1; // Set to -1 to indicate no ISV bindings needed
      } else if (this.pageNumber > totalApiPages) {
        // Calculate ISV bindings page number based on current page
        this.isvBindingsPageNumber = this.pageNumber - totalApiPages;
      } else {
        this.isvBindingsPageNumber = -1;
      }
    }
  }

  // One of pageNumber, goToNext or goToPrevious should be set.
  onPageNumberChange({pageNumber, goToNext, goToPrevious}) {
    const currentPageNumber = this.pageNumber;

    // Calculate API integration user pages
    const apiIntegrationUserCount = this.totalApiIntegrations;
    const totalApiPages = Math.ceil(apiIntegrationUserCount / this.pageSize);

    // Calculate total pages including ISV bindings
    const combinedTotalPages = Math.ceil(this.totalIntegrations / this.pageSize);

    if (pageNumber !== undefined) {
      this.handlePageNumberChange(pageNumber, totalApiPages);
    } else if (goToNext) {
      this.handleGoToNextPage(totalApiPages);
    } else if (goToPrevious) {
      this.handleGoToPrevPage(totalApiPages);
    }
    // Handles the case where the last API page is full but there are ISV bindings to show
    // And updates the state variables of the store
    this.handleLastPageIsvBindingsAndStateUpdates(
      totalApiPages,
      combinedTotalPages,
      currentPageNumber
    );
  }

  // Check if we're on the last page of API integrations and need to show ISV bindings
  // Use to reset the list state after a delete operation.
  // If using a TableSection make sure to update it to keep it in sync with the list.
  resetListState() {
    this.filterQuery = '';
    this.pageNumber = 1;
    this.isvBindingsPageNumber = -1; // Reset ISV bindings page number to -1
    this.totalApiIntegrations = 0;
    this.totalIntegrations = 0;
    this.selectedItems = [];
  }
}

export default UserIsvStore;
