import {TextArea} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

const IP_LIST_SEPARATOR = ',';
const IPV4_REGEX_VALIDATOR =
  /^(?:(?:[1-9]?\d|1\d\d|2(?:[0-4]\d|5[0-5]))\.){3}(?:[1-9]?\d|1\d\d|2(?:[0-4]\d|5[0-5]))(?:\/(?:[12]?\d|3[0-2]))?$/;

const IpTextArea = ({
  allowMultiple = true,
  defaultValue = '',
  onChange,
  onValidationStateChanged,
}) => {
  const [isPristine, setIsPristine] = useState(true);
  const [ipValue, setIpValue] = useState(defaultValue);
  const intl = useIntl();

  const ipAddressValidator = (value) => {
    if (isPristine && !value) {
      return null;
    }

    if (!value) {
      onValidationStateChanged?.(false);
      return 'invalid';
    }

    if (allowMultiple) {
      const ipAddresses = value.split(IP_LIST_SEPARATOR);
      const invalidIpAddresses = ipAddresses.filter((ip) => !IPV4_REGEX_VALIDATOR.test(ip.trim()));

      if (invalidIpAddresses.length > 0) {
        onValidationStateChanged?.(false);
        return 'invalid';
      }
    } else if (!IPV4_REGEX_VALIDATOR.test(value.trim())) {
      onValidationStateChanged?.(false);
      return 'invalid';
    }

    onValidationStateChanged?.(true);
    return null;
  };

  const onIpAddressesChange = (value) => {
    setIsPristine(false);
    setIpValue(value);

    onChange?.(value);
  };

  const validationState = ipAddressValidator(ipValue);

  return (
    <TextArea
      autoFocus
      defaultValue={defaultValue}
      description={intl.formatMessage({
        id: 'settings.authSettings.ipRestriction.ipTextArea.description',
      })}
      errorMessage={intl.formatMessage({
        id: 'settings.authSettings.ipRestriction.ipTextArea.errorMessage',
      })}
      height="size-2000"
      label={intl.formatMessage({id: 'settings.authSettings.ipRestriction.ipTextArea.label'})}
      marginTop="size-200"
      onChange={onIpAddressesChange}
      validationState={validationState}
      width="100%"
    />
  );
};

IpTextArea.propTypes = {
  allowMultiple: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onValidationStateChanged: PropTypes.func,
};

export default IpTextArea;

export {IP_LIST_SEPARATOR};
