// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaPromoImageDisplay from 'common/components/sophia/sophia-promo/sophia-promo-image-display/SophiaPromoImageDisplay';
import SophiaPromoRecommendationReason from 'common/components/sophia/sophia-promo/sophia-promo-recommendation-reason/SophiaPromoRecommendationReason';
import SophiaPromoVideoDisplay from 'common/components/sophia/sophia-promo/sophia-promo-video-display/SophiaPromoVideoDisplay';

import SophiaPromoActions from './sophia-promo-actions/SophiaPromoActions';
import SophiaPromoDescription from './sophia-promo-description/SophiaPromoDescription';
import SophiaPromoTitle from './sophia-promo-title/SophiaPromoTitle';

/**
 * SophiaPromoRecommendationPanel component. This is a panel that is used in the recommendation section on the shell,
 * one of a set of related panels that can contain different combinations of the child components, depending
 * on the type of panel. For example, the Banner version does not have room to display a hoverbox for legal
 * terms and conditions, so the valid children for that component will not include that child component (the
 * SophiaPromoHoverOverTooltip).
 *
 * The component is designed to be used with the JsonPayloadProvider, which will provide the JSON inputPayload
 * data to the children of the component, and allow for optional transforms to be applied to the inputPayload
 * data before it is passed to the children. The transforms are not exposed to the children, and should not be
 * manipulated by the children.
 *
 * @returns {Element} the SophiaPromoRecommendationPanel component
 * @constructor SophiaPromoRecommendationPanel component
 */
const SophiaPromoRecommendationPanel = ({onCardCTAAction}) => {
  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  return (
    <View
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      data-testid="one-console-promo-recommend"
      padding="size-200"
      position="relative"
      UNSAFE_style={{
        backgroundColor: payload.backgroundColor,
        boxShadow: '0 0.1875rem 0.375rem rgba(0,0,0,.08)',
      }}
    >
      <View data-testid="one-console-promo-image">
        <SophiaPromoImageDisplay />
      </View>

      <View data-testid="one-console-promo-video">
        <SophiaPromoVideoDisplay />
      </View>

      <View data-testid="one-console-promo-recommendation">
        <SophiaPromoRecommendationReason />
      </View>

      <View
        borderBottomWidth="none"
        borderColor="gray-300"
        data-testid="one-console-promo-recommend-content"
        marginTop="size-100"
        paddingBottom="18px"
      >
        <Flex direction="column">
          <SophiaPromoTitle />
          <SophiaPromoDescription />
        </Flex>
      </View>

      <Flex
        alignItems="center"
        data-testid="one-console-promo-recommend-footer"
        gap={{base: 'size-100', S: 'size-0'}}
        justifyContent="space-between"
      >
        <SophiaPromoActions onPressAction={onCardCTAAction} />
      </Flex>
    </View>
  );
};

SophiaPromoRecommendationPanel.propTypes = {
  onCardCTAAction: PropTypes.func,
};

export default SophiaPromoRecommendationPanel;
