import {feature} from '@admin-tribe/acsc';
import {SELECTION_MODE} from '@pandora/react-table-section';
import React from 'react';

import rootStore from 'core/RootStore';

import {AppIntegrationTableContextProvider} from '../../hooks/AppIntegrationTableContext';
import AppIntegrationsTableSection from '../table/AppIntegrationsTableSection';

import {useAddAppIntegrationModalContext} from './hooks/AddAppIntegrationModalContext';

/**
 * The 'Select app integration' step in the AddAppIntegrationModal workflow
 * Component that renders the app integration selection step in the add integration modal workflow
 * @returns {React.ReactElement} The app integration selection component
 */
const AddAppIntegrationModalSelectApp = () => {
  const {setTableSelection} = useAddAppIntegrationModalContext();

  return feature.isEnabled('temp_eu_dsa_compliance_admin_9112') ? (
    <AppIntegrationsTableSection
      // Need exact px, can't use Spectrum variables. This exact px intentionally
      // cuts off a table row to indicate that the table is scroll-able.
      maxHeight="395px"
      onTableSelection={setTableSelection}
      selectionMode={SELECTION_MODE.SINGLE}
    />
  ) : (
    <AppIntegrationTableContextProvider orgId={rootStore.organizationStore.activeOrgId}>
      <AppIntegrationsTableSection
        // Need exact px, can't use Spectrum variables. This exact px intentionally
        // cuts off a table row to indicate that the table is scroll-able.
        maxHeight="395px"
        onTableSelection={setTableSelection}
        selectionMode={SELECTION_MODE.SINGLE}
      />
    </AppIntegrationTableContextProvider>
  );
};

export default AddAppIntegrationModalSelectApp;
