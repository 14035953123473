import {jilUsers, log} from '@admin-tribe/acsc';

import adobeioConsoleIntegrations from 'common/api/adobeio-console/adobeioConsoleIntegrations';
import ApiOrganizationList from 'core/api-organization/ApiOrganizationList';

import IsvBinding from './IsvBinding';
import JIL_ALL_TECH_ACC_PAGE_SIZE from './JilAllTechAccPageSize';

/**
 * The list of credentials ISV bindings for the specified Renga org.
 */
class IsvBindingList {
  /**
   * @description Method to retrieve ISV bindings from the back-end.
   *
   * @param {Object} options - Options for the constructor
   * @param {String} options.rengaOrgId - the Renga org id
   * @param {String} options.adobeioOrgId - the AdobeIO org id
   * @param {Number} options.page - the page number
   * @param {Number} options.size - the page size
   * @returns {Promise<IsvBindingList>} Promise that resolves to the instance when the refresh is successful
   */
  static get({rengaOrgId, adobeioOrgId, page, size}) {
    const model = new IsvBindingList({adobeioOrgId, page, rengaOrgId, size});

    return model.refresh();
  }

  /**
   * @description Constructor for IsvBindingList model .
   */
  constructor({adobeioOrgId, rengaOrgId, page, size}) {
    this.adobeioOrgId = adobeioOrgId;
    this.rengaOrgId = rengaOrgId;
    this.page = page;
    this.size = size;
    this.items = [];
    this.totalIsvBindings = 0;
  }

  /**
   * @description Method to refresh the contents of the list.
   * @returns {Promise<IsvBindingList>} promise - resolved when the list is refreshed.
   */
  async refresh() {
    let isvBindingList, jilUsersList;
    let allJilUsers = [];

    try {
      // Get the list of adobeio organizations. If adobeioOrgId is already present, don't call
      if (!this.adobeioOrgId) {
        const adobeioOrgs = await ApiOrganizationList.get();
        // Find the adobeio organization which maps to the current org.
        this.adobeioOrgId = adobeioOrgs.getAdobeioOrgId({rengaOrgId: this.rengaOrgId});
      }
    } catch (error) {
      return Promise.reject(error);
    }

    try {
      // Get the list of intergrations for the adobeio organization.
      isvBindingList = await adobeioConsoleIntegrations.getOrganizationCredentialsIsvBindings({
        orgId: this.adobeioOrgId,
        page: this.page,
        size: this.size,
      });
      this.totalIsvBindings = isvBindingList.total || 0;
    } catch (error) {
      log.error(
        `IsvBindingList.refresh() failed to load credentials ISV bindings. Error: ${error}`
      );
      return Promise.reject(error);
    }

    try {
      // Initial request to get first page and total count
      const initialResponse = await jilUsers.queryUsers({
        orgId: this.rengaOrgId,
        params: {
          filter_include_domain: 'techacct.adobe.com',
          page: 0,
          page_size: JIL_ALL_TECH_ACC_PAGE_SIZE,
        },
      });

      allJilUsers = [...initialResponse.data];
      const totalCount = Number.parseInt(initialResponse.headers['x-total-count'], 10);

      // If we have more users than our page size, fetch additional pages
      if (totalCount > JIL_ALL_TECH_ACC_PAGE_SIZE) {
        const totalPages = Math.ceil(totalCount / JIL_ALL_TECH_ACC_PAGE_SIZE);

        // Create array of promises for all additional pages
        const additionalPagePromises = [];
        for (let page = 1; page < totalPages; page++) {
          additionalPagePromises.push(
            jilUsers.queryUsers({
              orgId: this.rengaOrgId,
              params: {
                filter_include_domain: 'techacct.adobe.com',
                page,
                page_size: JIL_ALL_TECH_ACC_PAGE_SIZE,
              },
            })
          );
        }

        // Wait for all promises to resolve and combine the results
        const additionalResponses = await Promise.all(additionalPagePromises);
        allJilUsers = [...allJilUsers, ...additionalResponses.flatMap((response) => response.data)];
      }

      jilUsersList = {
        data: allJilUsers,
        headers: initialResponse.headers,
      };
    } catch (error) {
      log.error(`IsvBindingList.refresh() failed to load JIL users. Error: ${error}`);
      return Promise.reject(error);
    }

    // Construct the list which is Array<IsvBinding>.
    this.items = isvBindingList?.content?.map((isvBinding) =>
      IsvBinding.apiResponseTransformer(isvBinding)
    );
    this.items?.forEach((item) => {
      const jilUser = jilUsersList.data.find((user) => user.id === item.technicalAccountId);
      if (jilUser) {
        item.addRequiredAttributes(jilUser);
      }
    });

    return this;
  }
}

export default IsvBindingList;
