import {feature, getValueForQueryParam, log, navBus} from '@admin-tribe/acsc';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import AddUsersToOrgModal from 'common/components/add-user-modals/AddUsersToOrgModal';
import {MODAL_TYPE} from 'common/hooks/useGlobalModal';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import RootStore from 'core/RootStore';
import trialHelper from 'core/products/trial-helper/trialHelper';
import chatProvider from 'core/services/chat/chatProvider';

const SOPHIA_HVA_BANNER_NAME = 'SophiaHvaBanner';
const {SURFACE_ID} = SOPHIA_CONSTANTS;

const BUTTON_VARIANTS = {
  CTA: 'cta',
  DEFAULT: 'primary',
  SECONDARY: 'secondary',
};

const COLORS = {
  COLOR_GREY_50: '#ffffff',
  COLOR_GREY_800: '#4b4b4b',
  COLOR_GREY_900: '#2c2c2c',
};

/**
 * @description picks card content from a sophia card
 * @param {Object} card card property from SophiaCard
 * @returns {Object} card content
 */
function getContentObject(card) {
  const cardProperties = [
    'bodyCopy',
    'cardLabel',
    'ctaLabel',
    'displayText',
    'eventData',
    'secondaryCTALabel',
  ];

  return pick(card, cardProperties);
}

/**
 * The callback function to handle a sophia button's click event
 * @param {String} event The global modal ids start with https://adminconsole.adobe.com/#, special case
 *   callbacks start with #. Otherwise, it is a regular url.
 * @returns {Function} Returns a callback function based on the input event.
 */
function getCtaClickEventCallback(event) {
  if (event?.startsWith('https://adminconsole.adobe.com/#')) {
    return () => getModal(event);
  }
  if (event?.startsWith('https://adminconsole.adobe.com/link#')) {
    return getSpecialCaseCallback(event);
  }
  // indicates that the intent is to remain in admin console after other preceding cases have been exhausted
  // Note: When testing on stage, the Odin data should use these 'blind deep link' URLS as prod address (as below).
  // This is required as there is only one Odin data source for both stage and prod, and the Odin data needs to work
  // for both stage and prod. This is achieved because the navigateToUrlCallback() will relativize the path, and
  // use the NavBus to navigate to the relative path. This has the effect of making deeplink urls in the odin data
  // that start with 'https://adminconsole.adobe.com' (seen below) also work with stage automatically.
  // Note these deep links are 'blind' in that they do not have the orgId added in advance, as that is also resolved
  // with the navigateToUrlCallback() function.
  if (event?.startsWith('https://adminconsole.adobe.com')) {
    return navigateToUrlCallback(event);
  }
  if (typeof window !== 'undefined') {
    return () => window.open(event, '_blank');
  }

  return () => {};
}

/**
 * @description determines CSS classes from the card's surfaceId
 * @param {Object} card the sophia card
 * @returns {Array<String>} array of CSS classes
 */
function getClassesForCard(card = {}) {
  const cardClasses = ['sophia-card'];
  const surfaceId = card.surfaceId;

  switch (surfaceId) {
    case SURFACE_ID.ONE_CONSOLE:
      if (card.containerId === '2') {
        // Sophia banner for the First Mile project
        cardClasses.push('overview-tutorial');
      }
      break;
    case SURFACE_ID.ONE_CONSOLE_ACCOUNT:
    case SURFACE_ID.ONE_CONSOLE_PACKAGES:
    case SURFACE_ID.ONE_CONSOLE_PRODUCTS:
    case SURFACE_ID.ONE_CONSOLE_SETTINGS:
    case SURFACE_ID.ONE_CONSOLE_SUPPORT:
    case SURFACE_ID.ONE_CONSOLE_USERS:
      // Sophia banner for the First Mile project
      cardClasses.push('tutorial');
      break;
    case SURFACE_ID.ONE_CONSOLE_PROMOTION:
      cardClasses.push('promotion');
      break;
    default:
      break;
  }

  return cardClasses;
}

/**
 * @description determines variant for the primary CTA
 * @param {Object} card the sophia card
 * @returns {String} the primary CTA variant
 */
function getPrimaryCTAButtonVariant(card) {
  const {eventData} = card.getCard();

  const ctaButtonVariant = eventData?.ctaButtonVariant;

  if (isEmpty(ctaButtonVariant)) {
    return getDefaultCTAButtonVariant(card);
  }
  return ctaButtonVariant;
}

/**
 * @description determines variant for secondary CTA
 * @param {Object} card the sophia card
 * @returns {String} the secondary CTA variant
 */
function getSecondaryCTAButtonVariant(card) {
  const {eventData} = card.getCard();
  const secondaryCTAButtonVariant = eventData?.secondaryCTAButtonVariant;
  if (isEmpty(secondaryCTAButtonVariant)) {
    return BUTTON_VARIANTS.SECONDARY;
  }
  return secondaryCTAButtonVariant;
}

/**
 * @description generates CSS object from sophia card properties
 * @param {Object} card the sophia card
 * @returns {Object} the CSS styles as an object
 */
function getStyleObj(card) {
  const {
    backgroundFillColor,
    backgroundImage,
    bodyCopyAlignment,
    ctaAlignment,
    displayTextAlignment,
    eventData,
  } = card;

  return pickBy({
    bodyCopy: {
      color: assignWithDefault(eventData?.headerFontColor, getDefaultHeaderFontColor(card)),
      textAlign: assignWithDefault(bodyCopyAlignment, 'left'),
    },
    cta: {
      textAlign: assignWithDefault(ctaAlignment, 'left'),
    },
    displayText: {
      color: assignWithDefault(
        eventData?.descriptionFontColor,
        getDefaultDescriptionFontColor(card)
      ),
      textAlign: assignWithDefault(displayTextAlignment, 'center'),
    },
    global: {
      backgroundColor: assignWithDefault(backgroundFillColor, null),
      backgroundImage: backgroundImage ? `url('${backgroundImage}')` : null,
    },
  });
}

/* Private functions */

function assignWithDefault(value, defaultValue) {
  return isEmpty(value) ? defaultValue : value;
}

/**
 * @description returns the modal to display, formatted for the useGlobalModal hook
 * @param {String} event The global modal ids start with https://adminconsole.adobe.com/#, special case
 * @returns {Object} useGlobalModal payload
 */
function getModal(eventName) {
  const baseUrl = eventName?.split('?')?.[0];
  switch (baseUrl) {
    case 'https://adminconsole.adobe.com/#add-user':
      return {
        component: AddUsersToOrgModal,
        type: MODAL_TYPE.SRC2,
      };
    case 'https://adminconsole.adobe.com/#jarvis':
      chatProvider.openMessagingWindow({
        sourceText: SOPHIA_HVA_BANNER_NAME,
      });
      return null;
    case 'https://adminconsole.adobe.com/#quick-assign':
      return {
        componentBindings: [],
        componentName: 'appQuickAssignModal2',
        type: MODAL_TYPE.SRC1,
      };
    case 'https://adminconsole.adobe.com/#video-modal':
      return {
        componentBindings: [
          {
            attributeName: 'videoUrl',
            type: 'String',
            value: getValueForQueryParam(eventName, 'url'),
          },
        ],
        componentName: 'binkyVideoModal',
        type: MODAL_TYPE.SRC1,
      };
    default:
      return null;
  }
}

function getSpecialCaseCallback(eventName) {
  switch (eventName) {
    case 'https://adminconsole.adobe.com/link#biz-trial-buy-now': {
      // This uses a jumpUrl which has to be constructed right before use since it is based on our current auth
      // token as well as the current org, the trial product id and the current locale.
      const productId = trialHelper.getTrialProduct()?.id;
      if (productId) {
        return () => {
          trialHelper.openBuyNowUrl(productId);
        };
      }
      return errorCase();
    }
    default:
      return errorCase();
  }

  ////////////

  function errorCase() {
    log.error(`sophiaCardUtils: no callback for ${eventName}`);
    return () => {};
  }
}

function getDefaultCTAButtonVariant(card) {
  switch (card.surfaceId) {
    case SURFACE_ID.ONE_CONSOLE_PROMOTION:
      return BUTTON_VARIANTS.DEFAULT;
    default:
      return BUTTON_VARIANTS.CTA;
  }
}

function getDefaultHeaderFontColor(card) {
  switch (card.surfaceId) {
    case SURFACE_ID.ONE_CONSOLE:
      return card.containerId === '2' ? COLORS.COLOR_GREY_900 : COLORS.COLOR_GREY_50;
    default:
      return COLORS.COLOR_GREY_900;
  }
}

function getDefaultDescriptionFontColor(card) {
  switch (card.surfaceId) {
    case SURFACE_ID.ONE_CONSOLE:
      return card.containerId === '2' ? COLORS.COLOR_GREY_800 : COLORS.COLOR_GREY_50;
    default:
      return COLORS.COLOR_GREY_800;
  }
}

function navigateToUrlCallback(eventName) {
  const eventUrl = new URL(eventName);

  const orgId = RootStore.organizationStore.activeOrgId;

  // only add OrgID if not already present
  const pathname = eventUrl.pathname.match(/([^/?@]+@AdobeOrg)/)
    ? eventUrl.pathname
    : `/${orgId}${eventUrl.pathname}`;
  const search = eventUrl.search;

  // navigate to relative path with orgId and eventUrl path and any query params
  // if no path admin console redirects to overview
  return () => {
    if (feature.isEnabled('bug_fix_sophia_card_redirect')) {
      navBus.navigate(
        feature.isEnabled('use_routing_with_query_params')
          ? {pathname, search}
          : `${pathname}${search}`
      );
    } else {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- callback
      window.location.pathname = `${pathname}${search}`;
    }
  };
}

export {
  SOPHIA_HVA_BANNER_NAME,
  getClassesForCard,
  getContentObject,
  getCtaClickEventCallback,
  getPrimaryCTAButtonVariant,
  getSecondaryCTAButtonVariant,
  getStyleObj,
};
