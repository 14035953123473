import {feature, toPandoraProductList} from '@admin-tribe/acsc';
import {USER_ROLES} from '@pandora/administration-core-types';
import {Contract} from '@pandora/data-model-contract';
import {JilModelList} from '@pandora/data-model-list';
import {canAccessEnterpriseSupport} from '@pandora/data-model-support-ticket';
import {useAuth} from '@pandora/react-auth-provider';
import {userRoleUtils} from '@pandora/react-data-model-member';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import HelpTopicList from '../components/help-topic-lists/HelpTopicList';
import {SUPPORT_CONSTANTS} from '../support.constants';
import {getHelpTopicsTabs} from '../utils/HelpTopicTabsUtils';

/**
 * Custom hook that provides support summary information and state management.
 *
 * @returns {Object} An object containing support summary data and state management functions.
 * @returns {boolean} accessEnterpriseSupport - Indicates if the user has access to enterprise support.
 * @returns {string} cloudType - The current cloud type.
 * @returns {Object} contractList - A list of contracts.
 * @returns {boolean} hasError - Indicates if there is an error.
 * @returns {boolean} isDistributorOrg - Indicates if the active organization is a distributor.
 * @returns {boolean} isLoading - Indicates if data is currently loading.
 * @returns {boolean} isOrgOrSupportAdminOrPSA - Indicates if the user has an admin or support role.
 * @returns {Array} productEntitlementList - A list of product entitlements.
 * @returns {Array} productList - A list of products.
 * @returns {Function} setCloudType - Function to set the cloud type.
 * @returns {Function} setHasError - Function to set the error state.
 * @returns {Function} setIsLoading - Function to set the loading state.
 * @returns {Function} setShowBanner - Function to set the banner visibility.
 * @returns {Function} setProductEntitlementList - Function to set the product entitlement list.
 * @returns {boolean} showBanner - Indicates if the banner is visible.
 * @returns {Array} tabs - A list of help topic tabs.
 * @returns {Array} tabsToShow - A list of tabs to show with content.
 */
const useSupportSummary = () => {
  const auth = useAuth();
  const intl = useIntl();

  const [cloudType, setCloudType] = useState(SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.GENERAL);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productEntitlementList, setProductEntitlementList] = useState(undefined);
  const [showBanner, setShowBanner] = useState(false);
  const tabs = useMemo(() => getHelpTopicsTabs(), []);

  const isOrgOrSupportAdminOrPSA = userRoleUtils.anyOfForOrg(
    auth.getUserProfile().roles,
    [USER_ROLES.ORG_ADMIN, USER_ROLES.SUPPORT_ADMIN, USER_ROLES.PRODUCT_SUPPORT_ADMIN],
    rootStore.organizationStore.activeOrgId
  );
  const isDistributorOrg =
    feature.isEnabled('temp_support_distributor_org') &&
    rootStore.organizationStore.isActiveOrgDistributor;

  const tabsToShow = tabs.map((tab) => ({
    content: <HelpTopicList cloudType={tab.key} contentModel={tab.contentModel} />,
    key: tab.key,
    name: intl.formatMessage({id: tab.name}),
    pathname: tab.pathname,
  }));

  const contractList = useMemo(
    () =>
      new JilModelList({
        items: rootStore.organizationStore.contractList.items.map(
          (contract) => new Contract(contract)
        ),
      }),
    []
  );

  const productList = useMemo(
    () => toPandoraProductList(rootStore.organizationStore.productList),
    []
  );

  const accessEnterpriseSupport = useMemo(
    () => canAccessEnterpriseSupport(contractList, productList) && isOrgOrSupportAdminOrPSA,
    [contractList, isOrgOrSupportAdminOrPSA, productList]
  );

  return {
    accessEnterpriseSupport,
    cloudType,
    contractList,
    hasError,
    isDistributorOrg,
    isLoading,
    isOrgOrSupportAdminOrPSA,
    productEntitlementList,
    productList,
    setCloudType,
    setHasError,
    setIsLoading,
    setProductEntitlementList,
    setShowBanner,
    showBanner,
    tabs,
    tabsToShow,
  };
};

export default useSupportSummary;
