import binky, {PRODUCT_LIST_PI_CODES, feature} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const {ModalDescription} = binkyUI.common.components.modal;
const GoUrl = binkyUI.common.components.GoUrl;

const AddUsersToUserGroupDescription = ({orgId}) => {
  const intl = useIntl();
  const [isTeam, setIsTeam] = React.useState(true);

  React.useEffect(() => {
    let isCurrent = true;

    async function fetchProducts() {
      let fetchedProductList;

      try {
        fetchedProductList = await binky.services.product.ProductList.get({
          orgId,
          ...(feature.isEnabled('temp_enable_pi_codes') && {
            processingInstructionCodes: PRODUCT_LIST_PI_CODES,
          }),
        });
      } catch (error) {
        binky.services.log.error(
          'Failed to fetch product list when adding users to user group. Error:',
          error
        );
      }

      if (isCurrent && fetchedProductList) {
        setIsTeam(binky.services.product.productListUtils.hasOnlyTeamProducts(fetchedProductList));
      }
    }

    fetchProducts();

    return () => {
      isCurrent = false;
    };
  }, [intl, orgId]);

  return (
    <ModalDescription>
      <Text>
        {intl.formatMessage(
          {id: `common.addUsersToUserGroupModal.${isTeam ? 'team' : 'enterprise'}.description`},
          {
            goUrl: (str) => <GoUrl name="aac_learn_more_usergroups">{str}</GoUrl>,
          }
        )}
      </Text>
    </ModalDescription>
  );
};

AddUsersToUserGroupDescription.propTypes = {
  orgId: PropTypes.string.isRequired,
};

export default AddUsersToUserGroupDescription;
