import {navBus} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  PATH_DIRECTORY_DETAILS,
  PATH_DIRECTORY_DETAILS_DOMAINS,
  PATH_SETTINGS_ASSET,
  PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS,
  PATH_SETTINGS_ASSET_CREDENTIALS,
  PATH_SETTINGS_ASSET_MIGRATION,
  PATH_SETTINGS_ASSET_SHARING_OPTIONS,
  PATH_SETTINGS_AUTHENTICATION,
  PATH_SETTINGS_CONSOLE,
  PATH_SETTINGS_CONTENT_LOGS,
  PATH_SETTINGS_ENCRYPTION,
  PATH_SETTINGS_IDENTITY,
  PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS,
  PATH_SETTINGS_IDENTITY_DIRECTORIES,
  PATH_SETTINGS_IDENTITY_DOMAINS,
  PATH_SETTINGS_IDENTITY_GUIDED_SETUP,
  PATH_SETTINGS_IDENTITY_TRUSTEES,
  PATH_SETTINGS_NON_ADOBE_GENAI_MODELS_POLICY,
  PATH_SETTINGS_ROOT,
  PATH_SETTINGS_SECURITY_CONTACTS,
  PATH_SETTINGS_SHARED_PROJECT_POLICIES,
} from 'features/settings/routing/settingsPaths';

/**
 * @description Method to navigate to a path with params and options
 * @param {string} path Path to navigate to
 * @param {object} params Path params such as orgId and directoryId
 * @param {object} options Navigation options such as state or searchQuery
 */
const goTo = (path, params = {}, options = {}) => {
  const url = generatePath(path, {orgId: rootStore.organizationStore.activeOrgId, ...params});

  navBus.navigate(url, options);
};

/**
 * @description Method to navigate to the Assets Settings page for the current organization
 */
const goToAssetSettings = () => goTo(PATH_SETTINGS_ASSET);

/**
 * @description Method to navigate to the Asset Sharing Options page for the current organization
 */
const goToAssetSharingOptions = () => goTo(PATH_SETTINGS_ASSET_SHARING_OPTIONS);

/**
 * @description Method to navigate to the Asset Authorized Domains page for the current organization
 */
const goToAssetAuthorizedDomains = () => goTo(PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS);

/**
 * @description Method to navigate to the Asset Credentials page for the current organization
 */
const goToAssetCredentials = () => goTo(PATH_SETTINGS_ASSET_CREDENTIALS);

/**
 * @description Method to navigate to the Asset Migrations page for the current organization
 */
const goToAssetMigrations = () => goTo(PATH_SETTINGS_ASSET_MIGRATION);

/**
 * @description Method to navigate to the Authenitcation Settings page for the current organization
 */
const goToAuthenitcationSettings = () => goTo(PATH_SETTINGS_AUTHENTICATION);

/**
 * @description Method to navigate to the 3rd Party GenAI model Policy Settings page for the current organization
 */
const goToNonAdobeGenAIModels = () => goTo(PATH_SETTINGS_NON_ADOBE_GENAI_MODELS_POLICY);

/**
 * @description Method to navigate to the Console Settings page for the current organization
 */
const goToConsoleSettings = () => goTo(PATH_SETTINGS_CONSOLE);

/**
 * @description Method to navigate to the Content Logs page for the current organization
 */
const goToContentLogs = () => goTo(PATH_SETTINGS_CONTENT_LOGS);

/**
 * @description Method to navigate to the EncryptionSettings page for the current organization
 */
const goToEncryptionSettings = () => goTo(PATH_SETTINGS_ENCRYPTION);

/**
 * @description Method to navigate to the Identity Settings page for the current organization
 */
const goToIdentitySettings = () => goTo(PATH_SETTINGS_IDENTITY);

/**
 * @description Method to navigate to the Identity Access Requests page for the current organization
 */
const goToIdentityAccessRequests = () => goTo(PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS);

/**
 * @description Method to navigate to the Identity Directories page for the current organization
 */
const goToIdentityDirectories = () => goTo(PATH_SETTINGS_IDENTITY_DIRECTORIES);

/**
 * @description Method to navigate to the Identity Domains page for the current organization
 */
const goToIdentityDomains = () => goTo(PATH_SETTINGS_IDENTITY_DOMAINS);

/**
 * @description Method to navigate to the Identity Guided Setup for the current organization
 */
const goToIdentityGuidedSetup = () => goTo(PATH_SETTINGS_IDENTITY_GUIDED_SETUP);

/**
 * @description Method to navigate to the Identity Trustees page for the current organization
 */
const goToIdentityTrustees = () => goTo(PATH_SETTINGS_IDENTITY_TRUSTEES);

/**
 * @description Method to navigate to the Settings Root page for the current organization
 */
const goToSettingsRoot = () => goTo(PATH_SETTINGS_ROOT);

/**
 * @description Method to navigate to the Security Contacts page for the current organization
 */
const goToSecurityContacts = () => goTo(PATH_SETTINGS_SECURITY_CONTACTS);

/**
 * @description Method to navigate to the Shared-Project Policy page for the current organization
 */
const goToSharedProjectPolicies = () => goTo(PATH_SETTINGS_SHARED_PROJECT_POLICIES);

const goToDirectoryDetails = (directoryId) => goTo(PATH_DIRECTORY_DETAILS, {directoryId});

/**
 * @description Method to navigate to Directory Details Domains page for the current organization
 * We need to pass the directoryId and setup=true
 * setup=true is used to open the "Add domain" modal from the beginning
 */
const goToSetupDomains = (directoryId) =>
  goTo(
    PATH_DIRECTORY_DETAILS_DOMAINS,
    {directoryId},
    {
      state: {setup: true},
    }
  );

export {
  goToAssetAuthorizedDomains,
  goToAssetCredentials,
  goToAssetMigrations,
  goToSetupDomains,
  goToAssetSettings,
  goToAssetSharingOptions,
  goToAuthenitcationSettings,
  goToConsoleSettings,
  goToContentLogs,
  goToDirectoryDetails,
  goToEncryptionSettings,
  goToIdentityAccessRequests,
  goToIdentityDirectories,
  goToIdentityDomains,
  goToIdentityGuidedSetup,
  goToIdentitySettings,
  goToIdentityTrustees,
  goToNonAdobeGenAIModels,
  goToSecurityContacts,
  goToSettingsRoot,
  goToSharedProjectPolicies,
};
