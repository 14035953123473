// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {Button, ButtonGroup, Text, View} from '@adobe/react-spectrum';
import {
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {WizardDriver, WizardView} from '@pandora/react-wizard';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StepItem from 'common/components/stepped-view/StepItem';
import SteppedView from 'common/components/stepped-view/SteppedView';

import RosterSyncProfilesStep from './RosterSyncProfilesStep';
import RosterSyncProviderSelector from './RosterSyncProviderSelector';
import RosterSyncSettingsStep from './RosterSyncSettingsStep';
import useRosterSyncWizard from './hooks/useRosterSyncWizard';
import {ROSTER_SYNC_STEPS, STEPS} from './rosterSyncConstants';

const RosterSyncSteppedModal = ({onClose, initialStepId = ROSTER_SYNC_STEPS.PROVIDER.id}) => {
  const intl = useIntl();
  const [currentStep, setCurrentStep] = useState(() =>
    STEPS.find((step) => step.id === initialStepId)
  );

  const {
    handleBack,
    handleNext,
    isLoading,
    canGoNext,
    initWizard,
    onInitWizard,
    getNextButtonMessageId,
  } = useRosterSyncWizard({
    currentStep: currentStep.id,
    onClose,
  });

  const previousText = intl.formatMessage({id: 'common.modal.buttons.back'});
  const cancelText = intl.formatMessage({id: 'common.modal.buttons.cancel'});

  const isNextButtonDisabled = () => !canGoNext() || isLoading;

  const onStepChange = (stepChangedParams) => {
    setCurrentStep(STEPS.find((step) => step.id === stepChangedParams.currentStepName));
  };

  return (
    <ModalDialog
      heightVariant="dynamic"
      id="roster-sync-stepped-modal"
      isLoading={isLoading}
      size="workflow"
    >
      <ModalHeading>
        <WizardView initConfig={initWizard} onStepChange={onStepChange}>
          <WizardDriver onInit={onInitWizard} />
        </WizardView>
        <Text>
          {intl.formatMessage({
            id: currentStep.title,
          })}
        </Text>
      </ModalHeading>
      <ModalDescription>
        <View>
          <Text
            UNSAFE_style={{
              fontSize: 'var(--spectrum-global-dimension-size-175)',
              fontWeight: 'bold',
            }}
          >
            {intl.formatMessage(
              {id: 'settings.rosterSync.stepIndicator'},
              {
                current: currentStep.number,
                total: STEPS.length,
              }
            )}
          </Text>
        </View>
        <FormattedMessage id={currentStep.description} />
      </ModalDescription>
      <ModalContent>
        <SteppedView currentStep={currentStep.id}>
          <StepItem id={ROSTER_SYNC_STEPS.PROVIDER.id}>
            <RosterSyncProviderSelector />
          </StepItem>
          <StepItem id={ROSTER_SYNC_STEPS.SETTINGS.id}>
            <RosterSyncSettingsStep />
          </StepItem>
          <StepItem id={ROSTER_SYNC_STEPS.PROFILES.id}>
            <RosterSyncProfilesStep />
          </StepItem>
        </SteppedView>
      </ModalContent>
      <ButtonGroup align="end">
        <Button onPress={handleBack} variant="primary">
          {currentStep.id === ROSTER_SYNC_STEPS.PROVIDER.id ? cancelText : previousText}
        </Button>
        <Button isDisabled={isNextButtonDisabled()} onPress={handleNext} variant="accent">
          <FormattedMessage id={getNextButtonMessageId()} />
        </Button>
      </ButtonGroup>
    </ModalDialog>
  );
};

RosterSyncSteppedModal.propTypes = {
  initialStepId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default RosterSyncSteppedModal;
