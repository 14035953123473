// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Image, View} from '@adobe/react-spectrum';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

const SophiaPromoIcon = () => {
  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  if (!payload.imageUrl) {
    return null;
  }

  return (
    <View data-testid="one-console-promo-icon">
      <Image
        alt={payload.imageAlt || ''}
        data-testid="one-console-promo-icon-image"
        height="auto"
        objectFit="cover"
        src={payload.imageUrl}
        width="size-600"
      />
    </View>
  );
};

SophiaPromoIcon.propTypes = {};

export default SophiaPromoIcon;
