import {OverlayWait} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Link, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from '../../support.constants';
import StartChatButton from '../start-chat-button/StartChatButton';
import SupportTicketCreateButton from '../support-ticket-create-button/SupportTicketCreateButton';

import styles from './SupportSummaryQuickActions.pcss';

const {SUPPORT_TICKET_RECORD_TYPE} = SUPPORT_CONSTANTS;
/**
 * A container for quick actions you might take having to do with Support cases with a heading, has "Create case" and "Start chat"
 * buttons and a link to active support cases
 */
const SupportCaseQuickActions = (props) => {
  const {
    'data-testid': dataTestId,
    canDisplayChat,
    cloudType,
    isDistributorOrg = false,
    isLoading = false,
    onSupportCaseCreated,
    onGoToOpenCases,
    supportCaseCount,
  } = props;
  const intl = useIntl();
  return (
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes --  need a top level class to namespace the styles below
    <View data-testid={dataTestId} flex="1" UNSAFE_className={styles['quick-actions']}>
      <OverlayWait isLoading={isLoading} showContent size="M">
        <Heading level="2" marginTop="size-10">
          {intl.formatMessage({
            id: isDistributorOrg
              ? 'support.quickActions.contactSupport.title'
              : 'support.quickActions.supportCase.title',
          })}
        </Heading>

        <View
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes --  need a top level class to namespace the styles below
          UNSAFE_className={styles[supportCaseCount > 0 && !isDistributorOrg ? 'show' : 'hide']}
        >
          <Link isQuiet onPress={onGoToOpenCases}>
            {intl.formatMessage(
              {
                id: 'support.quickActions.supportCase.viewAllActive',
              },
              {
                count: intl.formatNumber(supportCaseCount),
              }
            )}
          </Link>
        </View>
        <View>
          <Flex gap="size-100" marginTop="size-200" wrap>
            <SupportTicketCreateButton
              cloudType={cloudType}
              onCreate={onSupportCaseCreated}
              recordType={SUPPORT_TICKET_RECORD_TYPE.CASE}
              variant="accent"
            />
            {(canDisplayChat || isDistributorOrg) && (
              <StartChatButton analyticsName="startChatButton" variant="primary" />
            )}
          </Flex>
        </View>
      </OverlayWait>
    </View>
  );
};

SupportCaseQuickActions.propTypes = {
  /**
   * Whether to show the start chat button
   */
  canDisplayChat: PropTypes.bool.isRequired,
  /**
   * Used to set cloud type
   */
  cloudType: PropTypes.string,
  /**
   * The specified data-testid for the component.
   */
  'data-testid': PropTypes.string,
  /**
   * Whether or not the org is a distributor org
   */
  isDistributorOrg: PropTypes.bool,
  /**
   * Used to show loader for count
   */
  isLoading: PropTypes.bool,
  /**
   * Callback function for navigating to open Support Cases
   */
  onGoToOpenCases: PropTypes.func.isRequired,
  /**
   * Callback function for after support case is opened
   */
  onSupportCaseCreated: PropTypes.func.isRequired,
  /**
   * The number of existing expert sessions that have been opened
   */
  supportCaseCount: PropTypes.number.isRequired,
};

export default SupportCaseQuickActions;
