// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {Flex, Heading, Image, InlineAlert, View} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import GroupsProfilesTableSection from 'features/settings/components/roster-sync-settings/groups-profiles-table/GroupsProfilesTableSection';
import {
  APP_TYPES_TO_INTEGRATION_NAMES,
  PROVIDER_CONFIGS,
} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';

import {useRosterSyncContext} from './RosterSyncContext';
import {getMissingProductTypes} from './rosterSyncUtils';

const RosterSyncProfilesStep = () => {
  const {provider, products, selectedApps} = useRosterSyncContext();

  // Check if we have products that match at least one of the product families for each selected app type
  const missingProductTypes = getMissingProductTypes(selectedApps, products);

  // Convert missing product types to their display names
  const missingProductNames = missingProductTypes.map(
    (appType) => APP_TYPES_TO_INTEGRATION_NAMES[appType] || appType
  );

  return (
    <Flex data-testid="modal-content" direction="column" gap="size-400">
      <View>
        <Flex alignItems="center" gap="size-200">
          <Image
            alt=""
            height="size-1250"
            src={PROVIDER_CONFIGS[provider].logo}
            width="size-1500"
          />
          <View>
            <Heading level={3}>
              <FormattedMessage id={PROVIDER_CONFIGS[provider].name} />
            </Heading>
            <p>
              <FormattedMessage id="settings.rosterSync.profiles.modal.provider.description" />
            </p>
            <p>
              <FormattedMessage id="settings.rosterSync.profiles.modal.provider.subDescription" />
            </p>
          </View>
        </Flex>
      </View>

      <Flex direction="column">
        {products === null && (
          <InlineAlert variant="notice" width="100%">
            <FormattedMessage id="settings.rosterSync.profiles.modal.noProductsAlert" />
          </InlineAlert>
        )}

        {missingProductTypes.length > 0 && (
          <InlineAlert variant="notice" width="100%">
            <FormattedMessage
              id="settings.rosterSync.profiles.modal.missingProductsAlert"
              values={{
                appTypes: missingProductNames.join(', '),
                count: missingProductNames.length,
              }}
            />
          </InlineAlert>
        )}
      </Flex>

      <GroupsProfilesTableSection />
    </Flex>
  );
};

export default RosterSyncProfilesStep;
