import {Flex, Heading} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import SupportHelpTopic from './support-help-topic/SupportHelpTopic';

/**
 * A widget to display the Support Help Topics.
 */
const SupportHelpTopics = ({'data-testid': dataTestId, goUrls}) => {
  const intl = useIntl();
  const isDistributorOrg = rootStore.organizationStore.isActiveOrgDistributor;
  return (
    <Flex data-testid={dataTestId || 'help-topic-list'} direction="column" isQuiet>
      <Heading
        data-testid="help-topic-header"
        level={3}
        marginBottom="size-125"
        marginTop="size-400"
      >
        {intl.formatMessage({id: 'support.supportHelpTopics.label.popularHelpTopics'})}
      </Heading>
      {!isDistributorOrg &&
        goUrls.map((goUrl) => (
          <SupportHelpTopic
            key={goUrl.name}
            data-testid={goUrl.name}
            isQuiet
            label={intl.formatMessage({id: `support.supportHelpTopics.label.${goUrl.label}`})}
            name={goUrl.name}
          />
        ))}
    </Flex>
  );
};

SupportHelpTopics.propTypes = {
  /**
   * data-testid is used as identifier of the component.
   * The default is 'help-topic-list'.
   */
  'data-testid': PropTypes.string,
  /**
   * goUrls is used to define the urls of component.
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  goUrls: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SupportHelpTopics;
