import {PRODUCT_DELEGATION_TARGET, feature} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {hasManageAdminsPolicy} from 'core/admin/access/adminAccess';
import {isOrgAdmin, isReadOnly} from 'core/organizations/access/organizationAccess';
import {
  canAssignUser as canAssignUserToProduct,
  canAssignUserIgnoringLicenseCounts as canAssignUserToProductIgnoringLicenseCounts,
  canViewUsers as canShowUsers,
} from 'core/products/access/productAccess';
import auth from 'core/services/auth';

/**
 * @description Returns true if an admin can be assigned to the product profile.
 * @returns {Boolean} True if admin can be assigned to product profile
 */
function canAssignAdmin() {
  return hasManageAdminsPolicy() && !isReadOnly();
}

/**
 * @description Returns true if a developer can be assigned to a product profile.
 *
 * @returns {Boolean} True if developer can be assigned to product profile
 */
function canAssignDeveloper() {
  return !isReadOnly();
}

/**
 * @description Returns true if integration can be assigned to the product profile.
 *
 * @returns {Boolean} True if integration can be assigned to product profile.
 */
function canAssignIntegration() {
  return !isReadOnly();
}

/**
 * @description Returns true if an user can be assigned to the product profile.
 * This can be used to determine if the ProductProfile `Add user` button should be disabled.
 *
 * @param {LicenseGroup} productProfile - the product is a property on the licenseGroup
 *   (make sure it is not a skeleton product - product must have the isAdministerable property)
 *
 * @returns {Boolean} True if user has permission to be assigned to the product profile and there are available licenses.
 *   It will return false if the admin has permission but there are no available licenses.
 */
function canAssignUser(productProfile) {
  return canAssignUserToProduct(productProfile.product);
}

/**
 * @description Returns true if an user can be assigned to the product profile ignoring license counts.
 * This can be used to determine if the ProductProfile `Add user` button should be shown.
 *
 * @param {LicenseGroup} productProfile - the product is a property on the licenseGroup
 *   (make sure it is not a skeleton product - product must have the isAdministerable property)
 *
 * @returns {Boolean} True if user can be assigned to product profile ignoring license counts
 *   It will return true if the admin has permission even if there are no available licenses.
 */
function canAssignUserIgnoringLicenseCounts(productProfile) {
  return canAssignUserToProductIgnoringLicenseCounts(productProfile.product);
}

/**
 * @description Returns true if a device report can be created for the product
 *
 * @param {Product} product
 *
 * @returns {Boolean} True if a device report can be created for the product.
 */
function canCreateDeviceReports(product) {
  return product.isSharedDeviceLicense();
}

/**
 * @description Returns true if a new product profile can be created.
 *
 * @param {Product} product
 *
 * @returns {Boolean} True if a new product profile can be created
 */
function canCreateNewProductProfile(product) {
  // If the childCreationAllowed field is missing entirely then it's
  // likely that the license lives in LS2 and hasn't been moved to LS3
  // yet. In that case we'll operate with the old logic.
  if (feature.isEnabled('temp_sls_m_3') && product.childCreationAllowed !== undefined) {
    return (
      product.childCreationAllowed &&
      !isReadOnly() &&
      rootStore.organizationStore.productList.hasUserDelegatableProduct() &&
      (isOrgAdminOrProductAdmin(product?.targetExpression) || auth.isUserContractAdmin())
    );
  }
  return (
    !isReadOnly() &&
    rootStore.organizationStore.productList.hasUserDelegatableProduct() &&
    (isOrgAdminOrProductAdmin(product?.targetExpression) || auth.isUserContractAdmin())
  );
}

/**
 * @description Returns true if the product profile can recover licenses.
 *
 * @param {ConsumableSummarizationList} consumableSummarizationList
 * @param {String} profileId
 * @param {Product} product
 *
 * @returns {Boolean} True if the product profile can recover licenses
 */
function canRecoverLicenses(consumableSummarizationList, profileId, product) {
  if (product.isSharedDeviceLicense() && auth.isUserOrgAdmin()) {
    const consumables = consumableSummarizationList.getConsumablesForSummaryId(profileId);
    return consumables?.[0]?.consumedQuantity > 0;
  }
  return false;
}

/**
 * @description Returns true if an admin can be removed from a product profile.
 *
 * @returns {Boolean} True if admin can be removed from product profile
 */
function canRemoveAdmin() {
  return hasManageAdminsPolicy() && !isReadOnly();
}

/**
 * @description Returns true if an developer can be removed from a product profile.
 *
 * @returns {Boolean} True if developer can be removed from a product profile
 */
function canRemoveDeveloper() {
  return !isReadOnly();
}

/**
 * @description Returns true if the product profile can be removed.
 *
 * @returns {Boolean} True if product profile can be removed
 */
function canRemoveProductProfile() {
  return !isReadOnly();
}
/**
 * @description Returns true if an user can be removed from a product profile.
 *
 * @returns {Boolean} True if user can be removed from a product profile
 */
function canRemoveUser() {
  return !isReadOnly();
}

/**
 * @description Returns true if the product profile can show admins.
 * @param {Product} product
 * @returns {Boolean} True if product profile can show admins
 */
function canShowAdmins(product) {
  return product.isEnterprise() && product.isAdministerable();
}

/**
 * @description method to check if the user can view the associated packages for the product profile.
 * @param {Product} product
 * @returns {Boolean} True if can view associated packages for product profile
 */
function canViewAssociatedPackages(product) {
  return product.isSharedDeviceLicense();
}

/**
 * @description Method to determine if the profile table should show a 'View details' column
 *   to open the ProductProfileDetailDrawer.
 * @returns {Boolean} True if the table should include include the drawer.
 */
function canViewDetails(product) {
  return product.isDelegatableToUser();
}

/**
 * @description method to check if the user can view either the product profile developer or integrations tab.
 * @param {Product} product
 * @returns {Boolean} True if can view developer or integrations tab
 */
function canViewDevelopers(product) {
  return (
    isOrgAdmin() &&
    product.isAdministerable() &&
    product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)
  );
}

/**
 * @description method to check if the user can view the product profile devices tab.
 * @param {Product} product
 * @returns {Boolean} True if can view developer or integrations tab
 */
function canViewDevices(product) {
  return product.isAdministerable() && product.isSharedDeviceLicense();
}

/**
 * @description method to check if the user can view entitled users count for the product profile.
 * @param {Product} product
 * @returns {Boolean} True if can view entitled users count for product profile
 */
function canViewEntitledUsersCount(product) {
  return !product.isSharedDeviceLicense();
}

/**
 * @description method to check if the user can view total license count for the product profile.
 * @param {Product} product
 * @returns {Boolean} True if can view the total quantity of licenses for product profile
 */
function canViewLicensesTotalQuantity(product) {
  return !product.isSharedDeviceLicense();
}

/**
 * @description method to check if the user can view used license count for the product profile.
 * @param {Product} product
 * @returns {Boolean} True if can view the used quanity of licenses for product profile
 */
function canViewLicensesUsedQuantity(product) {
  return product.isSharedDeviceLicense();
}

/**
 * @description method to check if the user can view the notify user cell for the product profile.
 * @param {Product} product
 * @returns {Boolean} True if can view the status of notifications for product profile
 */
function canViewNotifyUser(product) {
  return !product.isSharedDeviceLicense();
}

/**
 * @description method to check if the user can view the set quota button for the product profile.
 * @param {Product} product
 * @returns {Boolean} True if can view the set quota button for product profile
 */
function canViewSetQuotaButton(product) {
  return (
    canViewProductProfiles(product) &&
    (isOrgAdminOrProductAdmin(product?.targetExpression) || auth.isUserContractAdmin()) &&
    product.usesSeatBasedDelegation()
  );
}

/**
 * @description method to check if the user can view product profiles.
 * @param {Product} product
 * @returns {Boolean} True if can view product profiles
 */
function canViewProductProfiles(product) {
  let hasOrgDelegatable;
  if (feature.isEnabled('temp_delegation_type_dcomm')) {
    hasOrgDelegatable = product.fulfillableItemList.hasOrgDelegatableWithTarget(
      product.processingInstructions?.license_data?.delegation_targets
    );
  } else {
    hasOrgDelegatable = product.fulfillableItemList.hasOrgDelegatable();
  }

  return (
    product.isAdministerable() &&
    product.customerSegment !== 'TEAM' &&
    !product.isLegacyDeviceLicense() &&
    !product.isFeatureRestrictedLicense() &&
    !hasOrgDelegatable
  );
}

/**
 * @description method to check if the product can show display name.
 * @param {Product} product
 * @returns {Boolean} True if can show product profile display name
 */
function canShowDisplayName(product) {
  return !product.isSharedDeviceLicense();
}

/**
 * @description method to check if the product can show user notifications.
 * @param {Product} product
 * @returns {Boolean} True if can show product profile notifications
 */
function canShowUserNotifications(product) {
  return !product.isSharedDeviceLicense();
}

/**
 * @description Method to determine if the profile has available licenses (or can delegate users
 *     without having them).
 * @param {ProductConfiguration} productProfile the profile to check for available licenses
 * @returns {Boolean} True if the profile has available licenses, otherwise false
 */
function hasAvailableLicenses(productProfile) {
  return !productProfile.product.isTeam() || !productProfile.product.hasNoAvailableLicenses();
}

/**
 * @description determines if a profile should have permissions for us to fetch.
 * @param {Product} product the product to check
 *
 * @returns {Boolean} True if this Product is of a type and status to have permissions, else false
 */
function canViewPermissions(product) {
  if (
    feature.isEnabled('force_permissions') ||
    product.hasConfigurationSettingForLicenseGroup(true)
  ) {
    return true;
  }
  // legacy permissions are only shown for non-DX products, or DX ones that have been migrated
  const canShowLegacyPermissions =
    !product.isMarketingCloudProduct() || product.isMigrationComplete();
  return (
    product.isAdministerable() &&
    canShowLegacyPermissions &&
    (product.hasConfigurationSettingForLegacyPermissions() ||
      // Determine if product uses legacy User Permission Management
      (product.hasConfigurationSettingForLicenseGroup(false) &&
        product.fulfillableItemList.hasUserPermissionManagement()))
  );
}

/**
 * Method to determine if the user is an Org admin or a Product admin
 *
 * @param {String} targetExpression - The product targetExpression
 * @returns {Boolean} - True if the user is an Org admin or a Product admin
 */
function isOrgAdminOrProductAdmin(targetExpression) {
  return auth.isUserOrgAdmin() || auth.isUserProductAdminForTarget(targetExpression);
}

export {
  canAssignAdmin,
  canAssignDeveloper,
  canAssignUser,
  canAssignUserIgnoringLicenseCounts,
  canCreateDeviceReports,
  canCreateNewProductProfile,
  canRecoverLicenses,
  canRemoveAdmin,
  canRemoveDeveloper,
  canAssignIntegration,
  canRemoveProductProfile,
  canRemoveUser,
  canShowAdmins,
  canShowDisplayName,
  canShowUserNotifications,
  canShowUsers,
  canViewAssociatedPackages,
  canViewDetails,
  canViewDevelopers,
  canViewDevices,
  canViewEntitledUsersCount,
  canViewLicensesTotalQuantity,
  canViewLicensesUsedQuantity,
  canViewNotifyUser,
  canViewPermissions,
  canViewProductProfiles,
  canViewSetQuotaButton,
  hasAvailableLicenses,
  isOrgAdminOrProductAdmin,
};
