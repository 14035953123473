// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {OverlayWait} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  DialogContainer,
  Divider,
  Flex,
  Heading,
  View,
} from '@adobe/react-spectrum';
import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import RemoveRosterSyncConfirmationModal from 'features/settings/components/roster-sync-settings/roster-sync-details/RemoveRosterSyncConfirmationModal';
import RosterSyncDetailsHeader from 'features/settings/components/roster-sync-settings/roster-sync-details/RosterSyncDetailsHeader';
import {SYNC_STATUSES} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';

const RosterSyncDetails = () => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const {syncConfig, refreshSyncConfig} = useRosterSyncContext();

  // Refresh sync config when component mounts to ensure we have the latest status
  // This is needed to display the correct status
  useEffect(() => {
    const fetchLatestConfig = async () => {
      setIsLoading(true);
      await refreshSyncConfig();
      setIsLoading(false);
      setHasFetchedData(true);
    };

    fetchLatestConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want to run on mount
  }, []);

  // Check if the sync is currently running or queued
  const isRunningOrQueued =
    syncConfig.status === SYNC_STATUSES.RUNNING || syncConfig.status === SYNC_STATUSES.QUEUED;

  // Show a loading state until we've fetched fresh data
  if (!hasFetchedData) {
    return (
      <Flex alignItems="center" direction="column" height="200px" justifyContent="center">
        <OverlayWait isLoading showContent={false} />
      </Flex>
    );
  }

  return (
    <OverlayWait isLoading={isLoading} showContent>
      <Flex direction="column">
        <Heading level={3}>
          <FormattedMessage id="settings.rosterSync.edit.sections.status.title" />
        </Heading>
        <RosterSyncDetailsHeader />

        <Divider size="S" />
        <View>
          <Heading level={3}>
            <FormattedMessage id="settings.rosterSync.edit.sections.remove.title" />
          </Heading>
          <p>
            <FormattedMessage id="settings.rosterSync.edit.sections.remove.description" />
          </p>
          <ButtonGroup align="end" width="100%">
            <Button
              isDisabled={isRunningOrQueued}
              onPress={() => setShowDeleteConfirmationModal(true)}
              variant="negative"
            >
              <FormattedMessage id="settings.rosterSync.edit.sections.remove.removeButton" />
            </Button>
          </ButtonGroup>
        </View>
        <DialogContainer onDismiss={() => setShowDeleteConfirmationModal(false)}>
          {showDeleteConfirmationModal && <RemoveRosterSyncConfirmationModal />}
        </DialogContainer>
      </Flex>
    </OverlayWait>
  );
};

export default RosterSyncDetails;
