// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import SafeHtml from '@pandora/react-safe-html';
import PropTypes from 'prop-types';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

const SophiaPromoDescription = ({children}) => {
  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  return payload.description ? (
    <View
      data-testid="one-console-promo-description"
      UNSAFE_style={{
        fontSize: 'var(--spectrum-global-dimension-font-size-100)',
      }}
    >
      <SafeHtml dataTestId="one-console-promo-description-text" html={payload.description} />
      {children}
    </View>
  ) : null;
};

SophiaPromoDescription.propTypes = {
  children: PropTypes.node,
};

export default SophiaPromoDescription;
