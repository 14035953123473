const ADD_PRODUCT_STEPS = {
  /**
   * Step for showing Addon product list
   */
  ADDON: 'addon',
  /**
   * Step for showing Quick Assign Step
   */
  QUICK_ASSIGN_LICENSES: 'quick_assign_licenses',
  /**
   * Step for selecting a reason for cancellation.
   */
  REASON_CANCEL_LIST: 'reason-cancel-list',
  /**
   * Step for reviewing an order.
   */
  REVIEW_ORDER: 'review-order',
  /**
   * Step for saving an offer.
   */
  SAVE_OFFER: 'save-offer',
  /**
   * Step for selecting a contract.
   */
  SELECT_CONTRACT: 'select-contract',
  /**
   * Step for selecting an educational organization type.
   */
  SELECT_EDU_ORG_TYPE: 'select-edu-org-type',
  /**
   * Step for select plan
   */
  SELECT_PLAN: 'select-plan',
  /**
   * Step for selecting a plan type.
   */
  SELECT_PLAN_TYPE: 'select-plan-type',
  /**
   * Step for selecting a product.
   */
  SELECT_PRODUCT: 'select-product',
};

const ADD_PRODUCT_WORKFLOWS = {
  ADD_LICENSE: 'add-license',
  ADD_PRODUCT: 'add-product',
  SELECT_PLAN: 'select-plan',
  UPDATE_RENEW: 'update-renew',
  VIEW_RENEW: 'view-renew',
};

/**
 * @description Add Product Workflow Configuration IDs
 * @see {@link https://wiki.corp.adobe.com/display/PAN/RFC%3A+Mini+App+-+Add+Product#RFC:MiniAppAddProduct-Parameters | Parameters section of RFC: Mini App - Add Product }
 * for more information.
 */
const ADD_PRODUCT_WORKFLOW_CONFIG_IDS = {
  ADD_LICENSE: 'add_license',
  ADD_LICENSE_VARIATION: 'add_license_variation',
  ADD_PRODUCT: 'add_product',
  SELECT_PLAN_VARIATION: 'select_plan_variation',
  SELECT_PLAN_WITH_EDU_VARIATION: 'select_plan_with_edu_variation',
};

const STATUS_CODE = {
  CANCEL_WITHOUT_PROGRESS: 0,
  FULLY_COMPLETE: 10,
  GO_BACK: -1,
};

const ADD_PRODUCT_RTP2_FEATURES = [
  'share_apollo_client',
  'support_search_contracts',
  'temp_add_product_mini_app_rtp2',
  'temp_add_product_rtp2_disable_by_cliend_id',
  'use_auth_user_for_cart',
  'use_mat_steps',
];

const ADD_PRODUCT_RTP2_OPT_FEATURES = [
  'add_license_hide_cart_action',
  'add_product_hide_account_info',
  'add_product_hide_current_license',
  'hide_logo',
  'prorated_billing_info',
  'select_plan_column_view',
  'temp_add_product_mini_app_rtp2',
];

export {
  ADD_PRODUCT_RTP2_FEATURES,
  ADD_PRODUCT_RTP2_OPT_FEATURES,
  ADD_PRODUCT_STEPS,
  ADD_PRODUCT_WORKFLOWS,
  ADD_PRODUCT_WORKFLOW_CONFIG_IDS,
  STATUS_CODE,
};
