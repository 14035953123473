// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {useCallback, useEffect, useRef, useState} from 'react';

/**
 * Custom hook for adaptive polling with maximum duration and safety features
 * Designed for SPA environments where component unmounting handles navigation
 *
 * @param {Object} options - Configuration options
 * @param {boolean} options.active - Whether polling is active
 * @param {Function} options.callback - Function to call on each poll interval, should return a value for comparison
 * @param {number} options.increaseAfter - Increase interval after this many unchanged polls
 * @param {number} options.increaseBy - Factor to increase interval by
 * @param {number} options.initialDelay - Initial polling interval in milliseconds
 * @param {number} options.maxDelay - Maximum polling interval in milliseconds
 * @param {number} options.maxDuration - Maximum polling duration in milliseconds
 * @param {Function} options.isValidCheck - Optional function to check if polling should continue
 * @returns {Object} Object containing the current delay
 */
const useAdaptivePolling = ({
  active = false,
  callback,
  increaseAfter = 3, // Increase interval after 3 unchanged polls
  increaseBy = 1.5, // Increase by 50%
  initialDelay = 5000,
  maxDelay = 30000, // 30 seconds
  maxDuration = 10 * 60 * 1000, // 10 minutes
  isValidCheck = null, // Optional function to check if polling should continue
}) => {
  const savedCallback = useRef();
  const startTimeRef = useRef(null);
  const unchangedCountRef = useRef(0);
  const lastValueRef = useRef(null);
  const [currentDelay, setCurrentDelay] = useState(initialDelay);
  const intervalIdRef = useRef(null);

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Reset polling when active changes
  useEffect(() => {
    if (active) {
      startTimeRef.current = Date.now();
      setCurrentDelay(initialDelay);
      unchangedCountRef.current = 0;
    }
  }, [active, initialDelay]);

  // The main polling function
  const tick = useCallback(async () => {
    // Check if we've exceeded max duration
    if (Date.now() - startTimeRef.current > maxDuration) {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
      return;
    }

    try {
      // Call the callback and get the result
      const result = await savedCallback.current();

      // Check if the value has changed
      const hasChanged = JSON.stringify(result) !== JSON.stringify(lastValueRef.current);

      if (hasChanged) {
        // Reset counter when value changes
        unchangedCountRef.current = 0;
        lastValueRef.current = result;
        return;
      }

      // Handle unchanged value
      unchangedCountRef.current += 1;

      // Check if we should increase the interval
      if (unchangedCountRef.current < increaseAfter) {
        return;
      }

      // Calculate new delay
      const newDelay = Math.min(currentDelay * increaseBy, maxDelay);
      if (newDelay === currentDelay) {
        return;
      }

      // Update delay and restart interval
      setCurrentDelay(newDelay);
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = setInterval(tick, newDelay);
      }
    } catch (error) {
      // Handle errors if needed
    }
  }, [currentDelay, increaseAfter, increaseBy, maxDelay, maxDuration]);

  // Set up the interval
  useEffect(() => {
    // Check if polling should be active
    const shouldPoll = active && (!isValidCheck || isValidCheck());

    if (!shouldPoll) {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
      return undefined;
    }

    // Clear any existing interval
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    // Start new interval
    intervalIdRef.current = setInterval(tick, currentDelay);

    // Clean up on unmount or when dependencies change
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [active, currentDelay, tick, isValidCheck]);

  // Return current delay for informational purposes
  return {currentDelay};
};

export default useAdaptivePolling;
